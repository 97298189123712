import { yupResolver } from '@hookform/resolvers/yup';
import React, { memo, useEffect, useState } from 'react';
import { Button, Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';

import { FormProvider, useForm } from 'react-hook-form';
import GeneralSummary, {
  getGeneralInfoProductSchema,
} from '../../components/Products/GeneralSummary';
import { getKitSchema } from '../../components/Products/Kit';
import ProvidersSummary from '../../components/Products/ProvidersSummary';
import { defaultValuesProduct } from '../../utils/defaultValues';
import Stock from '../../components/Products/Stock';
import clsx from 'clsx';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

const ModalSummarizedProducts = ({
  showModal,
  handleCloseModal,
  editState,
  data,
}) => {
  const schema = getKitSchema.concat(getGeneralInfoProductSchema);
  const { reset, ...methods } = useForm({
    mode: 'all',
    defaultValues: defaultValuesProduct,
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [tabHasError, setTabHasError] = useState({
    tab1: false,
    tab2: false,
    tab3: false,
  });
  const [showInventory, setShowInventory] = useState(true);
  const [showLocation, setShowLocation] = useState(false);

  const [dataProductType, setDataProductType] = useState([]);
  const [dataUnit, setDataUnit] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const [dataProductStatus, setDataProductStatus] = useState([]);
  const [dataProductLocation, setDataProductLocation] = useState([]);

  useEffect(() => {
    const handleErrorsOnTab = () => {
      let tabWithErrorsState = { ...tabHasError };

      methods.formState.errors?.product !== undefined
        ? (tabWithErrorsState.tab1 = true)
        : (tabWithErrorsState.tab1 = false);

      methods.formState.errors?.providers !== undefined
        ? (tabWithErrorsState.tab2 = true)
        : (tabWithErrorsState.tab2 = false);

      setTabHasError(tabWithErrorsState);
    };

    handleErrorsOnTab();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.errors]);

  const setData = (obj, clear) => {
    if (clear) {
      reset(defaultValuesProduct);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api
        .get('/getProductsData')
        .then((response) => {
          if (response.ok) {
            setShowInventory(
              response.data.companysettings.enabledInventory || true,
            );
            setDataProductType(response.data.productype || []);
            setDataUnit(response.data.unit || []);
            setDataOrigin(response.data.origin || []);
            setDataProductStatus(response.data.productstatus || []);
            setDataProductLocation(response.data.productlocation || []);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editState, showModal]);

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-product');
  }, []);

  const getLocation = async () => {
    try {
      await api
        .get('/getProductsData')
        .then((response) => {
          if (response.ok) {
            setDataProductLocation(response.data.productlocation || []);
          }
        })
        .catch((error) => {
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (!showLocation) getLocation();
  }, [showLocation]);

  const handleSubmitForm = async (data, e) => {
    setShowLocation(false);
    e.preventDefault();
    e.stopPropagation();
    console.log(
      '🚀 ~ file: modal-product.js ~ line 232 ~ handleSubmitForm ~ data',
      data,
    );
  };

  return (
    <Modal
      id="modal-summarized-product"
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
      className="maxHeight600 "
    >
      <FormProvider {...methods}>
        <form
          id="form-product"
          onSubmit={methods.handleSubmit((data, e) =>
            handleSubmitForm(data, e),
          )}
        >
          <Modal.Header closeButton>
            <Modal.Title className="mb-0">
              <i className="fa fa-box" /> Propriedades do Produto Resumido
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Loading loading={loading} addClass="heigth330px" />
            <div
              className={`${loading ? 'hidden' : ''}`}
              style={{ maxHeight: '600px' }}
            >
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`${tabActive === 0 ? 'active' : ''} 
                                            ${
                                              tabHasError.tab1
                                                ? 'text-danger'
                                                : null
                                            }`}
                    onClick={() => {
                      setTabActive(0);
                    }}
                  >
                    <span id="general">Geral</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={clsx({
                      active: tabActive === 1,
                      'text-danger': tabHasError.tab2,
                      hidden: !showInventory,
                    })}
                    onClick={() => {
                      setTabActive(1);
                    }}
                  >
                    <span id="stock">Estoque</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={`${tabActive === 2 ? 'active' : ''} 
                                        ${
                                          tabHasError.tab2
                                            ? 'text-danger'
                                            : null
                                        }`}
                    onClick={() => {
                      setTabActive(2);
                    }}
                  >
                    <span id="providers">Fornecedores</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={tabActive}
                style={{ maxHeight: '400px', overflow: 'auto' }}
              >
                <TabPane tabId={0}>
                  <GeneralSummary
                    dataProductType={dataProductType}
                    dataUnit={dataUnit}
                    dataOrigin={dataOrigin}
                    dataProductStatus={dataProductStatus}
                    dataProductLocation={dataProductLocation}
                  />
                </TabPane>
                <TabPane tabId={1}>
                  <Stock />
                </TabPane>
                <TabPane tabId={2}>
                  <ProvidersSummary />
                </TabPane>
              </TabContent>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" disabled={loading}>
              <em className="fa fa-print" /> Imprimir Etiqueta
            </Button>
            <Button
              type="submit"
              id="submit-product-insert"
              className={`btn-theme ${loading ? 'hidden' : ''}`}
            >
              <em className="fa fa-save" /> Salvar
            </Button>
            <Button
              className="btn-ligth-gray"
              onClick={() => {
                handleCloseModal();
              }}
            >
              <em className="fa fa-door-closed" /> Fechar
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
      {/* </ValidationForm> */}
    </Modal>
  );
};

export default memo(ModalSummarizedProducts);
