import React, { useState, useEffect } from 'react';
import ChatContainer from './ChatContainer'


export default function ChatMessageContainer( { socket }) {
    const [showPopup, setShowPopup] = useState(false)
    const [chats, setChats]         = useState([])
    const [chat, setChat]           = useState({})
    
    

    const closeWindow = (e,id, _this) => {
        e.stopPropagation();
        const after = chats.filter((t, i) => {
            if (t.props.id !== id)
                return true;
        
            return false;
        });

        setChats(after)
    }

    const openChat = (id, status, imgURL, name) => {
        let _find = false
        chats.map((t, i) => {
            if (t.props.id === id) 
                _find = true
        });

        if (!_find) {
            socket.emit('getMessages',{room  : 110,
                                       users : [1,2]})
            const _props = {
                id : id,
                status : status,
                imgUrl : imgURL,
                name : name,
                messages : []
            }
            setChats(chat => chat.concat({props : _props}))
        }

    }
    
    const receiveData = () => {
        if (socket) {
            socket.on("sendMessage", data => {

                const _props = {
                        id : data.idsender,
                        status : data.idstatus,
                        imgUrl : data.imgUrl,
                        name :  data.name,
                        messages : [{he : data.message,status : 2, create_at : "2019-01-01 12:45:11"}]
                    }
                setChat({props : _props})            
            })

            socket.on("getMessages", data => {
                console.log(data)        
            })
        }
    }

    useEffect(() => {
        receiveData() 
    },[])

    useEffect(() => {
        if (Object.keys(chat).length > 0) {
            let _find = false
            chats.map((t, i) => {
                if (t.props.id === chat.props.id) 
                    _find = true
            });

            if (!_find) 
                setChats(chats => chats.concat(chat)) 
        }

    },[chat])

    useEffect(() => {
        if (socket) {
            socket.on("userslist", data => {
                let aux = chats.slice(0)
                setChats([])
                if (data !== null) {
                    data.map((user, key) => {
                        aux.map((item, key) => {
                            if (user.iduser === item.props.id) {
                                item.props.status = user.idstatus
                                setChats(chats => chats.concat({props : item.props}))
                            }
                        })
                    }) 
                }
            });
        }
    },[chats])

    window.openChat = openChat;
    return (
        <aside className="container-messages">
            {chats.map((chat, id) => {
                return (<ChatContainer key={id} props={chat.props} closeCallback={closeWindow} socket={socket}/>)
            })}
            <div style={{display:"flex", flexDirection:"column-reverse"}}>
                <div className={`chat-plus ${chats.length <= 0 ? "hidden" : ""}`} onMouseOver={e => setShowPopup(true)} onMouseOut={e => setShowPopup(false)}>
                    <em className="fa fa-comment-dots"/>
                </div>
                <div style={{backgroundColor:"red"}} className={`chat-popup ${!showPopup ? "hidden" : "fade-in"}`} onMouseOver={e => setShowPopup(true)} onMouseOut={e => setShowPopup(false)}></div>
                <div style={{backgroundColor:"green"}} className={`chat-popup ${!showPopup ? "hidden" : "fade-in"}`} onMouseOver={e => setShowPopup(true)} onMouseOut={e => setShowPopup(false)}></div>
                <div style={{backgroundColor:"blue"}} className={`chat-popup ${!showPopup ? "hidden" : "fade-in"}`} onMouseOver={e => setShowPopup(true)} onMouseOut={e => setShowPopup(false)}></div>
            </div>
        </aside> 
    );
}


