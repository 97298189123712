import { memo } from 'react';
import { Alert, Button, Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';
import Switch from '../../Forms/Inputs/switch';

const NfseTotalValues = () => {
    const { register, control } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="totalValues.cofins">COFINS(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalValues.cofins')}
                            id="totalValues-cofins"
                            type="number"
                            defaultValue={0}
                            step={0.01}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="totalValues.pis">PIS(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalValues.pis')}
                            id="totalValues-pis"
                            type="number"
                            defaultValue={0}
                            step={0.01}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="totalValues.csll">CSLL(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalValues.csll')}
                            id="totalValues-csll"
                            type="number"
                            defaultValue={0}
                            step={0.01}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="totalValues.ir">IR(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalValues.ir')}
                            id="totalValues-ir"
                            type="number"
                            defaultValue={0}
                            step={0.01}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="totalValues.inss">INSS(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalValues.inss')}
                            id="totalValues-inss"
                            type="number"
                            defaultValue={0}
                            step={0.01}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-2">
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="totalValues-confinsValue">Valor COFINS</label>
                    <div className="input-group">
                        <Controller
                            name="totalValues.confinsValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="totalValues.confinsValue"
                                    id="totalValues-confinsValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="totalValues-pisValue">Valor PIS</label>
                    <div className="input-group">
                        <Controller
                            name="totalValues.pisValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="totalValues.pisValue"
                                    id="totalValues-pisValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="totalValues-csllValue">Valor CSLL</label>
                    <div className="input-group">
                        <Controller
                            name="totalValues.csllValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="totalValues.csllValue"
                                    id="totalValues-csllValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="totalValues-irValue">Valor IR</label>
                    <div className="input-group">
                        <Controller
                            name="totalValues.irValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="totalValues.irValue"
                                    id="totalValues-irValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="totalValues-inssValue">Valor INSS</label>
                    <div className="input-group">
                        <Controller
                            name="totalValues.inssValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="totalValues.inssValue"
                                    id="totalValues-inssValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mb-2">
                <Col lg={3} md={3} sm={3} className="d-flex align-items-center">
                    <Switch name="totalValues.isToRetainCofins" label="Reter COFINS" />
                </Col>
                <Col lg={3} md={3} sm={3} className="d-flex align-items-center">
                    <Switch name="totalValues.isToRetainPis" label="Reter PIS" />
                </Col>
                <Col lg={2} md={2} sm={3} className="d-flex align-items-center">
                    <Switch name="totalValues.isToRetainCsll" label="Reter CSLL" />
                </Col>
                <Col lg={2} md={2} sm={3} className="d-flex align-items-center">
                    <Switch name="totalValues.isToRetainIr" label="Reter IR" />
                </Col>
                <Col lg={2} md={2} sm={3} className="d-flex align-items-center">
                    <Switch name="totalValues.isToRetainInss" label="Reter INSS" />
                </Col>
            </Row>

            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="totalValues.netValue">Valor líquido da nota</label>
                    <div className="d-flex align-items-center">
                        <div className="input-group">
                            <FormControl
                                {...register('totalValues.netValue')}
                                id="totalValues-netValue"
                                type="number"
                                defaultValue={0}
                                step={0.01}
                            />
                        </div>
                        <Button variant="primary" className="ms-2">
                            <i className="fa-solid fa-rotate-right" />
                        </Button>
                    </div>
                </Col>
                <Col lg={8} md={8} sm={12}>
                    <Alert variant="warning">
                        Atenção! Os valores dos impostos federais seráo descontados das parcelas da nota caso sejam
                        retidos.
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfseTotalValues);
