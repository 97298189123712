import { memo, useState } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ModalClient from '../../../pages/modals/modal-client';
import MaskInput from '../../Forms/Inputs/maskedInput';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeTotalizers = () => {
    const { register, control } = useFormContext();
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Container fluid>
            <ModalClient showModal={showModal} handleCloseModal={handleCloseModal} />
            <Row className="mb-2">
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="totalizers-cteTotal">Total CT-e</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalizers.cteTotal')}
                            name="totalizers.cteTotal"
                            id="totalizers-cteTotal"
                            type="number"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="totalizers-steNumber">Total NF-e</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalizers.steNumber')}
                            name="totalizers.steNumber"
                            id="totalizers-steNumber"
                            type="number"
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="totalizers-cargoTotalValue">Valor total de carga</label>
                    <div className="input-group">
                        <Controller
                            name="totalizers.cargoTotalValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="totalizers.cargoTotalValue"
                                    id="totalizers.cargoTotalValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="totalizers-cargoGrossWeight">Peso bruto de carga</label>
                    <div className="input-group">
                        <FormControl
                            {...register('totalizers.cargoGrossWeight')}
                            name="totalizers.cargoGrossWeight"
                            id="totalizers-cargoGrossWeight"
                            type="number"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="totalizers-unity">Unidade</label>
                    <div className="input-group">
                        <FormSelect {...register('totalizers.unity')} id="totalizers-unity" className="w-100">
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(MdfeTotalizers);
