import React from 'react';
import { FormControl } from 'react-bootstrap';
import { CEPMask, cnpjMask, cpfCnpjMask, cpfMask, currencyMask } from '../../../utils/masks';

const MaskInput = ({ mask, field, ...inputProps }) => {
    const handleChangeText = React.useCallback(
        (e) => {
            let value = '';
            switch (mask) {
                case 'currency':
                    e.currentTarget.maxLength = 12;
                    value = currencyMask(e.currentTarget.value);
                    break;
                case 'cpf':
                    e.currentTarget.maxLength = 14;
                    value = cpfMask(e.currentTarget.value);
                    break;
                case 'cnpj':
                    e.currentTarget.maxLength = 18;
                    value = cnpjMask(e.currentTarget.value);
                    break;
                case 'cpf/cnpj':
                    e.currentTarget.maxLength = 18;
                    value = cpfCnpjMask(e.currentTarget.value);

                    break;
                case 'cep':
                    e.currentTarget.maxLength = 10;
                    value = CEPMask(e.currentTarget.value);
                    break;
                default:
                    e.currentTarget.maxLength = 12;
                    value = currencyMask(e.currentTarget.value);
            }

            field.onChange(value);
            return (e.currentTarget.value = value);
        },
        [mask, field],
    );

    return (
        <>
            <FormControl
                name={field?.name}
                type="text"
                onChange={(e) => {
                    handleChangeText(e);
                }}
                value={field?.value}
                {...inputProps}
            />
        </>
    );
};

export default MaskInput;
