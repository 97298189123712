import { memo } from 'react';
import { Col, Container, FormSelect, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';

const genericArray = [
    { label: 'Acre', value: 'AC' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
    { value: 'EX', label: 'Estrangeiro' },
];
const MdfeRouteInformation = () => {
    const { register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'routeInformations.route', // unique name for your Field Array
    });

    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={10} md={10}>
                            <div className="input-group">
                                <label htmlFor="route-uf">UF de percurso</label>
                                <FormSelect
                                    {...register(`routeInformations.route.${index}.uf`)}
                                    id="characteristics-uf"
                                    className="w-100"
                                >
                                    <option value="">Selecione</option>

                                    {genericArray.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="adicionar munícipio"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                uf: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeRouteInformation);
