import { memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import MaskInput from '../../Forms/Inputs/maskedInput';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeDrivers = () => {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'drivers.driver', // unique name for your Field Array
    });

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };
    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={5} md={5}>
                            <div className="input-group">
                                <label htmlFor="driver-name">
                                    Nome do condutor <span className="text-danger"> *</span>
                                </label>
                                {/* <Controller
                                    name={`drivers.driver.${index}.name`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`drivers.driver.${index}.name`}
                                            isClearable
                                            defaultOptions
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </Col>
                        <Col lg={5} md={5} sm={12}>
                            <label htmlFor="driver-cpf">
                                CPF do condutor <span className="text-danger"> *</span>
                            </label>
                            <div className="input-group">
                                <Controller
                                    name={`drivers.driver.${index}.cpf`}
                                    control={control}
                                    render={({ field }) => (
                                        <MaskInput
                                            name={`drivers.driver.${index}.cpf`}
                                            id="driver-cpf"
                                            mask="cpf"
                                            field={field}
                                            className={`text-right`}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="Outro motorista"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                name: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeDrivers);
