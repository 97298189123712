import { memo } from 'react';
import {
    Col,
    Container,
    FormControl,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContainer,
    TabContent,
    TabPane,
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Switch from '../../Forms/Inputs/switch';
import NfceCfop from './NFCEParameters/nfce-cfop';
import NfceCofins from './NFCEParameters/nfce-cofins';
import NfceIcms from './NFCEParameters/nfce-icms';
import NfcePis from './NFCEParameters/nfce-pis';

const NfceParameters = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <section className="mt-3 pt-3 border-top">
                <div className="mb-3">
                    <Switch
                        name="fiscalTributary.nfceParameters.mustUseNfceParameters"
                        label="Parâmetros na nota fiscal- NFC-e- (se esta opção for marcada estes parâmetros sobrepôem os parâmetros gerais)"
                    />
                </div>
                <Row>
                    <Col lg={7} md={7}>
                        <TabContainer id="nfce-tab" defaultActiveKey="nfce-ICMS">
                            <Nav tabs className="nav-tabs" defaultValue="nfce-ICMS">
                                <NavItem>
                                    <NavLink eventKey="nfce-ICMS">
                                        <span id="nfce-ICMS">ICMS</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="nfce-PIS">
                                        <span id="nfce-PIS">PIS</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="nfce-COFINS">
                                        <span id="nfce-PIS">{'COFINS'}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink eventKey="nfce-CFOP">
                                        <span id="nfce-CFOP">CFOP</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent defaultValue="nfce-ICMS" defaultChecked="nfce-ICMS">
                                <TabPane eventKey="nfce-ICMS" label="nfce-ICMS">
                                    <NfceIcms />
                                </TabPane>

                                <TabPane eventKey="nfce-PIS" label="nfce-PIS">
                                    <NfcePis />
                                </TabPane>

                                <TabPane eventKey="nfce-COFINS" label="nfce-COFINS">
                                    <NfceCofins />
                                </TabPane>

                                <TabPane eventKey="nfce-CFOP" label="nfce-CFOP">
                                    <NfceCfop />
                                </TabPane>
                            </TabContent>
                        </TabContainer>
                    </Col>
                    <Col lg={5} md={5}>
                        <Row>
                            <Col lg={12} md={12}>
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">Valor aproximado dos tributos</span>
                                    <span>percentual de cálculo de tributos conforme Lei n° 12.741/12</span>
                                </div>
                                <Row>
                                    <Col lg={6} md={6} xs={12}>
                                        <label>Federal(%)</label>
                                        <div className="input-group">
                                            <FormControl
                                                {...register('fiscalTributary.nfceParameters.federal')}
                                                name="fiscalTributary.nfceParameters.federal"
                                                id="fiscalTributary.nfceParameters.federal"
                                                type="number"
                                                className="form-control "
                                                autoComplete="no"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12}>
                                        <label>Estadual(%)</label>
                                        <div className="input-group">
                                            <FormControl
                                                {...register('fiscalTributary.nfceParameters.state')}
                                                name="fiscalTributary.nfceParameters.state"
                                                id="fiscalTributary.nfceParameters.state"
                                                type="number"
                                                className="form-control "
                                                autoComplete="no"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} md={12}>
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">Informações adicionais do produto na NFC-e</span>
                                    <span>
                                        As informações abaixo aparecerão abaixo da descrição do produto da Danfe
                                    </span>
                                </div>
                                <Row>
                                    <Col lg={12} md={12} xs={12}>
                                        <div className="input-group">
                                            <FormControl
                                                {...register('fiscalTributary.nfceParameters.information')}
                                                name="fiscalTributary.nfceParameters.information"
                                                id="fiscalTributary.nfceParameters.information"
                                                as="textarea"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </Container>
    );
};

export default memo(NfceParameters);
