import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import MaskWithValidation from '../../components/maskInput';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCostCenter({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]       = useState(false)

    const [costcenter,setCostCenter]  = useState('')
    const [account,setAccount]        = useState('')
    const [idparent,setIDParent]      = useState(-1)

    const [dataParent, setDataParent] = useState([])

    const setData = (obj,clear) => {
        setCostCenter(clear ? '' : obj.costcenter) 
        setAccount(clear ? '' : obj.account) 
        setIDParent(clear ? '' : obj.idparent) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-cost-center'),document.querySelector('#submit-cost-center').children[0].classList,document.querySelector('#submit-cost-center').lastChild.nodeValue)

        try {
            let _data = {costcenter,
                         account,
                         idparent }

            if (editState)
                await api.put('/cost-center/' + data.idcostcenter, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-cost-center'))
                         } else
                             loadingButton(false,document.querySelector('#submit-cost-center'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-cost-center'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/cost-center', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-cost-center'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-cost-center'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-cost-center'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-cost-center'))
            ShowMessages(response)
        }
    }
    const getData = async() => {
        await api.get('/getCostCenterParent')
                 .then(response => {
                      if (response.ok) {   
                          console.log(response.data)
                         setDataParent(response.data)    
                      }
                })
                 .catch( error => {
                     ShowMessages(error) 
                })

        
    }

    useEffect(() => {
        getData()
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-cost-center')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-project-diagram"></i> Propriedades do Centro de Custo
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <div>
                            <label>Centro de Custo Pai</label>
                            <div>
                                <SelectGroup 
                                    name="idparent" 
                                    id="idparent" 
                                    placeholder="Selecione" 
                                    value={idparent} 
                                    className="form-select"
                                    onChange={e => setIDParent(parseInt(e.target.value))}>
                                    <option  value=''>Selecione</option>
                                    {dataParent.map((data, id) => {
                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                                    })} 
                                </SelectGroup>   
                            </div>
                        </div>

                        <div>
                            <label className='required'>Conta</label>
                            <div className="input-group">
                                <MaskWithValidation
                                    name="account"
                                    id="account"
                                    type="text"
                                    required
                                    errorMessage={{ required: "Por favor, informe uma conta!" }}
                                    className="form-control "
                                    value={account}
                                    autoFocus={true}
                                    onChange={e => setAccount(e.target.value)}
                                    autoComplete="off"
                                    mask={[/[0-9]/,'.',/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/]}/>
                            </div>
                        </div>   
                        <div>
                            <label className='required'>Centro de Custo</label>
                            <div className="input-group">
                                <TextInput  
                                    name="costcenter"
                                    id="costcenter"
                                    type="text"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe um centro de custo!" }}
                                    value={costcenter}
                                    className="form-control "
                                    onChange={e => setCostCenter(e.target.value)}
                                    autoComplete="off"/>
                            </div>
                        </div>   
                                                        
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-cost-center" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}