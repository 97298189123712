import React, { useState, useEffect } from 'react';

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalLocation({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
  setReturn,
}) {
  const [loading, setLoading] = useState(false);

  const [productlocation, setProductLocation] = useState('');

  const setData = (obj, clear) => {
    setProductLocation(clear ? '' : obj.productlocation);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-location'),
      document.querySelector('#submit-location').children[0].classList,
      document.querySelector('#submit-location').lastChild.nodeValue,
    );
    try {
      let _data = { productlocation };

      if (editState)
        await api
          .put('/location/' + data.idproductlocation, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-location'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-location'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-location'));
            ShowMessages(error);
          });
      else
        await api
          .post('/location', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                if (setReturn !== undefined) setReturn(response.data.idreturn);
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-location'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-location'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-location'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-location'));
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
  }, [data, editState, showModal]);

  useEffect(() => {
    setStatistic('modals\\modal-location');
  }, []);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-compass"></i> Propriedades de Locação
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          <div className={`${loading ? 'hidden' : ''}`}>
            <div>
              <label className="required">Locação</label>
              <div className="input-group">
                <TextInput
                  name="location"
                  id="location"
                  type="text"
                  required
                  autoFocus={true}
                  maxLength={100}
                  errorMessage={{ required: 'Por favor, informe uma locação!' }}
                  value={productlocation}
                  className="form-control "
                  onChange={(e) => setProductLocation(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-location"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button
            className="btn-ligth-gray"
            onClick={(e) => handleCloseModal(false)}
          >
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
