import React, { useState, useEffect } from 'react';
import ContentWrapper from '../layouts/contentWrapper';
import Loading from '../../components/Loading';
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic';
import SidebarRun from '../layouts/Sidebar.run';

import { checkPermission } from './../../components/security';
import { Collapse, Button } from 'reactstrap';
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';

export default function PermissionsUser(props) {
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  const [company, setCompany] = useState(0);
  const [dataCompany, setDataCompany] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [menu, setMenu] = useState([]);
  const [actions, setActions] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [idMenu, setIDMenu] = useState(0);

  
  const setComboCompany = async (value) => {
    let company = value;
    if (!checkPermission(256, 7)) company = ReadDataStorage(IDCOMPANY);

    setLoadingContent(true);
    setCompany(company);
    setPermissions([]);
    getPermissions(-1);
    buildCollapseList(menu, true, false);
    console.log(permissions);
    await api
      .get('/action-permissions/U/' + props.props.id + '/' + company)
      .then((response) => {
        response.data.map((_actions) => {
          setPermissions((permissions) =>
            permissions.concat({
              name: _actions.name,
              inputName: _actions.nameinput,
              checked: _actions.checked === 1,
              idmenu: _actions.idmenu,
              permission: _actions.permission,
              idpermission: _actions.idpermission,
            }),
          );
        });
        setLoadingContent(false);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let _data = { permissions };

      await api
        .put('/permission/U/' + props.props.id + '/' + company, _data)
        .then((response) => {
          if (response.ok) ShowMessages(response.data);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  const checkedAll = (status) => {
    let _aux = permissions;
    setPermissions([]);
    _aux.map((permission, _id) => {
      _aux[_id].checked = status;
    });
    setPermissions(_aux);
    setActions(
      _aux.filter((permission) => {
        return permission.idmenu === idMenu;
      }),
    );
  };

  const setChecked = (e, id) => {
    e.stopPropagation();
    let _aux = permissions;
    setPermissions([]);
    _aux.map((permission, _id) => {
      if (permission.idpermission === id) {
        _aux[_id].checked = _aux[_id].checked === true ? false : true;
        setPermissions(_aux);
        setActions(
          permissions.filter((permission) => {
            return permission.idmenu === _aux[_id].idmenu;
          }),
        );
      }
    });
  };

  const itemType = (item) => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
  };

  const toggleItemCollapse = (stateName) => {
    var _collapse = collapse;

    if (_collapse['_collapse'] !== undefined)
      _collapse = _collapse['_collapse'];

    for (let c in _collapse) {
      if (_collapse[c] === true && c !== stateName) _collapse[c] = false;
    }

    _collapse[stateName] = !_collapse[stateName];
    setCollapse({ _collapse });
  };

  const SidebarItemHeader = ({ item }) => (
    <li className="nav-heading">
      <span>{item.heading}</span>
    </li>
  );

  const SidebarItem = ({ item, isActive, collapse }) => (
    <li className={isActive ? 'active' : ''}>
      <a
        href="/#"
        title={item.name}
        onClick={(e) => getPermissions(item.idmenu)}
      >
        {!collapse && (
          <em className="arrow-right" style={{ fontSize: '14px' }}></em>
        )}
        {collapse ? <em className={item.icon}></em> : ''}

        <span>{item.name}</span>
      </a>
    </li>
  );

  const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
    <li className={isActive ? 'active' : ''}>
      <div className="nav-item nav-item-permissions" onClick={handler}>
        {item.icon && <em className={item.icon}></em>}
        <span>{item.name}</span>
        <span className={isOpen ? 'arrow open' : 'arrow'}></span>
      </div>
      <Collapse isOpen={isOpen}>
        <ul id={item.path} className="sidebar-nav sidebar-subnav">
          {children}
        </ul>
      </Collapse>
    </li>
  );

  const SidebarSubHeader = ({ item }) => (
    <li className="sidebar-subnav-header">{item.name}</li>
  );

  const navigator = (route) => {
    this.props.history.push(route.replace('#', ''));
  };

  const closeSidebar = () => {
    this.props.actions.toggleSetting('asideToggled');
  };

  const routeActive = (_idmenu) => {
    return _idmenu === idMenu;
  };

  useEffect(() => {
    setStatistic('maintenance\\permissions-user');
  }, []);

  const buildCollapseList = (Menu, state, valueState) => {
    let _collapse = {};
    Menu
      //.filter(({heading}) => !heading)
      .forEach(({ name, path, submenu }) => {
        _collapse[name] = state ? valueState : routeActive(submenu ? -1 : path);
      });

    setCollapse({ _collapse });
  };

  const getPermissions = async (idmenu) => {
    setIDMenu(idmenu);
    console.log(permissions);
    setActions(
      permissions.filter((permission) => {
        return permission.idmenu === idmenu;
      }),
    );
  };

  const _sidebar = async (_props) => {
    setLoading(true);

    await api
      .get('/menus')
      .then((response) => {
        buildCollapseList(response.data);
        setMenu(response.data);
      })
      .catch((error) => {
        setMenu([]);
        ShowMessages(error);
      });

    await api
      .get('/getcompany')
      .then((response) => {
        setDataCompany(response.data);
        setCompany(response.data[0].value);
        setComboCompany(response.data[0].value);
      })
      .catch((error) => {
        ShowMessages(error);
      });

    setLoading(false);
  };

  useEffect(() => {
    _sidebar();
  }, []);

  useEffect(() => {}, [permissions, actions]);

  return (
    <ContentWrapper className="content-wrapper-bottom81 ">
      <Loading loading={loading} addClass="heigth60vh" />
      <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
        <div className="content-heading">
          <div className="box-maintance-buttons">
            <button
              className="btn btn-danger"
              style={{ marginLeft: '3px' }}
              title="Permitir todas as ações"
              onClick={(e) => checkedAll(true)}
            >
              <i className="fa fa-check"></i>
            </button>
            <button
              className="btn btn-warning"
              style={{ marginLeft: '3px' }}
              title="Negar todas as ações"
              onClick={(e) => checkedAll(false)}
            >
              <i className="fa fa-ban"></i>
            </button>

            <div
              className="input-group"
              style={{
                marginLeft: '20px',
                maxWidth: '250px',
                minWidth: '250px',
                marginTop: '-10px',
              }}
            >
              <div>
                <span className="text-default">Selecione a empresa</span>
                <select
                  name="company"
                  id="company"
                  placeholder="Selecione"
                  disabled={!checkPermission(256, 7)}
                  value={company}
                  className="form-select"
                  onChange={(e) => setComboCompany(e.target.value)}
                >
                  {dataCompany !== null
                    ? dataCompany.map((data, id) => {
                        return (
                          <option key={id} value={data.value}>
                            {data.label}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </div>
            </div>
            <div className="box-min-warning">
              <span>
                <i
                  className="fa fa-exclamation-triangle"
                  style={{ marginRight: '5px' }}
                />
                Após finalizar as configurações do usuários na empresa
                selecionada, salve as alterações antes de alterar entre o grupo
                de empresas
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`content-box-cart-page adjust-height ${
          loading ? 'hidden' : ''
        }`}
      >
        <Loading loading={loadingContent} addClass="heigth50vh" />
        <div className="box-permissions">
          <nav
            data-sidebar-anyclick-close=""
            className={`sidebar1 ${loadingContent ? 'hidden' : ''}`}
            style={{ overflow: 'hidden', width: '275px' }}
          >
            <ul className="sidebar-nav">
              {menu.map((item, i) => {
                // heading
                if (itemType(item) === 'heading')
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (itemType(item) === 'menu')
                    return (
                      <SidebarItem
                        isActive={routeActive(item.idmenu)}
                        item={item}
                        key={i}
                        collapse={true}
                      />
                    );
                  if (itemType(item) === 'submenu')
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={collapse['_collapse'][item.name]}
                        handler={toggleItemCollapse.bind(this, item.name)}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => (
                          <SidebarItem
                            key={i}
                            item={subitem}
                            isActive={routeActive(subitem.idmenu)}
                            collapse={false}
                          />
                        ))}
                      </SidebarSubItem>,
                    ];
                }
                return null; // unrecognized item
              })}
              {SidebarRun(navigator, closeSidebar)}
            </ul>
          </nav>
        </div>

        <div
          className={`${loadingContent ? 'hidden' : ''} ${
            actions.length <= 0 ? ' flex-align-center ' : ''
          }`}
          style={{
            width: '100%',
            marginLeft: '25px',
            border: '1px solid #EAEAEA',
            padding: '20px',
            borderRadius: '5px',
            display: 'flex',
          }}
        >
          <div
            className={`box-not-register bg-info ${
              actions.length > 0 ? 'hidden' : ''
            }`}
            style={{ width: '100%', maxHeight: '83px', marginTop: '0px' }}
          >
            <em className="fa fa-bell" style={{ lineHeight: '27.33px' }}></em>{' '}
            <span style={{ marginLeft: '10px' }}>
              Selecione um item do menu do lado esquerdo para listar as
              permissões!
            </span>
          </div>
          <table
            className={`table table-striped w-100 ${
              actions.length <= 0 ? 'hidden' : ''
            }`}
            style={{ height: '10px' }}
          >
            <thead className="thead-theme">
              <tr>
                <th className="head-th">Descrição da permissão</th>
                <th className="head-th text-center" style={{ width: '115px' }}>
                  Permitir?
                </th>
              </tr>
            </thead>
            <tbody>
              {actions.map((_actions, id) => {
                return (
                  <tr className="gradeX" key={id}>
                    <td>{_actions.name}</td>
                    <td>
                      <label
                        className="switch switch-sm"
                        style={{ justifyContent: 'center' }}
                      >
                        <input
                          type="checkbox"
                          checked={_actions.checked}
                          value={_actions.checked}
                          onClick={(e) => setChecked(e, _actions.idpermission)}
                        />
                        <span style={{ marginTop: '5px' }}></span>
                      </label>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="modal-footer footer-fixed modal-footer-bottom-10">
        <Button
          type="submit"
          className={`btn-theme ${loading ? 'hidden' : ''}`}
          onClick={(e) => handleSubmit(e)}
        >
          <em className="fa fa-save"></em> Salvar
        </Button>
        <Button
          className="btn-ligth-gray"
          onClick={(e) => window.removeTab(`tab_${props.id}`, e)}
        >
          <em className="fa fa-door-closed"></em> Fechar
        </Button>
      </div>
    </ContentWrapper>
  );
}
