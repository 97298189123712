import React, { useState } from 'react'

const TitleToggle = ({ children, title, subtitle, marginTop }) => {
    const [collapsed, setCollapsed] = useState(true)
    return (
        <div style={{ cursor: 'pointer', userSelect: 'none', marginTop: marginTop }}>
            <h4 className='d-flex' onClick={() => setCollapsed(!collapsed)}>
                <i className={`fa-solid fa-caret-${collapsed ? 'down' : 'up'} mr-2`}></i> <span style={{marginLeft: '5px'}}>{title}</span>
                <p style={{ fontSize: '0.8rem', margin: 0, color: '#999', alignSelf: 'center' }}>{subtitle}</p>
            </h4>
            <hr />
            <div style={{ display: collapsed ? 'flex' : 'none', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', gap: '8px' }}>
                {children}
            </div>
        </div>
    )
}

export default TitleToggle