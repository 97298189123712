import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const CteDocumentInformation = () => {
    const { register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'documentInformation.acessKeys', // unique name for your Field Array
    });

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="documentInformation-serviceType">Tipo de serviço</label>
                    <div className="input-group">
                        <FormSelect
                            {...register(`documentInformation.serviceType`)}
                            id="documentInformation-serviceType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className="input-group">
                                <label htmlFor="accessKeys-accesskey">Chave de acesso</label>
                                <FormControl
                                    {...register(`documentInformation.accessKeys.${index}.accessKey`)}
                                    name={`documentInformation.accessKeys.${index}.accessKey`}
                                    id="accessKeys-accesskey"
                                    type="text"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="adicionar chave de acesso"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                accessKey: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(CteDocumentInformation);
