import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const NfetotalValues = () => {
    const { register, control } = useFormContext({});
    return (
        <Container fluid>
            <Row className="mt-2">
                <Col md={2} lg={2}>
                    <label>BC de ICMS</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('totalValues.icmsbc')}
                                name="totalValues.icmsbc"
                                id="totalValues.icmsbc"
                                type="number"
                                maxLength={100}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor do ICMS</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('totalValues.icmsValue')}
                                name="totalValues.icmsValue"
                                id="totalValues.icmsValue"
                                type="number"
                                maxLength={100}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>BC de ICMS-ST</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('totalValues.icmsStBc')}
                                name="totalValues.icmsStBc"
                                id="totalValues.icmsStBc"
                                type="number"
                                maxLength={100}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor do ICMS-ST</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('totalValues.icmsStValue')}
                                name="totalValues.icmsStValue"
                                id="totalValues.icmsStValue"
                                type="number"
                                maxLength={100}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor do IPI</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('totalValues.IpiValue')}
                                name="totalValues.IpiValue"
                                id="totalValues.IpiValue"
                                type="number"
                                maxLength={100}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor dos Produtos</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <Controller
                                name="totalValues.productsValue"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="totalValues.productsValue"
                                        id="totalValues-productsValue"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={3} lg={3}>
                    <label>Desconto (R$)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <Controller
                                name="totalValues.discountInMoney"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="totalValues.discountInMoney"
                                        id="totalValues.discountInMoney"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} lg={3}>
                    <label>Desconto (%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('totalValues.discountInPercentage')}
                                name="totalValues.discountInPercentage"
                                id="totalValues.discountInPercentage"
                                type="number"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor do frete</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <Controller
                                name="totalValues.freightCost"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="totalValues.freightCost"
                                        id="totalValues.freightCost"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor das despesas</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <Controller
                                name="totalValues.expenseAmount"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="totalValues.expenseAmount"
                                        id="totalValues.expenseAmount"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Valor do seguro</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <Controller
                                name="totalValues.insurancePrice"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="totalValues.insurancePrice"
                                        id="totalValues.insurancePrice"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={5} lg={5}>
                    <label>Valor total da nota</label>
                    <div className="d-flex">
                        <div className="w-100 d-flex">
                            <Controller
                                name="totalValues.totalInvoiceValue"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="totalValues.totalInvoiceValue"
                                        id="totalValues.totalInvoiceValue"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                            <button className="btn btn-primary ms-2">
                                <i className="fa-solid fa-rotate-right" />
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfetotalValues);
