import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const isOwnerArr = [
    { label: 'Sim', value: 'sim' },
    { label: 'Não', value: 'nao' },
];

const genericArray = [
    { label: 'Acre', value: 'AC' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
    { value: 'EX', label: 'Estrangeiro' },
];

const MdfeTows = () => {
    const { control, register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'tows.tow', // unique name for your Field Array
    });

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };
    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={2} md={4}>
                            <div className="input-group">
                                <label htmlFor="tow-plateVehicle">Placa</label>
                                {/* <Controller
                                    name={`tows.tow.${index}.plateVehicle`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`tows.tow.${index}.plateVehicle`}
                                            id="tow-plateVehicle"
                                            defaultOptions
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="tow-truckBody">Tipo de carroceria</label>
                                <FormControl
                                    {...register(`tows.tow.${index}.truckBody`)}
                                    className="form-control"
                                    name={`tows.tow.${index}.truckBody`}
                                    id="tow-truckBody"
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="tow-uf">UF</label>
                                <FormSelect {...register(`tows.tow.${index}.uf`)} id="tow-uf" className="w-100">
                                    <option value="">Selecione</option>

                                    {genericArray.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </div>
                        </Col>
                        <Col lg={1} md={3}>
                            <div className="input-group">
                                <label htmlFor="tow-capacity">Capacidade</label>
                                <FormControl
                                    {...register(`tows.tow.${index}.capacity`)}
                                    className="form-control"
                                    name={`tows.tow.${index}.capacity`}
                                    id="tow-capacity"
                                    placeholder="kg"
                                    type="text"
                                />
                            </div>
                        </Col>
                        <Col lg={1} md={3}>
                            <div className="input-group">
                                <label htmlFor="tow-tara">Tara(KG)</label>
                                <FormControl
                                    {...register(`tows.tow.${index}.tara`)}
                                    className="form-control"
                                    name={`tows.tow.${index}.tara`}
                                    id="tow-tara"
                                    placeholder="kg"
                                    type="text"
                                />
                            </div>
                        </Col>

                        <Col lg={2} md={3}>
                            <div className="input-group">
                                <label htmlFor="tow-isOwner">Sou Proprietário</label>
                                <FormSelect
                                    {...register(`tows.tow.${index}.isOwner`)}
                                    id="tow-isOwner"
                                    className="w-100"
                                >
                                    <option value="">Selecione</option>

                                    {isOwnerArr.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="Outro motorista"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                plateVehicle: '',
                                truckBody: '',
                                capacity: '',
                                uf: '',
                                tara: '',
                                isOwner: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeTows);
