import React, { useState, useEffect } from 'react';

import {
  ValidationForm,
  TextInput,
  SelectGroup,
} from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';
import { format } from 'date-fns';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalHoliday({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [holiday, setHoliday] = useState('');
  const [holidayOperation, setHolidayOperation] = useState(0);
  const [dataHolidayOperation, setDataHolidayOperation] = useState([]);

  const setData = (obj, clear) => {
    console.log(obj);
    setDescription(clear ? '' : obj.description);
    setHoliday(clear ? '' : format(new Date(obj.holiday), 'yyyy-MM-dd'));
    setHolidayOperation(clear ? '' : obj.idholidayoperation);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-holiday'),
      document.querySelector('#submit-holiday').children[0].classList,
      document.querySelector('#submit-holiday').lastChild.nodeValue,
    );
    try {
      let _data = { description, holiday, holidayOperation };

      if (editState)
        await api
          .put('/holidays/' + data.idholiday, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(false, document.querySelector('#submit-holiday'));
            } else
              loadingButton(false, document.querySelector('#submit-holiday'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-holiday'));
            ShowMessages(error);
          });
      else
        await api
          .post('/holidays', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(false, document.querySelector('#submit-holiday'));
            } else
              loadingButton(false, document.querySelector('#submit-holiday'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-holiday'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-holiday'));
      ShowMessages(response);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api.get('/getDataHolidayOperation').then((response) => {
        if (response.ok) {
          setDataHolidayOperation(response.data);
        }
      });

      setLoading(false);
    } catch (response) {
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
  }, [data, editState, showModal]);

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-holiday');
  }, []);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        className="modal-content"
        setFocusOnError={true}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-hands-praying"></i> Feriados
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          <div className={`${loading ? 'hidden' : ''}`}>
            <div>
              <label className="required">Descrição</label>
              <div className="input-group">
                <TextInput
                  name="description"
                  id="description"
                  type="text"
                  required
                  autoFocus={true}
                  maxLength={100}
                  errorMessage={{
                    required: 'Por favor, informe uma descrição!',
                  }}
                  value={description}
                  className="form-control "
                  onChange={(e) => setDescription(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div>
                <label className="required">Data do Feriado</label>
                <div className="input-group">
                  <TextInput
                    name="holiday"
                    id="holiday"
                    type="date"
                    required
                    maxLength={100}
                    errorMessage={{ required: 'Por favor, informe uma data!' }}
                    value={holiday}
                    className="form-control "
                    onChange={(e) => setHoliday(e.target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <label className="required">Período do Expediente</label>
                <div>
                  <SelectGroup
                    name="holidayOperation"
                    id="holidayOperation"
                    placeholder="Selecione"
                    required
                    errorMessage={{
                      required: 'Por favor, informe um regime tributário!',
                    }}
                    className="form-select"
                    value={holidayOperation}
                    onChange={(e) =>
                      setHolidayOperation(parseInt(e.target.value))
                    }
                  >
                    <option value="">Selecione</option>
                    {dataHolidayOperation.map((data, id) => {
                      return (
                        <option key={id} value={data.value}>
                          {data.label}
                        </option>
                      );
                    })}
                  </SelectGroup>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-holiday"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
