import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from './../../assets/logo.png'
import api from '../../services/api'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import ShowMessages from './../../components/Exceptions';
import setStatistic from './../../services/apiStatistic'

import  loadingButton  from './../layouts/Buttons'

export default function Forgot(props) {
    const [access, setAccess] = useState('')

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit'),document.querySelector('#submit').children[0].classList,document.querySelector('#submit').lastChild.nodeValue)  

        try {
            await api.post('/forgot', {
                access
            })
            .then(response => {
                if (response.ok) {
                    if (response.data.status === 200)
                        NotificationManager.success(response.data.message, 'Sucesso!', 2000);
                    else
                        NotificationManager.error(response.data.message, 'Erro!', 2000);
                    var _t = setInterval(function() {
                        clearInterval(_t)
                        window.location.href = '/login'
                    },4000) 
                }
            })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit'),'fa fa-redo','Recuperar')  
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setStatistic('forgot')
    } ,[])

    return (
        <>
        <div className="background">
        </div>
        <div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div className="content">
                <NotificationContainer />
                <div className="block-center wd-xl">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-blue">
                            <Link to="login">

                                <img className="block-center rounded imgWidth" src={logo} alt={process.env.REACT_APP_NAME} />
                            </Link>
                        </div>
                        <div className="card-body">
                            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                                <div className="input-group mb-3" style={{flexDirection:"column"}}>
                                    <label>Informe o seu email, telefone ou matrícula!</label>
                                    <TextInput
                                        name="data"
                                        id="data"
                                        type="text"
                                        placeholder="Informe aqui..."
                                        required
                                        maxLength={60}
                                        errorMessage={{ required: "Por favor, informe algum meio de acesso!" }}
                                        value={access}
                                        className="form-control "
                                        onChange={e => setAccess(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                                <button className="btn btn-block btn-theme mt-3" type="submit" id='submit' style={{width:"100%"}}><em className="fa fa-redo"></em> Recuperar</button>
                                <div className="float-right" style={{marginTop:"15px"}}>
                                    <Link to="/login" className="text-muted">Voltar para o login?</Link>
                                </div>
                            </ValidationForm>

                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    )

}


