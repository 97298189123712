import { memo, useState } from 'react';
import { Button, Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import ModalClient from '../../../pages/modals/modal-client';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const CteGeneralInformation = () => {
    const { register, control } = useFormContext();
    const [showModal, setShowModal] = useState(false);

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Container fluid>
            <ModalClient showModal={showModal} handleCloseModal={handleCloseModal} />
            <Row className="mb-2">
                <Col lg={2} md={2}>
                    <label htmlFor="generalInformation-cteType">Tipo do CT-e</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('generalInformation.cteType')}
                            id="generalInformation-cteType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={2} md={2}>
                    <label htmlFor="generalInformation-serviceType">Tipo do serviço</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('generalInformation.serviceType')}
                            id="generalInformation-serviceType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-emissionDate">Data de emissão</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.emissionDate')}
                            id="generalInformation-emissionDate"
                            type="date"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-emissionHour">Hora de emissão</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.emissionHour')}
                            id="generalInformation-emissionHour"
                            type="time"
                            placeholder="hora"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-steSeries">Série CT-e</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.steSeries')}
                            name="generalInformation.steSeries"
                            id="generalInformation-steSeries"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-steNumber">Número CT-e</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.steNumber')}
                            name="generalInformation.steNumber"
                            id="generalInformation-steNumber"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={5} md={5} className="d-flex align-items-end">
                    <div className="w-100">
                        <label htmlFor="generalInformation-cfop">CFOP</label>
                        <div className="input-group">
                            <div>
                                {/* <Controller
                                    name={`generalInformation.cfop`}
                                    control={control}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`generalInformation.cfop`}
                                            id="generalInformation-cfop"
                                            isClearable
                                            defaultOptions
                                            placeholder={'CFOP'}
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={7} md={7}>
                    <label htmlFor="generalInformation-nature">Natureza</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.nature')}
                            name="generalInformation.nature"
                            id="generalInformation-nature"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={4} md={4}>
                    <label htmlFor="generalInformation-serviceTaker">Tomador do serviço</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('generalInformation.serviceTaker')}
                            id="generalInformation-serviceTaker"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={4} md={4} className="d-flex align-items-end">
                    <div className="w-100">
                        <label htmlFor="generalInformation-sender">Remetente</label>
                        <div className="input-group">
                            <div>
                                {/* <Controller
                                    name={`generalInformation.sender`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`generalInformation.sender`}
                                            id="generalInformation-sender"
                                            isClearable
                                            defaultOptions
                                            placeholder={'Remetente'}
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <Button
                        title="Adicionar Cliente"
                        variant="success"
                        id="button-add-client"
                        className="ms-2 mb-1"
                        style={{ width: 'max-content' }}
                        onClick={() => setShowModal(true)}
                    >
                        <i className="fa-solid fa-plus" />
                    </Button>
                </Col>
                <Col lg={4} md={4} className="d-flex align-items-end">
                    <div className="w-100">
                        <label htmlFor="generalInformation-recipient">Destinatário</label>
                        <div className="input-group">
                            <div>
                                {/* <Controller
                                    name={`generalInformation.recipient`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`generalInformation.recipient`}
                                            id="generalInformation-recipient"
                                            isClearable
                                            defaultOptions
                                            placeholder={'Destinatário'}
                                            loadOptions={loadOptions}
                                        />
                                    )} 
                                />*/}
                            </div>
                        </div>
                    </div>
                    <Button
                        title="Adicionar Cliente"
                        variant="success"
                        id="button-add-client"
                        className="ms-2 mb-1"
                        style={{ width: 'max-content' }}
                        onClick={() => setShowModal(true)}
                    >
                        <i className="fa-solid fa-plus" />
                    </Button>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="generalInformation-serviceStartCity">Cidade do início da prestação</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.serviceStartCity')}
                            name="generalInformation.serviceStartCity"
                            id="generalInformation-serviceStartCity"
                            type="text"
                            placeholder="Cidade do início da prestação"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="generalInformation-serviceFinalCity">Cidade do final da prestação</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.serviceFinalCity')}
                            name="generalInformation.serviceFinalCity"
                            id="generalInformation-serviceFinalCity"
                            type="text"
                            placeholder="Cidade do final da prestação"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-shipper">Expedidor</label>
                    <div className="input-group">
                        <div>
                            {/* <Controller
                                name={`generalInformation.shipper`}
                                control={control}
                                // rules={{ required: false }}
                                render={({ field }) => (
                                    <AsyncSelect
                                        {...field}
                                        name={`generalInformation.shipper`}
                                        id="generalInformation-shipper"
                                        isClearable
                                        defaultOptions
                                        placeholder={'Expedidor'}
                                        loadOptions={loadOptions}
                                    />
                                )}
                            /> */}
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-receiver">Recebedor</label>
                    <div className="input-group">
                        <div>
                            {/* <Controller
                                name={`generalInformation.receiver`}
                                control={control}
                                // rules={{ required: false }}
                                render={({ field }) => (
                                    <AsyncSelect
                                        {...field}
                                        name={`generalInformation.receiver`}
                                        id="generalInformation-receiver"
                                        isClearable
                                        defaultOptions
                                        placeholder={'Recebedor'}
                                        loadOptions={loadOptions}
                                    />
                                )}
                            /> */}
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-taker">Tomador</label>
                    <div className="input-group">
                        <div>
                            {/* <Controller
                                name={`generalInformation.taker`}
                                control={control}
                                // rules={{ required: false }}
                                render={({ field }) => (
                                    <AsyncSelect
                                        {...field}
                                        name={`generalInformation.taker`}
                                        id="generalInformation-taker"
                                        isClearable
                                        defaultOptions
                                        placeholder={'Tomador'}
                                        loadOptions={loadOptions}
                                    />
                                )}
                            /> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteGeneralInformation);
