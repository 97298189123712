import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import MaskInput from '../../Forms/Inputs/maskedInput';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeTollVouchers = () => {
    const { control, register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'tollVouchers.tollVoucher', // unique name for your Field Array
    });

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };
    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="tollVouchers-vehicleCategory">Categoria de combinação veicular</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('tollVouchers.vehicleCategory')}
                            id="tollVouchers-vehicleCategory"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>

                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="tollVoucher-providerCnpj">CNPJ do Fornecedor</label>
                                {/* <Controller
                                    name={`tollVouchers.tollVoucher.${index}.providerCnpj`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`tollVouchers.tollVoucher.${index}.providerCnpj`}
                                            id="tollVoucher-providerCnpj"
                                            placeholder="selecione"
                                            defaultOptions
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <label htmlFor="tollVoucher-responsibleCpfCnpj">CPF/CNPJ do responsável</label>
                            <div className="input-group">
                                <Controller
                                    name={`tollVouchers.tollVoucher.${index}.responsibleCpfCnpj`}
                                    control={control}
                                    render={({ field }) => (
                                        <MaskInput
                                            name={`tollVouchers.tollVoucher.${index}.responsibleCpfCnpj`}
                                            id="tollVoucher-responsibleCpfCnpj"
                                            mask="cpf/cnpj"
                                            field={field}
                                            className={`text-right`}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                            <label htmlFor="tollVoucher-voucherNumber">Núm. do comprovante</label>
                            <div className="input-group">
                                <FormControl
                                    {...register(`tollVouchers.tollVoucher.${index}.voucherNumber`)}
                                    name={`tollVouchers.tollVoucher.${index}.voucherNumber`}
                                    id="tollVoucher-voucherNumber"
                                    type="number"
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                            <label htmlFor="tollVoucher-voucherValue">Valor do vale</label>
                            <div className="input-group">
                                <Controller
                                    name={`tollVouchers.tollVoucher.${index}.voucherValue`}
                                    control={control}
                                    render={({ field }) => (
                                        <MaskInput
                                            name={`tollVouchers.tollVoucher.${index}.voucherValue`}
                                            id="tollVoucher-voucherValue"
                                            mask="currency"
                                            field={field}
                                            className={`text-right`}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2} sm={12}>
                            <label htmlFor="tollVoucher-voucherType">Tipo do vale</label>
                            <div className="input-group">
                                <FormSelect
                                    {...register(`tollVouchers.tollVoucher.${index}.voucherType`)}
                                    id="tollVoucher-voucherType"
                                    className="w-100"
                                >
                                    <option value="">Selecione</option>

                                    {genericArray.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </div>
                        </Col>

                        <Col className="align-self-end col-md-auto col-lg-auto">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="Outro código da operação de transporte"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                hirer: '',
                                cpfCnpj: '',
                                indentifier: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeTollVouchers);
