import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NfseObservations = () => {
    const { register } = useFormContext();
    return (
        <Container fluid>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <label htmlFor="observations-internalObservations">Observações internas</label>
                    <div className="input-group">
                        <FormControl
                            {...register('observations.internalObservations')}
                            name="observations.internalObservations"
                            id="observations-internalObservations"
                            as="textarea"
                            rows={6}
                            placeholder="Esta informação é de uso interno, portanto não será impressa na nota"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfseObservations);
