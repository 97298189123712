import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const CteInvoices = () => {
    const { control, register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={3} md={3}>
                    <div className="input-group">
                        <label htmlFor="invoices-number">Numero</label>
                        <FormControl
                            {...register(`invoices.number`)}
                            name={`invoices.number`}
                            id="invoices-number"
                            type="text"
                            className="form-control"
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="invoices-originAmount">Valor origem (R$)</label>
                    <div className="input-group">
                        <Controller
                            name="invoices.originAmount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="invoices.originAmount"
                                    id="invoices.originAmount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="invoices-discountAmount">Valor desconto (R$)</label>
                    <div className="input-group">
                        <Controller
                            name="invoices.discountAmount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="invoices.discountAmount"
                                    id="invoices.discountAmount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="invoices-amount">Valor (R$)</label>
                    <div className="input-group">
                        <Controller
                            name="invoices.amount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="invoices.amount"
                                    id="invoices.amount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteInvoices);
