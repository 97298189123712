import React, { Component } from 'react'

import logo from './../../assets/logo-404.png'

export default class Error404 extends Component {
    render() {
        return (
            <section>
                <div className="box">
                    <img src={logo} alt={process.env.REACT_APP_NAME}/>
                    <span><strong>404</strong></span>
                    <span>Página não encontrada</span>
                </div>
            </section>

                )
            }
        }
        