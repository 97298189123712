import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import MaskInput from '../../Forms/Inputs/maskedInput';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeHirers = () => {
    const { control, register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'hirers.hirer', // unique name for your Field Array
    });

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };
    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className="input-group">
                                <label htmlFor="hirer-hirer">Contratante</label>
                                {/* <Controller
                                    name={`hirers.hirer.${index}.hirer`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`hirers.hirer.${index}.hirer`}
                                            id="hirer-hirer"
                                            defaultOptions
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </Col>
                        <Col lg={3} md={3}>
                            <label htmlFor="hirer-cpfCnpj">CPF/CNPJ</label>
                            <div className="input-group">
                                <div className="input-group">
                                    <Controller
                                        name={`hirers.hirer.${index}.cpfCnpj`}
                                        control={control}
                                        render={({ field }) => (
                                            <MaskInput
                                                name={`hirers.hirer.${index}.cpfCnpj`}
                                                id="hirer.cpfCnpj"
                                                mask="cpf/cnpj"
                                                field={field}
                                                className={`text-right`}
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={12}>
                            <label htmlFor="hirer-indentifier">Identificador</label>
                            <div className="input-group">
                                <FormControl
                                    {...register(`hirers.hirer.${index}.indentifier`)}
                                    name={`hirers.hirer.${index}.indentifier`}
                                    id="hirer-indentifier"
                                    placeholder="identificador do contratante"
                                    type="number"
                                />
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="Outro código da operação de transporte"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                hirer: '',
                                cpfCnpj: '',
                                indentifier: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeHirers);
