import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading';
import { getUserData, IDSTATUS, ReadDataStorage, WriteDataStorage } from './../../services/auth';


export default function ChatUserList( { socket }) {
    const [users, setUsers]           = useState([])
    const [loading, setLoading]       = useState(false)
    const [idUser, setIdUser]         = useState(0)
    const [status, setStatus]         = useState(1)

    const _setStatus = (status) => {
        document.querySelector('#sp-user-status').classList = []
 
        switch (status) {
            case 0:
                document.querySelector('#sp-user-status').classList.add('circle','circle-lg','bg-mutted','shadow-mutted')
                break;
            case 1:
                document.querySelector('#sp-user-status').classList.add('circle','circle-lg','bg-success','shadow-success')
                break;
            case 2:
                document.querySelector('#sp-user-status').classList.add('circle','circle-lg','bg-warning','shadow-warning')
                break;
            case 3:
                document.querySelector('#sp-user-status').classList.add('circle','circle-lg','bg-danger','shadow-danger')
                break;
            default:
                break;
        }
        WriteDataStorage(IDSTATUS,status)
        socket.emit('userstatus',status)
        setStatus(status)
    }

    if (getUserData() && socket) {
        socket.on("userslist", data => {
            if (data !== null) {
                data.map((user, key) => {
                    if (user.iduser === getUserData()['iduser'])
                        setStatus(user.idstatus)
                })
                setUsers(data)
                setLoading(false)
            } else {
                setUsers([])
                setLoading(true)
            }
            
        });

        socket.on("getInfo", data => {
           setUsers(data)
           setLoading(false)
        })

    }

    const getUsers = async() => {
        setStatus(ReadDataStorage(IDSTATUS))
        setLoading(true)
        setIdUser(getUserData()['iduser'])
    }

    useEffect(() => {
        getUsers()
    },[])

    return (
        <aside className="offsidebar">     
            <div className="chat-list">
                <div className="chat-userstatus">
                    <div style={{display:"flex", alignItems:"center"}}>
                       <span className={`circle circle-lg ${(status === 0) ? "bg-mutted shadow-mutted" : ((status === 1) ? "bg-success shadow-success" 
                                                                           : status === 2 ? "bg-warning shadow-warning"
                                                                                          : "bg-danger shadow-danger")}`}></span>
                    </div>
                
                    <select className="form-select chat-select-userstatus" value={status === null ? -1 : status} onChange={e => _setStatus(parseInt(e.target.value))}>
                        <option value={1}>Online</option>
                        <option value={2}>Ausente</option>
                        <option value={3}>Ocupado</option>
                    </select>
                </div>
                <div className="title-group">
                    <span>TODOS USUÁRIOS</span>
                </div>
                <Loading loading={loading}/>
                {(users && users.length > 0) ?
                  users.map((user, key) => {
                    let _name = user.name.length > 25 ? user.name.substr(0,22) + '...' : user.name

                    if (idUser === user.iduser) {
                        return
                    } else 
                        return (
                            <div key={key}>
                                <div className="chat-list-box" onClick={e => window.openChat(user.iduser, user.idstatus, user.pathphoto, _name)}>
                                    {user.pathphoto !== "" ? <img className="avatar" src={user.pathphoto}/>
                                                           : <div className="avatar-no-photo"><span>{_name.substr(0,1)}</span></div>}
                                    <div className='chat-box-info'>
                                        <div className="row-data">
                                            <span>{_name}</span>
                                            <div title={`${user.idstatus === 0 ? "Offline"
                                                                               : user.idstatus === 1 ? "Online"
                                                                                                     : user.idstatus === 2 ? "Ausente"
                                                                                                                           : "Ocupado"}`}>
                                                <span className={`circle circle-md ${user.idstatus === 0 ? "bg-mutted  shadow-mutted"
                                                                                                         : user.idstatus === 1 ? "bg-success shadow-success"
                                                                                                                               : user.idstatus === 2 ? "bg-warning shadow-warning"
                                                                                                                                                     : "bg-danger shadow-danger"}`}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        )
                }) : ""}

                
                        {/* <div className="chat-list-box active" onClick={e => window.openChat(window._this)}>
                        <div className="avatar-no-photo"><span>J</span></div>
                            <div className='chat-box-info'>
                                <div className="row-data">
                                    <span>{'Juliana Batista'}</span>
                                    <div>
                                        <span className="badge badge-light badge-pill">50</span>
                                        <span className="circle bg-danger circle-md"></span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="chat-list-box">
                            <img className="avatar" src="https://livechat.s3.amazonaws.com/default/avatars/male_8.jpg"/>
                            <div className='chat-box-info'>
                                <div className="row-data">
                                    <span>{'Renato Matos'}</span>
                                    <div>
                                        <em className="fa fa-birthday-cake" style={{color: "hotpink"}}/>
                                        <span className="circle bg-success circle-md"></span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
             </div>         
                 
            </aside>
        );
   

}

