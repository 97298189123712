import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NFECFOP = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            {/* LINHA 1 */}
            <Row className="mt-2">
                <Col lg={6} md={6} xs={12}>
                    <label>Venda Dentro do Estado</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.cfop.insideStateSales')}
                            name="fiscalTributary.nfeParameters.cfop.insideStateSales"
                            id="fiscalTributary.nfeParameters.cfop.insideStateSales"
                            type="number"
                            minLength={0}
                            maxLength={4}
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6} xs={12}>
                    <label>Venda Fora do Estado</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.cfop.outsideStateSales')}
                            name="fiscalTributary.nfeParameters.cfop.outsideStateSales"
                            id="fiscalTributary.nfeParameters.cfop.outsideStateSales"
                            type="number"
                            minLength={0}
                            maxLength={4}
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg={6} md={6} xs={12}>
                    <label>Entrada de Mercadoria</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.cfop.goodsReceipt')}
                            name="fiscalTributary.nfeParameters.cfop.goodsReceipt"
                            id="fiscalTributary.nfeParameters.cfop.goodsReceipt"
                            type="number"
                            minLength={0}
                            maxLength={4}
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6} xs={12}>
                    <label>Importação</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.cfop.import')}
                            name="fiscalTributary.nfeParameters.cfop.import"
                            id="fiscalTributary.nfeParameters.cfop.import"
                            type="number"
                            minLength={0}
                            maxLength={4}
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg={6} md={6} xs={12}>
                    <label>Exportação</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.cfop.export')}
                            name="fiscalTributary.nfeParameters.cfop.export"
                            id="fiscalTributary.nfeParameters.cfop.export"
                            type="number"
                            pattern="\d*"
                            maxLength="4"
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NFECFOP);
