import { USER_DATA, IDCOMPANY, ReadDataStorage} from './../services/auth'

var CryptoJS = require("crypto-js");

const CryptData = {
    secret_key : "myprotectkey2020",
};

export const Crypt = (string) => {
    return CryptoJS.AES.encrypt(string, CryptData.secret_key).toString();
};

export const  DeCrypt = (string) => {
    const bytes  = CryptoJS.AES.decrypt(string, CryptData.secret_key);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export const checkPermission  = (valuePermission,idmenu) => {
    let _data          = JSON.parse(ReadDataStorage(USER_DATA))
    let userPermission = 0
    
    if (_data.user_data.permission[ReadDataStorage(IDCOMPANY)] !== undefined)
        userPermission = _data.user_data.permission[ReadDataStorage(IDCOMPANY)][idmenu]

    let maxValue       = _data.max_menu[idmenu]
    let _array         = []
    let _aux           = parseInt(maxValue)

    while (_aux > 0) {
        _array.push(_aux)
        _aux = _aux / 2

        if (_aux < 1)
            _aux = 0
    }

    let bOK = false
    _array.map((value) => {
        if (value <= userPermission) {
            if (valuePermission === value) 
                bOK = true

            userPermission -= value
        }
    })
    return bOK
}