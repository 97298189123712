export const defaultValuesProduct = {
    product: {
        code: '',
        name: '',
        type: '',
        brand: '',
        unity: '',
        gtinEanCode: '',
        ncm: '',
        grossWeight: '',
        netWeight: '',
        costPrice: '',
        salePrice: '',
        origin: '',
        status: '',
        internalBarCode: '',
        stockLocation: '',
        initialStock: '',
        minimumStock: '',
        maximumStock: '',
        observation: '',
        productIsKit: false,
    },
    providers: {
        providers: [],
    },
    kitStructure: {
        isKit: false,
        dismemberItems: false,
        kit: [],
        movimentInventory: 'kit',
    },
    fiscalTributary: {
        icmsAliquot: '',
        ipiAliquot: '',
        pisAliquot: '',
        cofinsAliquot: '',
        taxableUnit: '',
        taxBenefitCode: '',
        cestCode: '',
        federal: '',
        state: '',
        nfeParameters: {
            mustUseNfeParameters: false,
            icms: {
                csosnCode: '',
                bcModality: '',
                federal: '',
                state: '',
                information: '',
            },
            ipi: {
                situationCode: '',
                framingCode: '',
                federal: '',
                state: '',
                information: '',
            },
            pis: {
                situationCode: '',
                isValueOfIcmsComposesPisBc: false,
                federal: '',
                state: '',
                information: '',
            },
            cofins: {
                situationCode: '',
                isValueOfIcmsComposesCofinsBc: false,
                federal: '',
                state: '',
                information: '',
            },
            cfop: {
                insideStateSales: '',
                outsideStateSales: '',
                goodsReceipt: '',
                import: '',
                export: '',
            },
            st: {
                determinationModality: '',
                icmsMva: '',
                icmsAliquot: '',
                bcReduction: '',
                federal: '',
                state: '',
                information: '',
            },
        },
        nfceParameters: {
            state: '',
            federal: '',
            information: '',
            mustUseNfceParameters: false,
            icms: {
                tributarySituation: '',
                bcModality: '',
            },
            pis: {
                tributarySituation: '',
                isValueOfIcmsComposesPisBc: false,
            },
            cofins: {
                tributarySituation: '',
                isValueOfIcmsComposesCofinsBc: false,
            },
            cfop: {
                consumerSale: '',
            },
        },
    },
};
export const defaultValuesNfe = {
    data: {
        transactionNature: '',
        purpose: '',
        type: '',
        series: '',
        number: '',
        client: '',
        seller: '',
    },
    products: {
        products: [
            {
                product: {
                    value: 'azul',
                    label: 'Azul',
                },
                quantity: '',
                ipi: '',
                icms: '',
                unitaryValue: '',
                cfop: '',
                ncm: '',
                totalValue: '',
            },
        ],
    },
    totalValues: {
        icmsBc: '',
        icmsValue: '',
        icmsStBc: '',
        icmsStValue: '',
        ipiValue: '',
        productsValue: '',
        discountInMoney: '',
        discountInPercentage: '',
        freightCost: '',
        expenseAmount: '',
        insurancePrice: '',
        totalInvoiceValue: '',
    },
    payment: {
        installmentsNumber: '',
    },
    transport: {
        grossWeight: '',
        netWeight: '',
        volume: '',
        kinds: '',
        brand: '',
        numbering: '',
        freightModality: '',
        shippingCompany: '',
        licensePlate: '',
        vehicleUf: '',
        rntc: '',
    },

    details: {
        informEmissionDate: false,
        informIETaxSubstitute: false,
        exitDate: '',
        exitHour: '',
        purchaseOrder: '',
        serviceType: '',
        operationDestination: '',
        intermediaryIndicator: '',
        intermediaryCnpj: '',
        intermediaryIndentifier: '',
        observations: '',
        internalObservations: '',
    },
};
export const defaultValuesNfse = {
    data: {
        client: '',
        technician: '',
        rpsSeries: '',
        rpsNumber: '',
        isClientNotIndentified: false,
    },
    details: {
        operationNature: '',
        operationRegime: '',
        provisionLocal: '',
        isCivilConstruction: false,
    },
    services: {
        description: '',
        serviceValue: '',
        calculationBasis: '',
        aliquot: '',
        taxAmount: '',
        isToRetainIss: false,
        deductionAmount: '',
        unconditionalAmount: '',
        conditionalAmount: '',
        federalTaxes: '',
        municipalTaxes: '',
        activity: '',
    },
    totalValues: {
        cofins: '',
        pis: '',
        csll: '',
        ir: '',
        inss: '',
        confinsValue: '',
        pisValue: '',
        csllValue: '',
        irValue: '',
        inssValue: '',
        isToRetainCofins: false,
        isToRetainPis: false,
        isToRetainCsll: false,
        isToRetainIr: false,
        isToRetainInss: false,
        netValue: '',
    },
    payment: {
        parcelsNumber: '',
    },
    observations: {
        internalObservations: '',
    },
};
export const defaultValuesCte = {
    generalInformation: {
        cteType: '',
        serviceType: '',
        emissionDate: '',
        emissionHour: '',
        steSeries: '',
        steNumber: '',
        cfop: '',
        nature: '',
        serviceTaker: '',
        sender: '',
        recipient: '',
        serviceStartCity: '',
        serviceFinalCity: '',
        shipper: '',
        receiver: '',
        taker: '',
    },
    cargoInformation: {
        amount: '',
        principalProduct: '',
        otherProductCharacteristic: '',
        characteristics: [],
    },
    road: {
        rntrc: '',
    },
    documentInformation: {
        serviceType: '',
        accessKeys: [],
    },
    transportDocuments: {
        informations: [],
    },
    charges: {
        suppliesTotalValues: '',
        accountsReceivable: '',
        taxesAproximateValues: '',
    },
    icms: {
        icmsCst: '',
        icmsCalculationBasis: '',
        icmsAliquot: '',
        icmsValue: '',
    },
    icmsSharing: {
        icmsValueCalculationBasis: '',
        endUfInternalAliquot: '',
        interstateAliquot: '',
        endUfPercentageSharing: '',
        startUfIcmsSharingValue: '',
        endUfIcmsSharingValue: '',
        endUfIcmsPercentageFCP: '',
        endUfIcmsValueFCP: '',
    },
    additionalInformation: {
        information: '',
    },
    invoices: {
        number: '',
        originAmount: '',
        discountAmount: '',
        amount: '',
    },
    installments: {
        installments: [],
    },
    payments: {
        parcelsNumber: '',
    },
};

export const defaultValuesMdfe = {
    generalInformation: {
        transporterType: '',
        emissionDate: '',
        emissionHour: '',
        mdfeSeries: '',
        mdfeNumber: '',
        loadingUf: '',
        unloadingUf: '',
        observations: '',
    },
    productInformation: {
        cargoType: '',
        cargoInformation: '',
        description: '',
        gtinCode: '',
        ncm: '',
        loadingCep: '',
        latitudeLoadingCep: '',
        longitudeLoadingCep: '',
        unloadingCep: '',
        latitudeUnloadingCep: '',
        longitudeUnloadingCep: '',
    },
    loadingInformations: {
        municipalities: [],
    },
    routeInformations: {
        route: [],
    },
    unloadingInformations: {
        issuerType: '',
        municipalities: [],
    },
    totalizers: {
        cteTotal: '',
        nfeTotal: '',
        cargoTotalValue: '',
        cargoGrossWeight: '',
        unity: '',
    },
    transportationRoadInformation: {
        rntrc: '',
        portSchedulingCode: '',
        truckBody: '',
        truckType: '',
        vehiclePlate: '',
        vehicleUf: '',
        tara: '',
        isVehicleNotOfMdfeCompany: false,
    },
    drivers: {
        driver: [],
    },
    tows: {
        tow: '',
    },
    transportOperationCodes: {
        transportOperationCode: [],
    },
    hirers: {
        hire: [],
    },
    tollVouchers: {
        vehicleCategory: '',
        tollVoucher: [],
    },
    insuranceInformations: {
        insuranceInformation: [],
    },
    freightPayment: {},
};
