import React from 'react'
import BaseRoutes  from './routes'

import "./Vendor";
import './styles/app.scss'

/* Theme Kernel System App Framework */
import './styles/ks-theme/app.scss'
/* ####### END ########*/

function App() {
    return (<BaseRoutes/>)
}

export default BaseRoutes;