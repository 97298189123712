import React, { useState, useEffect } from 'react'

import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import Filter from '../../components/Filter';
import {checkPermission} from './../../components/security'
import ShowMessages from '../../components/Exceptions'
import api from '../../services/api'
import ModalCheckout from '../modals/modal-checkout';

import { Col } from 'reactstrap';

import setStatistic from '../../services/apiStatistic'

export default function Bill() {
    const [loading, setLoading]               = useState(false)
    const [gridParams, setGridParams]         = useState({})
    const [filters, setFilters]               = useState([])
    const [isOpenFilter, setIsOpenFilter]     = useState(false)
    const [reload, setReload]                 = useState(false)
    const [print, setPrint]                   = useState(false)
    const [excel, setExcel]                   = useState(false)
    const [showButtons, setShowButtons]       = useState(false)
    const [invoices, setInvoices]             = useState(0)
    const [openedInvoceis, setOpenedInvoceis] = useState(0)
    const [opendedValues, setOpendedValues]   = useState(0)
    const [showModal, setShowModal]           = useState(false)

    const handleCloseModal = () => {setShowModal(false)}

    const getBill = async() => {
        await api.get('/invoice-status')
                 .then(response => {
                      setGridParams({idinstallmentreceivable  : {text  : "#Fatura",
                                                                mask  : { name  : 'leftSlice',
                                                                          value : '00000'},
                                                                primaryKey  : true},          
                                     duedate                 : {text        : "Vencimento" },
                                     paydate                 : {text        : "Pagamento",
                                                                searchDefault : true},
                                     total                   : {text        : "Valor",
                                                                className   : 'text-right',
                                                                item        : { className   : 'text-right'}},
                                     comments                : {text        : "Observação"},
                                     reference               : {text        : "Referência",
                                                                className   : "text-center",},
                                     invoicestatus           : {text        : "Status",
                                                                item        : {className : "text-center",
                                                                               switch    : {data  : response.data,
                                                                                            key   : 'idinvoicestatus'}
                                                                                    }},
                                     Options          : {text        : "Opções",
                                                         className   : "text-center",
                                                         order       : false,
                                                         filter      : false,
                                                         item        : [ {type  : "Custom",
                                                                          props : {tag               : "a",
                                                                                   title             : "Nota Fiscal",
                                                                                   className         : 'btn btn-sm btn-warning',
                                                                                   icon              : "fa fa-file-invoice",
                                                                                   href              : 'field:urlnf',
                                                                                   visualConditional : {idinvoicestatus : {operator : 'in',
                                                                                                                           values : [2,4]},
                                                                                                        urlnf           : {operator : '!=',
                                                                                                                           values : null}},
                                                                                   indexcallback     : 0}},
                                                                         {type  : "Custom",
                                                                          props : {tag               : "button",
                                                                                   title             : "Fatura",
                                                                                   className         : 'btn btn-sm btn-secondary',
                                                                                   icon              : "fa fa-file-invoice-dollar",
                                                                                   visualConditional : {idinvoicestatus : {operator : 'not in',
                                                                                                                           values : [5]}},
                                                                                   indexcallback     : 1}},
                                                                         {type  : "Custom",  
                                                                          props : {tag               : "button",
                                                                                   title             : "Efetuar Pagamento",
                                                                                   className         : 'btn btn-sm btn-success',
                                                                                   icon              : "fa fa-credit-card",
                                                                                   visualConditional : {idinvoicestatus : {operator : 'in',
                                                                                                                           values : [0,1,3,6]}},
                                                                                   indexcallback     : 2}}]}                                             
                     })
             })
    }

    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    const invoice = () => {

    }

    const receipt = async(id) => {
        let props = {
            id 
        }
        window.addTab(null, 'Fatura | #' + id.toString().padStart(5, "0"), 'fa fa-receipt', '/receipt',props,0)
    }

    const payment = () => {
        setShowModal(true)
    }

    const getData = async() => {
        await api.get('/bills-sumary')
                 .then(response => {
                    if (response.data === null) { 
                        ShowMessages({message : "Erro ao conectar ao servidor, tente novamente mais tarde!", status : 500}) 
                    } else {
                        setInvoices(response.data.invoices || 0)
                        setOpenedInvoceis(response.data.openedinvoceis || 0)
                        setOpendedValues(response.data.opendedvalues || 0)
                    }
   
                    setLoading(false)
                 })
                 .catch( error => { 
                    setLoading(false)
                    ShowMessages(error)
                })
       
    }
    useEffect(() => {
        getBill()
        setStatistic('maintenance\\bill')
    },[])

    useEffect(() => {
       getData() 
    },[reload])

    return (       
        <ContentWrapper>      
            <Loading loading={loading} />
            <ModalCheckout showModal={showModal} handleCloseModal = {handleCloseModal} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons" style={{marginBottom: "1.25rem"}}>
                        
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,6)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        
                    </div>
                    <div style={{display:"flex",flexWrap:"wrap", width:100+"%"}}>
                    <   Col xl={ 4 } md={ 4 }>
                        { /* START card */ }
                            <div className="card flex-row align-items-center align-items-stretch border-0">
                                <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
                                    <em className="fa fa-file-invoice fa-1x"></em>
                                </div>
                                <div className="col-8 py-1 bg-primary rounded-right">
                                    <div className="h3 mt-0 text-center">{invoices}</div>
                                    <div className="h4 text-uppercase text-center">Faturas</div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={ 4 } md={ 4 }>
                            { /* START card */ }
                            <div className="card flex-row align-items-center align-items-stretch border-0">
                                <div className={`col-4 d-flex align-items-center ${openedInvoceis > 0 ? 'bg-danger-dark' : 'bg-success-dark' } justify-content-center rounded-left`}>
                                    <em className="fa fa-receipt fa-1x"></em>
                                </div>
                                <div className={`col-8 py-1 ${openedInvoceis > 0 ? 'bg-danger' : 'bg-success'} rounded-right`}>
                                    <div className="h3 mt-0 text-center">{openedInvoceis}</div>
                                    <div className="h4 text-uppercase text-center">Abertas</div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={ 4 } md={ 4 }>
                            { /* START card */ }
                            <div className="card flex-row align-items-center align-items-stretch border-0">
                                <div className={`col-4 d-flex align-items-center ${opendedValues > 0 ? 'bg-danger-dark' : 'bg-success-dark'} justify-content-center rounded-left`}>
                                    <em className="fa fa-file-invoice-dollar fa-1x"></em>
                                </div>
                                <div className={`col-8 py-1 ${opendedValues > 0 ? 'bg-danger' : 'bg-success'} rounded-right`}>
                                    <div className="h3 mt-0 text-right">R$ {opendedValues.toLocaleString('pt-br', {minimumFractionDigits: 2})}</div>
                                    <div className="h4 text-uppercase text-right">Valores</div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart box-large-button ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/bills-sumary' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      filename            = "Faturas"
                      callbackButtons     = {[invoice,
                                              receipt,
                                              payment]}
                      />  

               
            </div>                 
        </ContentWrapper>)
}