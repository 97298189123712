import React, { useState, useEffect } from 'react';

import Loading from '../../components/Loading';
import api from '../../services/api';
import ContentWrapper from '../layouts/contentWrapper';
import ShowMessages from '../../components/Exceptions';
import loadingButton from '../layouts/Buttons';
import setStatistic from '../../services/apiStatistic';
import * as yup from 'yup';
import ModalIntegrationEmail from '../modals/modal-integration-email';
import Grid from '../../components/Grid';
import Filter from '../../components/Filter';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

const validateSchema = yup.object().shape({
    port: yup.number().integer().min(1).max(65535).required(),
    imap: yup.string(),
    user: yup.string().email().required(),
    password: yup.string().required(),
    tls: yup.boolean(),
});

const initialValue = {
    user: '',
    idcompany: '',
    idintegrationemail: '',
    idstatusintegrationmail: '',
    iduser: '',
    imap: '',
    port: '',
    password: '',
    nextcheck: '',
    tls: false,
    checkjunk: false,
    interval: 1,
    titles: [],
    title: '',
    boxes: [],
    selectedboxes: {},
};

export default function EmailList() {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(initialValue);
    const [reload, setReload] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [mailData, setMailData] = useState([]);
    const [gridParams, setGridParams] = useState({});

    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [reset, setReset] = useState(false);
    const [print, setPrint] = useState(false);
    const [excel, setExcel] = useState(false);
    const [editState, setEditState] = useState(false);
    const [data, setData] = useState({});
    const [filters, setFilters] = useState([
        {
            field: 'update_at',
            key: 'between',
            value: format(new Date(), 'yyyy-MM-dd'),
            value2: format(new Date(), 'yyyy-MM-dd'),
            ignoretime: true,
        },
    ]);

    const getData = async () => {
        setLoading(true);
        await api
            .get('/integration-email/0')
            .then((response) => {
                setMailData(response.data);
                setLoading(false);
                setGridParams({
                    idintegrationemail: {
                        text: '#ID',
                        mask: { name: 'leftSlice', value: '00000' },
                        primaryKey: true,
                        visible: false,
                    },
                    user: { text: 'Usuário', searchDefault: true },
                    imap: { text: 'Imap' },
                    port: { text: 'Porta' },
                    Options: {
                        text: 'Opções',
                        className: 'text-center',
                        order: false,
                        filter: false,
                        item: [
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Exclusão de email',
                                    className: 'btn btn-sm btn-danger',
                                    icon: 'fa fa-trash',
                                    message: 'Deseja realmente excluir o email?',
                                    indexcallback: 0,
                                },
                            },
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Editar email',
                                    className: 'btn btn-sm btn-warning',
                                    icon: 'fa fa-edit',
                                    indexcallback: 1,
                                },
                            },
                        ],
                    },
                });
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditState(false);
    };

    const handleFilter = () => {
        setIsOpenFilter(!isOpenFilter);
    };

    const removeEmail = async (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: 'Deseja realmente excluir o usuário?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api
                    .delete('/integration-email/' + id)
                    .then((response) => {
                        ShowMessages(response.data);
                        setUserData(initialValue);
                        setReload(true);
                    })
                    .catch((error) => {
                        ShowMessages(error);
                    });
            }
        });
    };

    const editEmail = async (id) => {
        console.log(id)
        await api
            .get('/integration-email/' + id)
            .then((response) => {
                console.log(response.data)
                setUserData(response.data);
                setShowModal(true);
                setEditState(true);
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };

    const handleCreate = () => {
        setUserData(initialValue);
        setShowModal(true);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setStatistic('modals\\modal-integration-email');
    }, []);

    return (
        <ContentWrapper>
            <div className="content-heading" />
            <div className="box-maintance-buttons">
                <div>
                    <button
                        className="btn btn-success mb-3"
                        title="Inserir email"
                        onClick={handleCreate}
                    >
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
                <button
                    className="btn btn-primary mb-3"
                    title="Atualizar Dados"
                    style={{ marginLeft: '3px' }}
                    onClick={(e) => setReload(true)}
                >
                    <i className="fa fa-redo"></i>
                </button>
            </div>
            <div className={`content-box-cart ${loading ? 'hidden' : ''}`}>
                <Filter
                    isOpenFilter={isOpenFilter}
                    filters={gridParams}
                    callback={setFilters}
                    callBackOpenFilter={handleFilter}
                    externalFilters={filters}
                    reset={reset}
                    setReset={setReset}
                />
                <Grid
                    url="/integration-email"
                    headers={gridParams}
                    loading={setLoading}
                    reload={reload}
                    print={print}
                    excel={excel}
                    filters={filters}
                    filename="Mineração de Curriculuns"
                    callbackShowButtons={setShowButtons}
                    callbackExcel={setExcel}
                    callbackPrint={setPrint}
                    callbackReload={setReload}
                    callbackCellClick={editPermission ? undefined : editEmail}
                    callbackButtons={[removeEmail, editEmail]}
                />
            </div>

            <ModalIntegrationEmail
                userData={userData}
                showModal={showModal}
                setUserData={setUserData}
                handleCloseModal={handleCloseModal}
                editState={editState}
                handleReload={setReload}
            />
        </ContentWrapper>
    );
}
