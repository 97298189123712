import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import Switch from '../../Forms/Inputs/switch';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeTransportationRoadInformation = () => {
    const { register, control } = useFormContext();

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="transportationRoadInformation-rntrc">
                        RNTRC (Registro Nacional de Transportadores Rodoviários de Carga)
                    </label>
                    <div className="input-group">
                        <FormControl
                            {...register('transportationRoadInformation.rntrc')}
                            name="transportationRoadInformation.rntrc"
                            id="transportationRoadInformation-rntrc"
                            type="text"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="transportationRoadInformation-portSchedulingCode">
                        Código de agendamento de porto
                    </label>
                    <div className="input-group">
                        <FormControl
                            {...register('transportationRoadInformation.portSchedulingCode')}
                            name="transportationRoadInformation.portSchedulingCode"
                            id="transportationRoadInformation-portSchedulingCode"
                            type="number"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="transportationRoadInformation-truckBody">Tipo de carroceria</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('transportationRoadInformation.truckBody')}
                            id="transportationRoadInformation-truckBody"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="transportationRoadInformation-truckType">Tipo de rodado</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('transportationRoadInformation.truckType')}
                            id="transportationRoadInformation-truckType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="transportationRoadInformation-vehiclePlate">
                        Placa do veículo <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                        {/* <Controller
                            name={`generalInformation.vehiclePlate`}
                            control={control}
                            render={({ field }) => (
                                <AsyncSelect
                                    {...field}
                                    name={`generalInformation.vehiclePlate`}
                                    id="generalInformation-vehiclePlate"
                                    isClearable
                                    defaultOptions
                                    loadOptions={loadOptions}
                                />
                            )}
                        /> */}
                    </div>
                </Col>
            </Row>
            <Row className="mb-2 d-flex align-items-center">
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="transportationRoadInformation-vehicleUf">UF do veículo</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('transportationRoadInformation.vehicleUf')}
                            id="transportationRoadInformation-vehicleUf"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="transportationRoadInformation-tara">
                        Tara <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                        <FormControl
                            {...register('transportationRoadInformation.tara')}
                            name="transportationRoadInformation.tara"
                            id="transportationRoadInformation-tara"
                            type="number"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6}>
                    <Switch
                        name="transportationRoadInformation.isVehicleNotOfMdfeCompany"
                        label="O veículo não percente à empresa emitente do MDF-e"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default memo(MdfeTransportationRoadInformation);
