import { Button, Container } from 'react-bootstrap';
import NfeProducts from '../../components/Invoices/nfe/products';
// import ModalTransport from '../modals/modal'

import { FormProvider, useForm } from 'react-hook-form';

import Data from '../../components/Invoices/nfe/data';
import InvoiceDetails from '../../components/Invoices/nfe/details';
import Payment from '../../components/Invoices/nfe/payment';
import TotalInvoices from '../../components/Invoices/nfe/total-values';
import Transport from '../../components/Invoices/nfe/transport';
import TitleToggle from '../../components/TitleToggle';
import { defaultValuesNfe } from '../../utils/defaultValues';
export default function CreateNFe() {
    const methods = useForm({
        mode: 'all',
        defaultValues: defaultValuesNfe,
    });

    const handleSubmit = (data) => {
        console.log('🚀 ~ file: create-nfe.js ~ line 101 ~ handleSubmit ~ e', data);
    };

    return (
        <div className="p-2 overflow-auto" style={{ maxHeight: '80vh' }}>
            <FormProvider {...methods}>
                <form id="form-nfe" onSubmit={methods.handleSubmit((data) => handleSubmit(data))}>
                    {/* DADOS NOTA FISCAL */}
                    <TitleToggle title="Dados da nota fiscal">
                        <Data />
                        <Container />
                    </TitleToggle>

                    {/* PRODUTOS */}
                    <TitleToggle title="Produtos" marginTop="32px">
                        {/* linha 1 */}
                        <NfeProducts />
                    </TitleToggle>

                    {/* TOTAIS NOTA FISCAL */}
                    <TitleToggle title="Valores totais da nota fiscal" marginTop="32px">
                        <TotalInvoices />
                    </TitleToggle>

                    <TitleToggle title="Pagamento" marginTop="32px">
                        <Payment />
                    </TitleToggle>

                    {/* TRANSPORTE */}
                    <TitleToggle title="Transporte" marginTop="32px">
                        <Transport />
                    </TitleToggle>

                    {/* DETALHES DA NOTA FISCAL */}
                    <TitleToggle title="Detalhes da nota fiscal" marginTop="32px">
                        <InvoiceDetails />
                    </TitleToggle>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '16px',
                            marginTop: '32px',
                        }}
                    >
                        <Button type="submit" id="submit-nfe" className={`btn-theme`}>
                            <em className="fa fa-save" /> Salvar
                        </Button>
                        <Button id="submit-nfe" className="btn-warning">
                            <em className="fa-solid fa-paper-plane" /> Emitir
                        </Button>
                        <Button className="btn-ligth-gray">
                            <em className="fa fa-door-closed" /> Fechar
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
