import { memo } from 'react';
import { Alert, Col, Container, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeUnloadingInformation = () => {
    const { control, register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'unloadingInformations.municipalities', // unique name for your Field Array
    });

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };
    return (
        <Container fluid>
            <Row>
                <Col lg={6} md={6}>
                    <label htmlFor="unloadingInformations-issuerType">
                        <label>Tipo do emitente</label>
                    </label>
                    <div className="input-group">
                        <FormSelect
                            {...register('unloadingInformations.issuerType')}
                            id="unloadingInformations-issuerType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={6} md={6}>
                    <Alert variant="primary">
                        <p>Para o tipo de emitente informado, insira apenas o CT-e</p>
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={10} md={10}>
                            <div className="input-group">
                                <label htmlFor="municipalities-name">
                                    Nome do município de descarregamento <span className="text-danger"> *</span>
                                </label>
                                {/* <Controller
                                    name={`unloadingInformations.municipalities.${index}.name`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`unloadingInformations.municipalities.${index}.name`}
                                            isClearable
                                            defaultOptions
                                            placeholder="Exemplos: São Paulo, Curitiba, Porto Alegre, Rio de Janeiro"
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="adicionar munícipio"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                name: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeUnloadingInformation);
