import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';

import ShowMessages from '../../components/Exceptions'

import api from '../../services/api'
import setStatistic from '../../services/apiStatistic'
import loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function CompanySettigns({showModal, handleCloseModal, data, handleReload}) {
    const [enabledWhatsapp, setEnabledWhatsapp ]      = useState(false)
    const [enabledInventory, setEnabledInventory ]    = useState(false)
    const [requiredMother, setRequiredMother]         = useState(false)
    const [requiredBirthDate, setRequiredBirthDate]   = useState(false)
    const [requiredCPF, setRequiredCPF]               = useState(false)
    const [requiredAddress, setRequiredAddress]       = useState(false)
    const [requiredPhone, setRequiredPhone]           = useState(false)
    const [requiredEmail, setRequiredEmail]           = useState(false)
    const [requiredContractDate, setRequiredContractDate] = useState(false)
    const handleSubmit = async(e) => {
        e.preventDefault()
        loadingButton(true,document.querySelector('#companySettings'),document.querySelector('#companySettings').children[0].classList,document.querySelector('#companySettings').lastChild.nodeValue)
        try {
            await api.post('/company/settings', { enabledWhatsapp,
                                                  enabledInventory,
                                                  requiredMother,
                                                  requiredBirthDate,
                                                  requiredCPF,
                                                  requiredAddress,
                                                  requiredPhone,
                                                  requiredEmail,
                                                  requiredContractDate,
                                                  idcompany : data.idcompany })
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                            if (response.data.status === 200) {
                                handleCloseModal()  
                                handleReload()
                            } else 
                                loadingButton(false,document.querySelector('#companySettings')) 
                         } else 
                            loadingButton(false,document.querySelector('#companySettings')) 
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#companySettings'))
                        ShowMessages(response)              
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#companySettings'))
            ShowMessages(response)          
        }
    }

    const setData = () => {
        setEnabledWhatsapp(data.settings === null ? false : data.settings.enabledWhatsapp )
        setEnabledInventory(data.settings === null ? false : data.settings.enabledInventory )
        setRequiredMother(data.settings === null ? false : data.settings.requiredMother)
        setRequiredBirthDate(data.settings === null ? false : data.settings.requiredBirthDate)
        setRequiredCPF(data.settings === null ? false : data.settings.requiredCPF)
        setRequiredAddress(data.settings === null ? false : data.settings.requiredAddress)
        setRequiredPhone(data.settings === null ? false : data.settings.requiredPhone)
        setRequiredEmail(data.settings === null ? false : data.settings.requiredEmail)
        setRequiredContractDate(data.settings === null ? false : data.settings.requiredContractDate)
    }

    useEffect(() => {
        if (showModal) {
            setData()
            setStatistic('modals\\modal-company-settings')
        }
    } ,[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-cogs"></i> Configurações da Empresa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "Flex", flexDirection:"column" }}>
                        <div style={{display:"flex"}}>
                            <label className="switch">
                                <input type="checkbox" checked={enabledWhatsapp} value={enabledWhatsapp} onClick={e => setEnabledWhatsapp(!enabledWhatsapp)}  />
                                <span></span>
                            </label>
                            <span style={{marginLeft: "10px"}}>Habilitar envio de mensagens do whatsapp?</span>
                        </div>
                        <div style={{display:"flex", marginTop:"15px"}}>
                            <label className="switch">
                                <input type="checkbox" checked={enabledInventory} value={enabledWhatsapp} onClick={e => setEnabledInventory(!enabledInventory)}  />
                                <span></span>
                            </label>
                            <span style={{marginLeft: "10px"}}>Habilitar controle de estoque?</span>
                        </div>
                        <div style={{marginTop:"20px", borderRadius:"5px", padding:"10px", border:"1px solid #cacaca"}}>
                            <div style={{marginTop:"-20px"}}>
                                <span style={{background:"#fff", width:"fit-content"}}>Cadastro de Colaboradores</span>
                            </div>

                            <div style={{display:"flex"}}>
                                <div>
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredMother} value={requiredMother} onClick={e => setRequiredMother(!requiredMother)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório nome da mãe?</span>
                                    </div>
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredBirthDate} value={requiredBirthDate} onClick={e => setRequiredBirthDate(!requiredBirthDate)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório data de nascimento?</span>
                                    </div>
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredCPF} value={requiredCPF} onClick={e => setRequiredCPF(!requiredCPF)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório CPF?</span>
                                    </div>
                                </div>
                                <div style={{marginLeft:"25px"}}>
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredAddress} value={requiredAddress} onClick={e => setRequiredAddress(!requiredAddress)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório Endereço?</span>
                                    </div>   
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredPhone} value={requiredPhone} onClick={e => setRequiredPhone(!requiredPhone)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório Telefone?</span>
                                    </div>  
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredEmail} value={requiredEmail} onClick={e => setRequiredEmail(!requiredEmail)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório Email?</span>
                                    </div>
                                    <div style={{display:"flex", marginTop:"10px"}}>
                                        <label className="switch">
                                            <input type="checkbox" checked={requiredContractDate} value={requiredContractDate} onClick={e => setRequiredContractDate(!requiredContractDate)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "10px"}}>Obrigatório Data da Contratação?</span>
                                    </div>
                                </div>
  
                            </div>
                        </div>
                    </div>    
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="companySettings" className="btn-theme">
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={e => handleCloseModal(false)}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
                </ValidationForm>
            </Modal>
    )
}