import React, { useState, useEffect, useCallback } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { checkPermission } from '../../components/security'
import { format } from 'date-fns'


export default function AttendanceFlux( props) {
    const [loading, setLoading] = useState(false)
    const [data, setData]       = useState([])

    const getData = async() => {
        setLoading(true)
        await api.get('/alertsall/1')
                 .then((response) => { 
                    setData(response.data || [])
                    setLoading(false)

                 }).catch(error => {
                    ShowMessages(error)
                 })  
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\alerts-all')
    }, [])

    return (
        <ContentWrapper>
            <Loading loading={loading} />
               <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <div style={{display:"flex",width:"50%", flexDirection:"column", padding:"5px"}} className="box-shadow">
                    {data.map(_item => {
                        return(
                            <div className={`box-alert ${_item.read_at !== null ? "" : "box-not-visualized"}`}  onClick={e => window.addTab(e, 'Alerta | ' + _item.title, 'fa fa-bell', '/alert',{ id : _item.idalert, setUpdate : getData},1)}>
                                <div>
                                    {_item.idtype === 1 ?
                                        <div className="bg-success icon-alert" style={{ color:'#fff', paddingLeft:'9px', paddingTop:"0px"}}>
                                            <i className='fa fa-thumbs-up'></i>
                                        </div>
                                    : _item.idtype === 0 ?
                                        <div className="bg-warning icon-alert" style={{ color:'#fff', paddingLeft:'18px', paddingTop:"1px"}}>
                                            <i className='fa fa-exclamation'></i>
                                        </div>
                                    :
                                        <div className="bg-danger icon-alert" style={{ color:'#fff', paddingLeft:'7px', paddingTop:"1px"}}>
                                            <i className='fa fa-ban'></i>
                                        </div>
                                    }
                                </div>
                                <div style={{marginLeft:"10px"}}>
                                    <div><span style={{fontWeight:"bold"}}>{_item.title}</span></div>
                                    <div><span> {format(new Date(_item.create_at),'dd/MM/yyyy HH:mm:ss')}</span></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </ContentWrapper>
    )
}