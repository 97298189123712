import { memo } from 'react';
import { Container, Nav, NavItem, NavLink, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import Switch from '../../Forms/Inputs/switch';
import NfeCfop from './NFEParameters/nfe-cfop';
import NfeCofins from './NFEParameters/nfe-cofins';
import NfeIcms from './NFEParameters/nfe-icms';
import NfeIpi from './NFEParameters/nfe-ipi';
import NfePis from './NFEParameters/nfe-pis';
import NfeSt from './NFEParameters/nfe-st';

const NfeParameters = () => {
    return (
        <Container fluid>
            {/* LINHA 1 */}
            <section className="mt-3 pt-3 border-top">
                <div className="mb-3">
                    <Switch
                        name="fiscalTributary.nfeParameters.mustUseNfeParameters"
                        label="Parâmetros na nota fiscal- NF-e- (se esta opção for marcada estes parâmetros sobrepôem os parâmetros gerais)"
                    />
                </div>
                <TabContainer defaultActiveKey={'ICMS'}>
                    <Nav tabs className="nav-tabs" defaultActiveKey="ICMS" defaultValue="ICMS">
                        <NavItem>
                            <NavLink eventKey="ICMS">
                                <span id="ICMS">ICMS</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink eventKey="IPI">
                                <span id="IPI">IPI</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink eventKey="PIS">
                                <span id="personal-data">PIS</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink eventKey="COFINS">
                                <span id="address-phones">{'COFINS'}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink eventKey="CFOP">
                                <span id="address-phones">CFOP</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink eventKey="ST">
                                <span id="professional">ST</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent defaultValue="ICMS" defaultChecked="ICMS">
                        <TabPane eventKey="ICMS">
                            <NfeIcms />
                        </TabPane>
                        <TabPane eventKey="IPI">
                            <NfeIpi />
                        </TabPane>
                        <TabPane eventKey="PIS">
                            <NfePis />
                        </TabPane>

                        <TabPane eventKey="COFINS">
                            <NfeCofins />
                        </TabPane>

                        <TabPane eventKey="CFOP">
                            <NfeCfop />
                        </TabPane>

                        <TabPane eventKey="ST">
                            <NfeSt />
                        </TabPane>
                    </TabContent>
                </TabContainer>
            </section>
        </Container>
    );
};

export default memo(NfeParameters);
