import '../../styles/app/common/pdv.scss';
import { OrderTable } from '../../components/PDV/OrderTable';
import { Product } from '../../components/PDV/PDVProduct';

function PDV() {
  return (
    <main className="pdv-page">
      <OrderTable />
      <Product />
    </main>
  );
}

export default PDV;
