import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeProductInformation = () => {
    const { register, control } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={5} md={5}>
                    <label htmlFor="generalInformation-cargoType">
                        Tipo da carga <span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                        <FormSelect
                            {...register('generalInformation.cargoType')}
                            id="generalInformation-cargoType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={7} md={7} sm={12}>
                    <label htmlFor="generalInformation-cargoInformation">Informações da carga lotação</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.cargoInformation')}
                            name="generalInformation.cargoInformation"
                            placeholder="Informe a carga que representa o maior valor"
                            id="generalInformation-cargoInformation"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={8} md={8} sm={12}>
                    <label htmlFor="generalInformation-description">
                        Descrição do produto <span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.description')}
                            id="generalInformation-description"
                            placeholder="Descrição do produto predominante"
                            type="text"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-gtinCode">Código GTIN/EAN</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.gtinCode')}
                            id="generalInformation-gtinCode"
                            type="text"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-ncm">NCM</label>
                    <div className="input-group">
                        <FormControl {...register('generalInformation.ncm')} id="generalInformation-ncm" type="text" />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-UnloadingCep">CEP descarregamento</label>
                    <div className="input-group">
                        <Controller
                            name="generalInformation.UnloadingCep"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="generalInformation.UnloadingCep"
                                    id="generalInformation.UnloadingCep"
                                    mask="cep"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                        <span style={{ fontWeight: 'bold' }}>
                            Obrigatório informar o CEP ou a Latitude e Longitude!*
                        </span>
                    </div>
                </Col>

                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-latitudeUnloadingCep">Latitude</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.latitudeUnloadingCep')}
                            id="generalInformation-latitudeUnloadingCep"
                            placeholder="Latitude do carregamento"
                            type="text"
                        />
                    </div>
                </Col>

                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-longitudeUnloadingCep">Longitude</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.longitudeUnloadingCep')}
                            id="generalInformation-longitudeUnloadingCep"
                            placeholder="Longitude do carregamento"
                            type="text"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-loadingCep">CEP descarregamento</label>
                    <div className="input-group">
                        <Controller
                            name="generalInformation.loadingCep"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="generalInformation.loadingCep"
                                    id="generalInformation.loadingCep"
                                    mask="cep"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                        <span style={{ fontWeight: 'bold' }}>
                            Obrigatório informar o CEP ou a Latitude e Longitude!*
                        </span>
                    </div>
                </Col>

                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-latitudeLoadingCep">Latitude</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.latitudeLoadingCep')}
                            id="generalInformation-latitudeLoadingCep"
                            placeholder="Latitude do descarregamento"
                            type="text"
                        />
                    </div>
                </Col>

                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="generalInformation-longitudeLoadingCep">Longitude</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.longitudeLoadingCep')}
                            id="generalInformation-longitudeLoadingCep"
                            placeholder="Longitude do descarregamento"
                            type="text"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(MdfeProductInformation);
