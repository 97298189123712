import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';
import Switch from '../../Forms/Inputs/switch';

const serviceType = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const Details = () => {
    const { register, control } = useFormContext();
    return (
        <Container fluid>
            <Row className="my-2">
                <Col lg={6} md={6} className="d-flex justify-content-center">
                    <Switch name="details.informEmissionDate" label="Informar data de emissão" />
                </Col>

                <Col lg={6} md={6} className="d-flex justify-content-center">
                    <Switch name="details.informIETaxSubstitute" label="Informar IE do substituto tributário" />
                </Col>
            </Row>

            <Row className="my-2">
                <Col lg={2} md={2} sm={12}>
                    <label>Data de saída</label>
                    <div className="input-group">
                        <FormControl {...register('details.exitDate')} id="details-exitDate" type="date" />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>Hora</label>
                    <div className="input-group">
                        <FormControl
                            {...register('details.exitHour')}
                            id="details-exitHour"
                            type="time"
                            placeholder="hora"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>Ordem de compra</label>
                    <div className="input-group">
                        <FormControl {...register('details.purchaseOrder')} id="details-purchaseOrder" type="text" />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label>Tipo de atendimento</label>
                    <div className="input-group">
                        <FormSelect {...register('details.serviceType')} id="details-serviceType" className="w-100">
                            <option value="">Selecione</option>

                            {serviceType.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label>Destino da operação</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('details.operationDestination')}
                            id="details-operationDestination"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {serviceType.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
            </Row>

            <Row className="my-2">
                <Col lg={4} md={4} sm={12}>
                    <label>Indicador de intermediador/marketplace</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('details.intermediaryIndicator')}
                            id="details-intermediaryIndicator"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {serviceType.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label>CNPJ do intermediador da transação</label>
                    <div className="input-group">
                        <Controller
                            name="details.intermediaryCnpj"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="details.intermediaryCnpj"
                                    id="details-intermediaryCnpj"
                                    mask="cnpj"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label>Identificador cadastro intermediador</label>
                    <div className="input-group">
                        <FormControl
                            {...register('details.intermediaryIndentifier')}
                            name="details.intermediaryIndentifier"
                            id="details-intermediaryIndentifier"
                            type="text"
                        />
                    </div>
                </Col>
            </Row>

            <Row className="my-2">
                <Col lg={6} md={6} sm={12}>
                    <label>Observações</label>
                    <div className="input-group">
                        <FormControl
                            {...register('details.observations')}
                            name="details.observations"
                            id="details-observations"
                            as="textarea"
                            rows={8}
                            placeholder="Está informação será impressa nas observações da nota"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6}>
                    <label>Observações internas</label>
                    <div className="input-group">
                        <FormControl
                            {...register('details.internalObservations')}
                            name="details.internalObservations"
                            id="details-internalObservations"
                            as="textarea"
                            rows={8}
                            placeholder="Está informação é de uso interno, portanto não será impressa na nota."
                        />
                    </div>
                </Col>
            </Row>

            <Row className="my-2">
                <div>
                    <button className="btn btn-success">Mensagens padrões</button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(Details);
