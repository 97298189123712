import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import MaskInput from '../../Forms/Inputs/maskedInput';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeInsuranceInformation = () => {
    const { control, register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'insuranceInformations.insuranceInformation', // unique name for your Field Array
    });

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };
    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <div key={field.id} className="d-flex align-items-end mb-2">
                        <div className="badge text-bg-primary align-self-end" style={{ width: '30px' }}>
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </div>

                        <Row className="d-flex align-items-center mx-1">
                            <Col lg={2} md={4}>
                                <div className="input-group">
                                    <label htmlFor="insuranceInformation-responsible">Responsável</label>
                                    {/* <Controller
                                        name={`insuranceInformations.insuranceInformation.${index}.responsible`}
                                        control={control}
                                        // rules={{ required: false }}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                {...field}
                                                name={`insuranceInformations.insuranceInformation.${index}.responsible`}
                                                id="insuranceInformation-responsible"
                                                placeholder="selecione"
                                                defaultOptions
                                                loadOptions={loadOptions}
                                            />
                                        )}
                                    /> */}
                                </div>
                            </Col>
                            <Col lg={2} md={4}>
                                <label htmlFor="insuranceInformation-responsibleCpfCnpj">CPF/CNPJ do resp.</label>
                                <div className="input-group">
                                    <Controller
                                        name={`insuranceInformations.insuranceInformation.${index}.responsibleCpfCnpj`}
                                        control={control}
                                        render={({ field }) => (
                                            <MaskInput
                                                name={`insuranceInformations.insuranceInformation.${index}.responsibleCpfCnpj`}
                                                id="insuranceInformation-responsibleCpfCnpj"
                                                mask="cpf/cnpj"
                                                field={field}
                                                className={`text-right`}
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={12}>
                                <label htmlFor="insuranceInformation-insurer">Seguradora</label>
                                <div className="input-group">
                                    <FormControl
                                        {...register(`insuranceInformations.insuranceInformation.${index}.insurer`)}
                                        name={`insuranceInformations.insuranceInformation.${index}.insurer`}
                                        id="insuranceInformation-insurer"
                                        type="number"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4}>
                                <label htmlFor="insuranceInformation-insurerCnpj">CNPJ da seguradora.</label>
                                <div className="input-group">
                                    <Controller
                                        name={`insuranceInformations.insuranceInformation.${index}.insurerCnpj`}
                                        control={control}
                                        render={({ field }) => (
                                            <MaskInput
                                                name={`insuranceInformations.insuranceInformation.${index}.insurerCnpj`}
                                                id="insuranceInformation-insurerCnpj"
                                                mask="cnpj"
                                                field={field}
                                                className={`text-right`}
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={12}>
                                <label htmlFor="insuranceInformation-insurancePolicyNumber">Núm. do Apólice</label>
                                <div className="input-group">
                                    <FormSelect
                                        {...register(
                                            `insuranceInformations.insuranceInformation.${index}.insurancePolicyNumber`,
                                        )}
                                        id="insuranceInformation-insurancePolicyNumber"
                                        className="w-100"
                                    >
                                        <option value="">Selecione</option>

                                        {genericArray.map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </FormSelect>
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={12}>
                                <label htmlFor="insuranceInformation-registeringNumber">Núm. da Averbação</label>
                                <div className="input-group">
                                    <FormSelect
                                        {...register(
                                            `insuranceInformations.insuranceInformation.${index}.registeringNumber`,
                                        )}
                                        id="insuranceInformation-registeringNumber"
                                        className="w-100"
                                    >
                                        <option value="">Selecione</option>

                                        {genericArray.map((item) => {
                                            return (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </FormSelect>
                                </div>
                            </Col>
                        </Row>

                        <div className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </div>
                    </div>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="Outro código da operação de transporte"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                hirer: '',
                                cpfCnpj: '',
                                indentifier: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(MdfeInsuranceInformation);
