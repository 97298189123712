import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import ModalIintegrationEmail from '../modals/modal-integration-email'
import ModalMiner from '../modals/modal-miner'
import  loadingButton  from '../layouts/Buttons'

import Swal from 'sweetalert2'

export default function CRS() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [loadingProcess, setLoadingProcess]              = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
    const [editPermission, setEditPermisson]               = useState(false)
    const [dataStatusCRS, setDataStatusCRS]                = useState([])
    const [statusCRS, setStatusCRS]                        = useState(-1)
    const [rule, setRule]                                  = useState([])

    const handleCloseModal = () => {setShowModal(false)}

    const getCRS= async() => {
        await api.get('/statuscrs/0')
                 .then(response => {
                    setDataStatusCRS(response.data)
                    setGridParams({
                                     idcrs  : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                  value : '00000'},
                                                     primaryKey  : true,
                                                     visible : false                         },
                                     name   : {text        : "Nome"},
                                     opportunity : {text   : "Vaga"},
                                     phone1 : {text   : "Telefone 01"},
                                     phone2 : {text   : "Telefone 02"},
                                     phone3 : {text   : "Telefone 03"},
                                     email  : {text   : "Email 01"},
                                     email1 : {text   : "Email 02"},
                                     statuscrs : {text: "Status",
                                                  item: {
                                                     className: "text-center",
                                                     switch: {
                                                         data: response.data,
                                                         key: 'idstatuscrs'
                                                     }
                                                  }},
                                     Options : {text        : "Opções",
                                                className   : "text-center",
                                                order       : false,
                                                filter      : false,
                                                item        : [{type  : "Custom",
                                                                props : {tag               : "button",
                                                                         title             : "Exclusão de cliente",
                                                                         className         : 'btn btn-sm btn-danger',
                                                                         icon              : "fa fa-trash",
                                                                         message           : "Deseja realmente excluir o cliente?",
                                                                         indexcallback     : 0}},
                                                            //   {type  : "Custom",
                                                            //    props : {tag               : "button",
                                                            //             title             : "Editar cliente",
                                                            //             className         : 'btn btn-sm btn-warning',
                                                            //             icon              : "fa fa-edit",
                                                            //             indexcallback     : 1}}
                                                                    
                                                                    ]}
                                    })              
                  }).catch(error => {
                      ShowMessages(error)
                  })
    }

    useEffect(() => {
        getCRS()
        handleUtilization()
        setStatistic('maintenance\\crs')
    },[])
    
    useEffect(() => {
        if (statusCRS >= 0)
            setFilters([{
                field      : 'idstatuscrs',
                key        : '=',  
                value      : statusCRS,
                value2     : null,
                ignoretime : true    
            }])
        else
            setFilters([])
    },[statusCRS])
    

    const handleFilter          = () => {setIsOpenFilter(!isOpenFilter)}

    const RemoveCRS   = async (id) => {      
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o contato?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/crs/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                        })
                        .catch( error => {
                            ShowMessages(error)
                        })
            }
            
        }) 
    }

    const handleProcess   = async (id) => {      
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente processar os contatos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                setLoadingProcess(true)
                await api.post('/crs/process')
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                            setLoadingProcess(false)
                        })
                        .catch( error => {
                            ShowMessages(error)
                        })
            }
            
        }) 
    }

    const EditCRS = async(id) => {
        await api.get('/crs/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const importFile = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#btn-import'),document.querySelector('#btn-import').children[0].classList,document.querySelector('#btn-import').lastChild.nodeValue)
        try {

            const formData = new FormData();

            formData.append('file', event.target.files[0]);

            await api.post('/importFile', formData, {headers: { "Content-Type": "multipart/form-data"}})
                      .then(response => {
                        document.querySelector('#fileupload').value = ''
                        event.target.files =undefined
                         ShowMessages(response)
                         if (response.ok) {   
                             if (response.data.status === 200) {
                             }
                        }
                        loadingButton(false,document.querySelector('#btn-import'),'fa fa-file-excel','Importar')        
                       })
                       .catch( error => {  
                        console.log(error)    
                        document.querySelector('#fileupload').value = ''
                        event.target.files =undefined
                         loadingButton(false,document.querySelector('#btn-import'),'fa fa-file-excel','Importar')    
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#btn-import'),'fa fa-file-excel','Importar')    
            ShowMessages(response)
        }
    }
    
    const handleUtilization = async() => {
        await api.get('/crs/utilization')
                 .then(response => {
                    setRule(response.data)
                    console.log(response.data)
                  })
                  .catch( error => {
                      ShowMessages(error)
                   })
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalIintegrationEmail showModal={showModal} handleCloseModal = {handleCloseModal} data={data}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div  className="box-maintance-buttons">
                        <div>    
                            <button id="btn-import"className="btn btn-success" title="Importar CRS" style={{marginLeft:"3px"}} onClick={e => document.querySelector('#fileupload').click()}><i className="fa fa-file-excel"></i></button>  
                            <input type="file" accept=".xlsx"  onChange={e => importFile(e)} className="hidden" id="fileupload" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>         
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons && (
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,23)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            )}
                            <button className="btn btn-theme" title="Processar CRS" disabled={loadingProcess} onClick={e => handleProcess()} style={{marginLeft:"3px"}}><i className={`fa ${loadingProcess ? 'fa-spin fa-spinner' : 'fa-arrows-rotate'}`}></i></button>
                        </div>
                    </div>
                    <div style={{display:"flex", borderLeft:"1px #cacaca dashed", paddingLeft:"15px",gap:"15px"}}>
                        <div style={{width:"250px"}}>
                            <label>Exibir somente com status de...</label>
                            <select className='form-select' value={statusCRS} onChange={e => setStatusCRS(parseInt(e.target.value))}>
                                <option value="-1">Todos</option>
                                {dataStatusCRS.map(_item => {
                                    return <option value={_item.idstatuscrs}>{_item.statuscrs}</option>  
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="box-shadow" style={{ width:"100px"}}>
                        <div title="Nomes Iguais">
                        <div style={{textAlign:"center"}}>
                                <span>
                                    Regra 01
                                </span>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", padding:"5px 0px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>{rule.rule1}</span>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <small style={{fontSize:"10px"}}>Nomes Iguais</small>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{ width:"100px"}}>
                        <div title="Emails Iguais">
                        <div style={{textAlign:"center"}}>
                                <span>
                                    Regra 02
                                </span>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", padding:"5px 0px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>{rule.rule2}</span>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <small style={{fontSize:"10px"}}>Emails Iguais</small>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{ width:"100px"}}>
                        <div title="Telefones Iguais">
                            <div style={{textAlign:"center"}}>
                                <span>
                                    Regra 03
                                </span>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", padding:"5px 0px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>{rule.rule3}</span>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <small style={{fontSize:"10px"}}>Telefones Iguais</small>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{ width:"100px"}}>
                        <div title="Nomes Similares">
                        <div style={{textAlign:"center"}}>
                                <span>
                                    Regra 04
                                </span>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", padding:"5px 0px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>{rule.rule4}</span>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <small style={{fontSize:"10px"}}>Nome Similares</small>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{borderRadius:"5px", width:"100px"}}>
                        <div title="Emails Similares">
                            <div style={{textAlign:"center"}}>
                                <span>
                                    Regra 05
                                </span>
                            </div>
    
                            <div style={{display:"flex", justifyContent:"center", padding:"5px 0px"}}>
                                <span style={{fontSize:"16px", fontWeight:"bold"}}>{rule.rule5}</span>
                            </div>
                            <div style={{textAlign:"center"}}>
                                <small style={{fontSize:"10px"}}>Emails Similares</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/crs' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      callbackCellClick   = {editPermission ? undefined : EditCRS}
                      filename            = "CRS"
                      callbackButtons     = {[RemoveCRS, 
                                              EditCRS]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}