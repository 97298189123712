import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, FormControl } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Filter from '../../components/Filter';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import { checkPermission } from '../../components/security';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import ContentWrapper from '../layouts/contentWrapper';
import ModalEmployeer from '../modals/modal-employeer';
import Swal from 'sweetalert2';

export default function FiscalDocument() {
    const [statusData, setStatusData] = useState([
        { value: 'digitada', label: 'Digitada' },
        { value: 'emitida', label: 'Emitida' },
        { value: 'cancelada', label: 'Cancelada' },
    ]);
    const [status, setStatus] = useState('');

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [reload, setReload] = useState(false);
    const [print, setPrint] = useState(false);
    const [excel, setExcel] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [editState, setEditState] = useState(false);
    const [gridParams, setGridParams] = useState({});
    const [data, setData] = useState({});
    const [filters, setFilters] = useState([]);

    const [editPermission, setEditPermisson] = useState(false);

    const getPeopleStatus = async () => {
        await api
            .get('/peoplestatus/0')
            .then((response) => {
                let _delCheck = !checkPermission(4, 4);
                let _editCheck = !checkPermission(8, 4);
                setEditPermisson(_editCheck);
                setGridParams({
                    idpeople: {
                        text: '#ID',
                        mask: {
                            name: 'leftSlice',
                            value: '00000',
                        },
                        primaryKey: true,
                    },
                    number: {
                        text: 'Número',
                        dataType: 'text',
                    },
                    emissionDate: {
                        text: 'Data de emissão',
                        dataType: 'date',
                    },
                    remetente: {
                        text: 'Remetente',
                        dataType: 'text',
                    },
                    destinatario: {
                        text: 'Destinatário',
                        dataType: 'text',
                    },
                    status: {
                        text: 'Status',
                        dataType: 'text',
                    },
                    Options: {
                        text: 'Opções',
                        className: 'text-center',
                        order: false,
                        filter: false,
                        item: [
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Exclusão de documento',
                                    className: 'btn btn-sm btn-danger',
                                    icon: 'fa fa-trash',
                                    disabled: _delCheck,
                                    message: 'Deseja realmente excluir este documento?',
                                    indexcallback: 0,
                                },
                            },
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Editar documento',
                                    disabled: _editCheck,
                                    className: 'btn btn-sm btn-warning',
                                    visualConditional: {
                                        idpeoplestatus: {
                                            operator: 'not in',
                                            values: [3, 5],
                                        },
                                    },
                                    icon: 'fa fa-edit',
                                    indexcallback: 1,
                                },
                            },
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Download XML',
                                    className: 'btn btn-sm btn-warning',
                                    icon: 'fa fa-download',
                                    disabled: _delCheck,
                                    indexcallback: 2,
                                },
                            },
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Enviar por Whatsapp',
                                    className: 'btn btn-sm btn-warning',
                                    icon: 'fa-brands fa-whatsapp-square',
                                    disabled: _delCheck,
                                    indexcallback: 3,
                                },
                            },
                            {
                                type: 'Custom',
                                props: {
                                    tag: 'button',
                                    title: 'Enviar por Email',
                                    className: 'btn btn-sm btn-warning',
                                    icon: 'fa-solid fa-envelope-circle-check',
                                    disabled: _delCheck,
                                    indexcallback: 4,
                                },
                            },
                        ],
                    },
                });
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };

    useEffect(() => {
        getPeopleStatus();
        setStatistic('maintenance\\fiscal-documents');
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleFilter = () => {
        setIsOpenFilter(!isOpenFilter);
    };

    const RemoveDocument = async (isConfirm, swal, id) => {
        if (isConfirm) {
            await api
                .delete('/employee/' + id)
                .then((response) => {
                    ShowMessages(response.data);
                    setReload(true);
                })
                .catch((error) => {
                    ShowMessages(error);
                });
        }
    };

    console.log(data, 'data');
    const EditDocument = async (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: 'Deseja realmente excluir o documento fiscal?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api
                    .delete('/employee/' + id)
                    .then((response) => {
                        ShowMessages(response.data);
                        setReload(true);
                    })
                    .catch((error) => {
                        ShowMessages(error);
                    });
            }
        });
    };
    const DownloadXMLDocument = async (id) => {
        await api
            .get('/employee/' + id)
            .then((response) => {
                setData(response.data);
                setEditState(true);
                setShowModal(true);
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };
    const SendToWhatsapp = async (id) => {
        await api
            .get('/employee/' + id)
            .then((response) => {
                setData(response.data);
                setEditState(true);
                setShowModal(true);
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };
    const SendToEmail = async (id) => {
        await api
            .get('/employee/' + id)
            .then((response) => {
                setData(response.data);
                setEditState(true);
                setShowModal(true);
            })
            .catch((error) => {
                ShowMessages(error);
            });
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href="/#"
            ref={ref}
            className="mnp-name"
            onClick={(e) => {
                e.preventDefault();
            }}
        >
            {children}
        </a>
    ));

    return (
        <ContentWrapper>
            <Loading loading={loading} />
            <ModalEmployeer
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                editState={editState}
                data={data}
                handleReload={setReload}
            />
            <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <DropdownButton
                                variant={'success'}
                                as={CustomToggle}
                                title={<i className="fa fa-plus"></i>}
                            >
                                <Dropdown.Item
                                    href="#"
                                    onClick={(e) =>
                                        window.addTab(
                                            e,
                                            'Criar NF-e',
                                            'fa fa-user-cog',
                                            '/create-nfe',
                                            {},
                                            0,
                                        )
                                    }
                                >
                                    NF-e
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={(e) =>
                                        window.addTab(
                                            e,
                                            'Criar NFS-e',
                                            'fa fa-user-cog',
                                            '/create-nfse',
                                            {},
                                            0,
                                        )
                                    }
                                >
                                    NFS-e
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={(e) =>
                                        window.addTab(
                                            e,
                                            'Criar CT-e',
                                            'fa fa-user-cog',
                                            '/create-cte',
                                            {},
                                            0,
                                        )
                                    }
                                >
                                    CT-e
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={(e) =>
                                        window.addTab(
                                            e,
                                            'Criar MDF-e',
                                            'fa fa-user-cog',
                                            '/create-mdfe',
                                            {},
                                            0,
                                        )
                                    }
                                >
                                    MDF-e
                                </Dropdown.Item>
                            </DropdownButton>
                            <button
                                className="btn btn-primary"
                                title="Atualizar Dados"
                                style={{ marginLeft: '3px' }}
                                onClick={(e) => setReload(true)}
                            >
                                <i className="fa fa-redo"></i>
                            </button>
                            {showButtons ? (
                                <>
                                    <button
                                        className="btn btn-dark"
                                        title="Impressão"
                                        onClick={(e) => setPrint(true)}
                                        style={{ marginLeft: '3px' }}
                                    >
                                        <i className="fa fa-print"></i>
                                    </button>
                                    <button
                                        className="btn btn-info"
                                        disabled={!checkPermission(16, 4)}
                                        title="Exportar para excel"
                                        onClick={(e) => setExcel(true)}
                                        style={{ marginLeft: '3px' }}
                                    >
                                        <i className="fa fa-file-excel"> </i>
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        title="Filtros Avançados"
                                        style={{ marginLeft: '3px' }}
                                        onClick={(e) => handleFilter()}
                                    >
                                        <i className="fa fa-search-plus"></i>
                                    </button>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="px-2">
                        <label className="mb-1">Data inicial do agendamento</label>
                        <div className="input-group">
                            <FormControl type={'date'} />
                        </div>
                    </div>
                    <div className="px-2">
                        <label className="mb-1">Data final do agendamento</label>
                        <div className="input-group">
                            <FormControl type={'date'} />
                        </div>
                    </div>
                    <div className="px-2">
                        <button className="btn btn-success">
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                    <div className="px-2">
                        <label className="mb-1">Data final do agendamento</label>
                        <select
                            name="company"
                            id="company"
                            placeholder="Selecione"
                            value={status}
                            className="form-select"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            {statusData?.map((data, id) => {
                                return (
                                    <option key={id} value={data.value}>
                                        {data.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="px-2">
                        <button className="btn btn-success">
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${loading ? 'hidden' : ''}`}>
                <Filter
                    isOpenFilter={isOpenFilter}
                    filters={gridParams}
                    callback={setFilters}
                    callBackOpenFilter={handleFilter}
                />
                <Grid
                    url="/employee"
                    headers={gridParams}
                    loading={setLoading}
                    reload={reload}
                    print={print}
                    excel={excel}
                    filters={filters}
                    callbackShowButtons={setShowButtons}
                    callbackPrint={setPrint}
                    callbackReload={setReload}
                    callbackExcel={setExcel}
                    filename="Colaborador"
                    callbackCellClick={editPermission ? undefined : EditDocument}
                    callbackButtons={[
                        RemoveDocument,
                        EditDocument,
                        DownloadXMLDocument,
                        SendToWhatsapp,
                        SendToEmail,
                    ]}
                />
            </div>
        </ContentWrapper>
    );
}
