import React, { memo, useCallback, useState, useEffect } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

const CarouselImg = () => {
  const [index, setIndex] = useState(-1);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [imgs, setImgs] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    multiple: true,
    // maxSize: 5 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      setIndex(imgs.length);
      acceptedFiles.map((item) => {
        return setImgs([
          ...imgs,
          { src: URL.createObjectURL(item), altText: item.type },
        ]);
      });
    },
  });

  console.log(imgs, 'imgs');

  const handleSelect = (index) => {
    setIndex(index);
  };

  const downloadImg = (e) => {
    fetch(e, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.png'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteImg = useCallback(
    (id) => {
      let imgsArr = imgs;

      let newArr = imgsArr.filter((item, index) => {
        return index !== id;
      });
      setImgs([...newArr]);

      if (index === imgs.length - 1) {
        setIndex(index - 1);
      }
    },
    [imgs, index],
  );

  useEffect(() => {
    return () => imgs?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [imgs]);

  return (
    <>
      {showFullscreen && (
        <div className="fs-container">
          <div className="fs-header">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => setShowFullscreen((s) => (s = false))}
              title="deszoom"
            >
              <em className="fa fa-compress"></em>
            </button>
          </div>
          <div className="fs-img-container">
            <img src={imgs[index].src} alt={imgs[index].alt} />
          </div>
        </div>
      )}
      <Container
        fluid
        className="d-flex align-items-center justify-content-center flex-column border border-success"
        style={{
          minHeight: !!imgs.length ? '80px' : '280px',
        }}
        {...getRootProps({})}
      >
        <div className="dropzone">
          <input {...getInputProps()} />
          <p>Arraste e solte imagens aqui</p>
        </div>
      </Container>
      <div className="carousel-section relative">
        {imgs.length > 0 ? (
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            pause
            interval={null}
            nextIcon={
              <FontAwesomeIcon icon={faChevronRight} className="text-success" />
            }
            prevIcon={
              <FontAwesomeIcon icon={faChevronLeft} className="text-success" />
            }
          >
            {imgs.map((item, index) => {
              return (
                <Carousel.Item key={`${item}:${index}`}>
                  <img
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '300px',
                    }}
                    src={item.src}
                    alt={item.altText}
                    height={250}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        ) : (
          <></>
        )}
      </div>
      <div>
        <button
          type="button"
          className="btn btn-success mx-2"
          onClick={() => setShowFullscreen((s) => (s = true))}
          title="Zoom"
          disabled={imgs.length > 0 ? false : true}
        >
          <em className="fa fa-expand"></em>
        </button>
        <button
          type="button"
          className="btn btn-success  mx-2"
          title="Baixar imagem"
          onClick={() => downloadImg(imgs[index].src)}
          disabled={imgs.length > 0 ? false : true}
        >
          <em className="fa fa-download"></em>
        </button>
        <button
          type="button"
          className="btn btn-success  mx-2"
          onClick={() => deleteImg(index)}
          title="Excluir"
          disabled={imgs.length > 0 ? false : true}
        >
          <em className="fa fa-trash"></em>
        </button>
      </div>
    </>
  );
};

export default memo(CarouselImg);
