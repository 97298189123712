import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput,SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import CurrencyInput from '../../components/moneyInput';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalBillsToPayEdit({showModal, handleCloseModal, handleReload, data}) {
    const [loading, setLoading]                       = useState(false)

    const [people,setPeople]                          = useState('')
    const [amount,setAmount]                          = useState(0.00)
    const [paymentcondition,setPaymentCondition]      = useState('')
    const [idcostcenter,setIdCostCenter]              = useState('')
    const [idchartofaccount,setIdCharTofAccount]      = useState('')
    const [idkindofpayment,setIdKindOfPayment]        = useState('')
    const [comments ,setComments]                     = useState('')
    const [invoice ,setInvoice]                       = useState('')

    const [dataChartOfAccount, setDataChartOfAccount]     = useState([])
    const [dataCostCenter, setDataCostCenter]             = useState([])
    const [dataKindOfPayment, setDataKindOfPayment]       = useState([])

    const setData = (obj)=> {
        if (JSON.stringify(obj) === '{}')
            return
        setLoading(true)
        setPeople(obj.name)
        setAmount(obj.amount.toFixed(2).toString().replace('.',','))
        setPaymentCondition(obj.paymentcondition)
        setIdCostCenter(obj.idcostcenter)
        setIdCharTofAccount(obj.idchartofaccount)
        setIdKindOfPayment(obj.idkindofpayment)
        setComments(obj.comments)
        setInvoice(obj.document)
        setLoading(false)
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/getBillsToPayData')
                 .then((response) => { 
                    setDataChartOfAccount(response.data.chartOfAccount)
                    setDataCostCenter(response.data.costcenter)
                    setDataKindOfPayment(response.data.kindofpayment)
                    setLoading(false)
                 }).catch(error => {
                    ShowMessages(error)
                 })  
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-billstopay'),document.querySelector('#submit-billstopay').children[0].classList,document.querySelector('#submit-billstopay').lastChild.nodeValue)
        try {
            let _data = {amount,
                         idcostcenter,
                         idchartofaccount,
                         idkindofpayment,
                         comments }

            await api.put('/bills-to-pay/' + data.idinstallmentpayable, _data)
                     .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-billstopay'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-billstopay'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-billstopay'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-billstopay'))
            ShowMessages(response)
        }
    }
       
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-billstopay')
    } ,[])

    useEffect(() => {
        setData(data)
    } ,[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-money-bill-wave"></i> Propriedades de Contas a Pagar
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Fornecedor</label>
                            <div className="input-group">
                                <TextInput 
                                    name="people" 
                                    id="people" 
                                    disabled
                                    className="form-control "
                                    value={people} 
                                    onChange={e => setPeople(e.target.value)}/> 
                            </div>
                        </div>  
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%"}}>
                                <label>Centro de Custo</label>
                                <div>
                                    <SelectGroup 
                                        name="costcenter" 
                                        id="costcenter" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe um centro de custo!" }}
                                        className="form-select"
                                        value={idcostcenter} 
                                        onChange={e => setIdCostCenter(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {dataCostCenter.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div>   
                            <div style={{marginLeft:"15px",width:"50%"}}>
                                <label>Plano de Contas</label>
                                <div>
                                    <SelectGroup 
                                        name="idchartofaccount" 
                                        id="idchartofaccount" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe um plano de contas!" }}
                                        className="form-select"
                                        value={idchartofaccount} 
                                        onChange={e => setIdCharTofAccount(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {dataChartOfAccount.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div>                               
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%"}}>
                                <label>Condições de Pagamento</label>
                                <div className="input-group">
                                    <TextInput 
                                        name="idpaymentcondition" 
                                        id="idpaymentcondition" 
                                        placeholder="Selecione" 
                                        disabled
                                        className="form-control "
                                        value={paymentcondition} 
                                        onChange={e => {setPaymentCondition(e.target.value)}}/> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",width:"30%"}}>
                                <label>Espécie de Pagamento</label>
                                <div>
                                    <SelectGroup 
                                        name="idKindOfPayment" 
                                        id="idKindOfPayment" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe uma espécie de pagamento!" }}
                                        className="form-select"
                                        value={idkindofpayment} 
                                        onChange={e => {setIdKindOfPayment(parseInt(e.target.value))}}>
                                            <option value="">Selecione</option>
                                            {dataKindOfPayment.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px", width:"20%"}}>
                                <label>Nº Documento</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="invoice"
                                        id="invoice"
                                        type="text"
                                        disabled
                                        maxLength={25}
                                        value={invoice}
                                        className="form-control "
                                        onChange={e => setInvoice(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{width:"30%"}}>
                                <label>Valor Parcela</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="saleprice"
                                        id="saleprice"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={amount}
                                        className="form-control text-right"
                                        onChange={e => {setAmount(e.target.value)}}
                                        autoComplete="off"/>
                                </div>
                            </div>

                        </div>
                        <div>
                            <label>Observações</label>
                            <div className="input-group">
                                <TextInput  
                                    name="comments"
                                    id="comments"
                                    type="text"
                                    maxLength={100}
                                    multiline
                                    value={comments}
                                    className="form-control "
                                    onChange={e => setComments(e.target.value)}
                                     autoComplete="off"/>
                             </div>
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-billstopay" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}