import { memo } from 'react';
import { Col, Container, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Switch from '../../../Forms/Inputs/switch';

const dataUnit = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
    { value: 'd', label: 'd' },
];

const NfePis = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mt-2">
                <Col md={12} lg={12}>
                    <label>Situação tributária do PIS</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormSelect {...register('fiscalTributary.nfceParameters.pis.tributarySituation')}>
                                <option value="">Selecione</option>

                                {dataUnit.map((item) => {
                                    return (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col md={12} lg={12}>
                    <div className="">
                        <Switch
                            name="fiscalTributary.nfceParameters.pis.isValueOfIcmsComposesPisBc"
                            label="Valor do ICMS compôe BC do PIS"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfePis);
