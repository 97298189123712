import { memo } from 'react';
import { Col, Container, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const dataUnit = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
    { value: 'd', label: 'd' },
];

const NfeIcms = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mt-2">
                <Col md={12} lg={12}>
                    <label>Situação tributária do ICMS - Simples Nacional</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormSelect {...register('fiscalTributary.nfceParameters.icms.tributarySituation')}>
                                <option value="">Selecione</option>

                                {dataUnit.map((item) => {
                                    return (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col md={12} lg={12}>
                    <label>Modalidade BC ICMS</label>
                    <div className="d-flex">
                        <FormSelect {...register('fiscalTributary.nfceParameters.icms.bcModality')}>
                            <option value="">Selecione</option>

                            {dataUnit.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfeIcms);
