import React, { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import ShowMessages from '../Exceptions';
import MaskInput from '../Forms/Inputs/maskedInput';
import Switch from '../Forms/Inputs/switch';
import { checkPermission } from '../security';
import ModalLocation from '../../pages/modals/modal-location';

export const getGeneralInfoProductSchema = yup.object({
  product: yup.object({
    code: yup.string().required('Prencha o campo!'),
    name: yup.string().required('Prencha o campo!'),
    type: yup.string().required('Prencha o campo!'),
    unity: yup.string().required('Prencha o campo!'),
    gtinEanCode: yup.string().required('Selecione uma unidade!'),
    ncm: yup.string().required('Prencha o campo!'),
    costPrice: yup.string().required('Preencha com um valor!'),
    salePrice: yup.string().required('Preencha com um valor!'),
    origin: yup.string().required('Escolha uma origem!'),
    status: yup.string().required('Escolha um status'),
  }),
});

const General = ({
  dataProductType,
  dataUnit,
  dataOrigin,
  dataProductStatus,
  dataProductLocation,
  setReload,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext({});

  const [showLocation, setShowLocation] = React.useState(false);

  const handleShowModalLocation = (value) => {
    if (value) {
      if (!checkPermission(1, 17) || !checkPermission(2, 17)) {
        ShowMessages({
          message: 'Você não tem permissão para executar essa operação',
          status: 500,
        });
        return;
      }
      document.querySelector('#modal-product').style.opacity = 0.0;
    } else document.querySelector('#modal-product').style.opacity = 1;

    setShowLocation(value);
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <Col md={4} lg={4}>
            <label>
              Código do produto <span className="text-danger">*</span>
            </label>
            <div className="d-flex">
              <div className="w-100">
                <FormControl
                  {...register('product.code')}
                  name="product.code"
                  id="product.code"
                  type="text"
                  maxLength={100}
                  className={`${errors?.product?.code ? 'is-invalid' : ''}`}
                  autoComplete="off"
                />

                {errors.product?.code && (
                  <span className="error-message">
                    {errors?.product?.code?.message}
                  </span>
                )}
              </div>
              <button
                type="button"
                className="btn btn-primary w-auto d-none"
                style={{ marginLeft: '5px' }}
              >
                <i className="fa-solid fa-arrow-rotate-right" />
              </button>
            </div>
          </Col>
          <Col md={4} lg={4}>
            <label>
              Nome do produto <span className="text-danger">*</span>
            </label>
            <div className="input-group ">
              <FormControl
                {...register('product.name')}
                name="product.name"
                id="product.name"
                type="text"
                maxLength={100}
                className={`${errors?.product?.name ? 'is-invalid' : ''}`}
                autoComplete="off"
              />

              {errors?.product?.name && (
                <p className="error-message">
                  {errors?.product?.name?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={4} lg={4}>
            <label>
              Tipo <span className="text-danger">*</span>
            </label>
            <div className="">
              <select
                {...register('product.type')}
                name="product.type"
                id="product.type"
                className={`${
                  errors.product?.type ? 'is-invalid' : ''
                } form-select`}
              >
                <option value="">Selecione</option>
                {dataProductType.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>

              {errors.product?.type && (
                <p className="error-message">
                  {errors?.product?.type?.message}
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={3} lg={3}>
            <label>Marca</label>
            <div className="input-group">
              <FormControl
                {...register('product.brand')}
                name="product.brand"
                id="product.brand"
                type="text"
                placeholder=""
                maxLength={50}
                className="form-control "
                autoComplete="off"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>
              Unidade <span className="text-danger">*</span>
            </label>
            <div className="">
              <FormSelect
                {...register('product.unity')}
                className={`${errors.product?.unity ? 'is-invalid' : ''}`}
              >
                <option value="">Selecione</option>

                {dataUnit.map((item) => {
                  return (
                    <option key={item.value} value={Number(item.value)}>
                      {item.label}
                    </option>
                  );
                })}
              </FormSelect>

              {errors.product?.unity && (
                <p className="error-message">
                  {errors?.product?.unity?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label className="required">Código GTIN/EAN</label>
            <div className="input-group">
              <FormControl
                {...register('product.gtinEanCode')}
                name="product.gtinEanCode"
                id="product.gtinEanCode"
                type="text"
                maxLength={100}
                className={`${errors.product?.gtinEanCode ? 'is-invalid' : ''}`}
                autoComplete="off"
              />

              {errors.product?.gtinEanCode && (
                <p className="error-message">
                  {errors?.product?.gtinEanCode?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>
              NCM <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <FormControl
                {...register('product.ncm')}
                name="product.ncm"
                id="product.ncm"
                type="text"
                maxLength={100}
                className={`${errors.product?.ncm ? 'is-invalid' : ''}`}
                autoComplete="off"
              />

              {errors.product?.ncm && (
                <p className="error-message">{errors?.product?.ncm?.message}</p>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={3} lg={3}>
            <label>Peso Bruto em KG</label>
            <div className="d-flex justify-content-between">
              <FormControl
                {...register('product.grossWeight')}
                name="product.grossWeight"
                id="product.grossWeight"
                type="number"
                className="form-control text-right"
                autoComplete="off"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>Peso Líquido em KG</label>
            <div className="d-flex justify-content-between">
              <FormControl
                {...register('product.netWeight')}
                name="product.netWeight"
                id="product.netWeight"
                type="number"
                className="form-control text-right"
                autoComplete="off"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>
              Valor de custo <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <Controller
                name="product.costPrice"
                control={control}
                render={({ field }) => (
                  <>
                    <MaskInput
                      id="product.costPrice"
                      type="text"
                      mask="currency"
                      field={field}
                      className={`${
                        errors.product?.costPrice ? 'is-invalid' : ''
                      } text-right`}
                      autoComplete="off"
                    />
                  </>
                )}
              />

              {errors.product?.costPrice && (
                <p className="error-message">
                  {errors?.product?.costPrice?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>
              Valor de venda <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <Controller
                name="product.salePrice"
                control={control}
                render={({ field }) => (
                  <MaskInput
                    name="product.salePrice"
                    id="product.salePrice"
                    mask="currency"
                    field={field}
                    className={`${
                      errors.product?.salePrice ? 'is-invalid' : ''
                    } text-right`}
                    autoComplete="off"
                  />
                )}
              />

              {errors.product?.salePrice && (
                <p className="error-message">
                  {errors.product?.salePrice?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={3} lg={3}>
            <div className="input-group">
              <button type="button" className="btn btn-primary d-none">
                <span>Formador de preço </span>
                <i className="fa-solid fa-dollar-sign" />
              </button>
            </div>
          </Col>
        </Row>

        {/* LINHA 4 */}
        <Row className="mt-2">
          <Col md={3} lg={3}>
            <label>
              Origem do produto <span className="text-danger">*</span>
            </label>
            <div>
              <FormSelect
                {...register('product.origin')}
                className={`${errors.product?.origin ? 'is-invalid' : ''}`}
                name="product.origin"
                id="product.origin"
              >
                <option value="">Selecione</option>
                {dataOrigin.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.value} - {item.label}
                    </option>
                  );
                })}
              </FormSelect>

              {errors.product?.origin && (
                <p className="error-message">
                  {errors.product?.origin?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>
              Status <span className="text-danger">*</span>
            </label>
            <div className="">
              <FormSelect
                {...register('product.status')}
                className={`${
                  errors.product?.status ? 'is-invalid' : ''
                } form-select`}
                name="product.status"
                id="product.status"
              >
                <option value="">Selecione</option>
                {dataProductStatus.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </FormSelect>

              {errors.product?.status && (
                <p className="error-message">
                  {errors?.product?.status?.message}
                </p>
              )}
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>Código de barras interno</label>
            <div className="input-group">
              <FormControl
                {...register('product.internalBarCode')}
                name="product.internalBarCode"
                id="product.internalBarCode"
                type="text"
                className="form-control "
                autoComplete="no"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={3} lg={3}>
            <label>Localização no estoque</label>
            <div className="d-flex">
              <div className="input-group">
                <FormSelect
                  {...register('product.stockLocation')}
                  className="w-100"
                >
                  <option value="">Selecione</option>
                  {dataProductLocation.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </FormSelect>
              </div>
              <button
                type="button"
                onClick={() => handleShowModalLocation(true)}
                className="btn btn-success"
                style={{ marginLeft: '5px' }}
                title="Inserir uma nova localização de estoque."
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>Estoque inicial</label>
            <div className="input-group">
              <FormControl
                {...register('product.initialStock')}
                name="product.initialStock"
                id="product.initialStock"
                type="number"
                min="0"
                className="text-right"
                autoComplete="off"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>Estoque mínimo</label>
            <div className="input-group">
              <FormControl
                {...register('product.minimumStock')}
                name="product.minimumStock"
                id="product.minimumStock"
                type="number"
                min="0"
                className="text-right"
                autoComplete="off"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <label>Estoque máximo</label>
            <div className="input-group">
              <FormControl
                {...register('product.maximumStock')}
                name="product.maximumStock"
                id="product.maximumStock"
                type="number"
                min="0"
                className="text-right"
                autoComplete="off"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={9} lg={9}>
            <label>Observações</label>
            <div className="input-group" style={{ height: '150px' }}>
              <FormControl
                {...register('product.observation')}
                name="product.observation"
                id="product.observation"
                as="textarea"
              />
            </div>
          </Col>
          <Col md={3} lg={3}>
            <div
              //  className="h-75 p-1 pb-5 ms-2 mt-3 border rounded w-100"
              style={{
                padding: '5px',
                border: '1px solid #cacaca',
                marginLeft: '15px',
                borderRadius: '5px',
                marginTop: '20px',
                width: '100%',
                height: '150px',
              }}
            >
              <div
                style={{
                  background: '#fff',
                  marginTop: '-16px',
                  width: '74px',
                }}
              >
                <span>Mais Opções</span>
              </div>
              <div className="d-flex justify-content-center">
                <Switch name="kitStructure.isKit" label="Produto Kit?" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ModalLocation
        data={dataProductLocation}
        editState={false}
        handleCloseModal={() => handleShowModalLocation(false)}
        handleReload={setReload}
        showModal={showLocation}
      />
    </>
  );
};

export default memo(General);
