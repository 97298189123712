import { memo, useState, useEffect } from 'react';
import { Col, Container, Form, Row, FormSelect } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import api from '../../services/api';
import ShowMessages from '../Exceptions';
import Switch from '../Forms/Inputs/switch';

const colourOptions = [
  { label: 'Azul', value: 'blue' },
  { label: 'Vermelho', value: 'red' },
  { label: 'Preto', value: 'black' },
  { label: 'Rosa', value: 'pink' },
  { label: 'Verde', value: 'green' },
  { label: 'Amarelo', value: 'yellow' },
];

export const getKitSchema = yup.object({
  // isKit: yup.bool().oneOf([true], 'Você deve adicionar pelo menos um kit!'),
  // kit: yup.array().min(1, "Você deve adicionar pelo menos 1 produto").required("adas")
  // .of(
  //     yup.object().shape({
  //         // product: yup.object().shape({
  //         //     value: yup.string().required(),
  //         //     label: yup.string().required(),
  //         // }).required("Escolha um produto!"),
  //         // quantity: yup.string().required("Escolha uma quantidade!"),
  //     })),
});

const Kit = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [productOptions, setProductOptions] = useState([
    {
      label: 'Alguma coisa',
      value: 1,
    },
    {
      label: 'Alguma coisa 2',
      value: 2,
    },
    {
      label: 'Alguma coisa 3',
      value: 3,
    },
  ]);

  const { fields, append, remove } = useFieldArray({
    name: 'kits',
  });
  const isKit = watch('kitStructure.isKit');
  console.log(errors);
  const filterColors = (inputValue) => {
    return colourOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  useEffect(() => {
    const loadOptions = async (inputValue, callback) => {
      try {
        const response = await api.get('/getProductsData');
        console.log(response.data, 'response.data');
      } catch (error) {
        ShowMessages(error);
      }
    };
    loadOptions();
  }, []);

  return (
    <Container fluid className="mt-2">
      <Row className="mt-2">
        <Col lg={6} md={6} className="d-flex justify-content-center mb-4">
          {/* <Switch name="kitStructure.isKit" label="Este produto é um KIT?" />
        </Col>
        <Col lg={6} md={6} className="d-flex justify-content-center"> */}
          <Switch
            name="kitStructure.dismemberItems"
            label="Desmembrar items do Kit no momento da Venda!"
          />
        </Col>
      </Row>
      {isKit && (
        <div>
          {fields.map((field, index) => (
            <Row
              key={field.id}
              className="d-flex align-items-center w-100 mb-2"
            >
              <Col
                sm={1}
                md={1}
                lg={1}
                className="badge text-bg-primary align-self-end"
                style={{ width: '30px' }}
              >
                <span
                  style={{
                    fontSize: '1.3rem',
                    marginBottom: '-20px',
                    display: 'inline-block',
                  }}
                >
                  {index + 1 + '.'}
                </span>
              </Col>
              <Col md={7} lg={7} className="d-flex align-items-end">
                <div className="input-group">
                  <label>Produto/Código</label>
                  <FormSelect
                    {...register(`kitStructure.kit.${index}.product`)}
                    className="w-100"
                  >
                    <option value="">Selecione o produto</option>
                    {productOptions?.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.value} - {item.label}
                        </option>
                      );
                    })}
                  </FormSelect>
                </div>
                {/* <p className="error-message">{errors?.kit?.[index].product?.message}</p> */}
              </Col>
              <Col>
                <div className="input-group">
                  <label>Quantidade</label>
                  <Form.Control
                    className="form-control"
                    name={`kitStructure.kit.${index}.quantity`}
                    id="quantity"
                    type="number"
                    key={field.id}
                    {...register(`kitStructure.kit.${index}.quantity`)}
                  />
                </div>
                {/* <p className="error-message">{errors?.kitStructure?.kit?.[index].quantity?.message}</p> */}
              </Col>
              <Col sm={1} md={1} lg={1} className="  align-self-end">
                <button
                  className="btn btn-danger mx-1"
                  onClick={() => remove(index)}
                >
                  <i className="fa-solid fa-trash" />
                </button>
              </Col>
            </Row>
          ))}
          <button
            type="button"
            title="adicionar outro Produto"
            className="btn btn-success font-weight-bold"
            onClick={() => append({ product: '', quantity: '' })}
          >
            <i className="fa-solid fa-plus" />
          </button>

          <p className="error-message">{errors?.kitStructure?.kit?.message}</p>
        </div>
      )}
      <div>
        <p className="error-message">{errors?.kitStructure?.isKit?.message}</p>
      </div>
      <div>
        <span className="text-bold">Controle de Estoque</span>
        <div className="my-2">
          <Controller
            control={control}
            name="kitStructure.movimentInventory"
            defaultValue="kit"
            render={({ field: { onChange, value } }) => (
              <>
                <div>
                  {/* <form> */}
                  <Form.Check
                    form="form-product"
                    name="kitStructure.movimentInventory"
                    label="Movimentar estoque somente do Kit"
                    type="radio"
                    id="movimentInventory1"
                    checked={value === 'kit'}
                    onChange={() => {
                      onChange('kit');
                    }}
                  />

                  <Form.Check
                    inline
                    form="form-product"
                    name="kitStructure.movimentInventory"
                    label="Movimentar estoque dos produtos do Kit (Matéria Prima)"
                    type="radio"
                    id="movimentInventory2"
                    checked={value === 'product'}
                    onChange={() => {
                      onChange('product');
                    }}
                  />
                  {/* </form> */}
                </div>
              </>
            )}
          />
        </div>
      </div>
    </Container>
  );
};

export default memo(Kit);
