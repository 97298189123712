import { memo } from 'react';
import { Col, Container, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Switch from '../../Forms/Inputs/switch';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const NfseDetails = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={3} md={3}>
                    <label htmlFor="details-operationNature">Natureza da operação</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('details.operationNature')}
                            id="details-operationNature"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3}>
                    <label htmlFor="details-operationRegime">Regime de operação</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('details.operationRegime')}
                            id="details-operationRegime"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3}>
                    <label htmlFor="details-provisionLocal">Local de prestação</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('details.provisionLocal')}
                            id="details-provisionLocal"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3} className="d-flex align-items-center">
                    <Switch name="details.isCivilConstruction" label="É de construção cívil" />
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfseDetails);
