import React, {useState, useEffect} from 'react'

import { ValidationForm, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';

import ShowMessages from '../../components/Exceptions'

import api from '../../services/api'
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ChangeUserGroup({showModal, handleCloseModal, data, idUser, handleReload}) {
    const [usergroup, setUserGroup ]               = useState('')
    const [newusergroup, setNewUserGroup ]         = useState(-1)

    const [dataUserGroup, setDataUserGroup]        = useState([])

    const handleSubmit = async(e) => {
        e.preventDefault()
        loadingButton(true,document.querySelector('#change-usergroup'),document.querySelector('#change-usergroup').children[0].classList,document.querySelector('#change-usergroup').lastChild.nodeValue)
        try {
            await api.put('/user/' + idUser, { newusergroup })
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                            if (response.data.status === 200)
                                handleCloseModal()  
                            else 
                                loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
                         } else 
                            loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
                         handleReload(true)
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
                        ShowMessages(response)       
                    })
        } catch (response) {
            loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
            ShowMessages(response)          
        }
    }

    const getData = async() => {
        await api.get('/getUserGroupData', { newusergroup })
            .then(response => {
                if (response.ok) 
                   setDataUserGroup(response.data)  
        }).catch(response => {
            ShowMessages(response)
        })
    } 

    useEffect(() => {
        if (showModal) {
            setStatistic('modals\\modal-changeUserGroup')
            setNewUserGroup(-1)
            if (data.length > 0)
                setUserGroup(data[0].usergroup)
            getData()  
        }  
    } ,[showModal, data])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-users"></i> Alteração de Grupo do Usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "Flex", flexDirection:"column" }}>
                        <div className="box-matricula alert alert-success mb-1">
                            <label>Grupo Atual</label>
                            <span>{usergroup}</span>
                        </div>
                        <div>
                            <label>Novo Grupo</label>
                            <div>
                                <SelectGroup 
                                    name="usergroup" 
                                    id="usergroup" 
                                    placeholder="Selecione" 
                                    required
                                    errorMessage={{ required: "Por favor, informe um grupo de usuários!" }}
                                    className="form-select"
                                    value={newusergroup} 
                                    onChange={e => setNewUserGroup(parseInt(e.target.value))}>
                                        <option value="">Selecione</option>
                                        {dataUserGroup.map((data, id) => {
                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                    })} 
                                </SelectGroup> 
                            </div>
                        </div>
                </div>    
            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id="change-usergroup" className="btn-theme">
                    <em className="fa fa-users"></em> Alterar
                </Button>          
                <Button className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}