import React, { useState, useEffect } from 'react';

import {
  ValidationForm,
  TextInput,
  SelectGroup,
} from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal, Alert, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import MaskWithValidation from '../../components/maskInput';
import MaskedInput from 'react-text-mask';
import Loading from '../../components/Loading';
import TooltipItem from '../../components/tooltip';
import CEP from 'cep-promise';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import setStatistic from '../../services/apiStatistic';

import api from '../../services/api';
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';
import loadingButton from '../layouts/Buttons';
import consultarCNPJ from 'consultar-cnpj';
import '../../styles/app/common/label.scss';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalClient({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingButtonCNPJ, setLoadingButtonCNPJ] = useState(false);
  const [tabActive, setTabActive] = useState(0);

  const [dataPeopleStatus, setDataPeopleStatus] = useState([]);
  const [dataContactType, setDataContactType] = useState([]);
  const [dataPhoneType, setDataPhoneType] = useState([]);
  const [dataPhoneOperator, setDataPhoneOperator] = useState([]);
  const [dataKindOfPerson, setDataKindOfPerson] = useState([]);

  const [contactType, setContactType] = useState(-1);
  const [contactValue, setContactValue] = useState('');

  const [CPF, setCPF] = useState('');
  const [rg, setRG] = useState('');
  const [municipalRegistration, setMunicipalRegistration] = useState('');
  const [name, setName] = useState('');
  const [placa, setPlaca] = useState('');
  const [renavam, setRenavam] = useState('');
  const [fantasy, setFantasy] = useState('');
  const [kindofperson, setKindofperson] = useState(0);
  const [mother, setMother] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [idsex, setIdSex] = useState(-1);
  const [idpeoplestatus, setIdpeoplestatus] = useState(-1);
  const [comments, setComments] = useState('');

  const [cep, setCEP] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUF] = useState('');
  const [contacts, setContacts] = useState([]);
  const [dataSex, setDataSex] = useState('');
  const [indexPerson, setIndexPerson] = useState('');

  const checkData = (event, formData, errorInputs) => {
    if (
      formData.name === '' ||
      formData.birthdate === '' ||
      formData.idsex === '' ||
      formData.idpeoplestatus === ''
    ) {
      document.querySelector('#personal-data').style.color = '#dc3545';
      document.querySelector('#personal-data').parentElement.click();
    } else document.querySelector('#personal-data').style.color = '#656565';
  };

  const setData = (obj, clear) => {
    setTabActive(0);

    setContactType(-1);
    setContactValue('');
    setCPF('');
    setRG('');
    setMunicipalRegistration('');

    if (obj.documents !== undefined)
      if (obj.documents.length > 0)
        obj.documents.map((item) => {
          if (item.idtype === 0 || item.idtype === 1)
            setCPF(clear ? '' : item.value);

          if (item.idtype === 2) setRG(clear ? '' : item.value);

          if (item.idtype === 13)
            setMunicipalRegistration(clear ? '' : item.value);
        });

    setKindofperson(clear ? '' : obj.kindofperson);
    setName(clear ? '' : obj.name);
    setMother(clear ? '' : obj.mother);
    setFantasy(clear ? '' : obj.fantasy);
    setBirthdate(clear ? '' : obj.birthdate ? obj.birthdate.substr(0, 10) : '');
    setIdSex(clear ? -1 : obj.idsex);
    setIdpeoplestatus(clear ? -1 : obj.idpeoplestatus);
    setComments(clear ? '' : obj.comments ? obj.comments : '');

    setCEP(clear ? '' : obj.addresses.cep);
    setStreet(clear ? '' : obj.addresses.street);
    setNumber(clear ? '' : obj.addresses.number);
    setComplement(clear ? '' : obj.addresses.complement);
    setNeighborhood(clear ? '' : obj.addresses.neighborhood);
    setCity(clear ? '' : obj.addresses.city);
    setUF(clear ? '' : obj.addresses.uf);

    setContacts(clear ? [] : obj.contacts);
  };

  const getAddress = (value) => {
    let _cep = value.replace(/\D/g, '');
    if (_cep.length === 8) {
      CEP(_cep)
        .then((data) => {
          setStreet(data.street);
          setCEP(value);
          if (data.street === '') {
            document.querySelector('#street').removeAttribute('disabled');
          } else {
            document.querySelector('#street').setAttribute('disabled', true);
          }

          setNeighborhood(data.neighborhood);
          if (data.neighborhood === '') {
            document.querySelector('#neighborhood').removeAttribute('disabled');
          } else {
            document
              .querySelector('#neighborhood')
              .setAttribute('disabled', true);
          }
          setUF(data.state);
          setCity(data.city);
        })
        .catch();
    } else {
      setStreet('');
      setNeighborhood('');
      setUF('');
      setCity('');
      document.querySelector('#street').setAttribute('disabled', true);
      document.querySelector('#neighborhood').setAttribute('disabled', true);
    }
  };

  const handleSubmit = async (event) => {
    loadingButton(
      true,
      document.querySelector('#submit-client'),
      document.querySelector('#submit-client').children[0].classList,
      document.querySelector('#submit-client').lastChild.nodeValue,
    );
    event.preventDefault();

    try {
      let type = 1;
      let idcompany = ReadDataStorage(IDCOMPANY);

      let _data = {
        name,
        CPF,
        rg,
        fantasy,
        kindofperson,
        municipalRegistration,
        birthdate,
        idsex,
        idpeoplestatus,
        comments,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        uf,
        contacts,
        type,
        idcompany,
      };

      if (editState)
        await api
          .put('/client/' + data.idpeople, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(false, document.querySelector('#submit-client'));
            } else
              loadingButton(false, document.querySelector('#submit-client'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-client'));
            ShowMessages(error);
          });
      else
        await api
          .post('/client', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(false, document.querySelector('#submit-client'));
            } else
              loadingButton(false, document.querySelector('#submit-client'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-client'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-client'));
      ShowMessages(response);
    }
  };

  const handleKindOfPerson = async (value) => {
    setKindofperson(value);
    setIndexPerson(value);
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api
        .get('/getClientData')
        .then((response) => {
          if (response.ok) {
            setDataSex(response.data.sex);
            setDataPeopleStatus(response.data.peopleStatus);
            setDataKindOfPerson(response.data.kindofperson);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  const getCNPJ = async () => {
    if (CPF.replace(/\D/g, '').length === 14) {
      setLoadingButtonCNPJ(true);
      try {
        const empresa = await consultarCNPJ(CPF, '');
        const estabelecimento = empresa.estabelecimento;

        setName(empresa.razao_social);
        setCEP(estabelecimento.cep);
        getAddress(estabelecimento.cep);
        setComplement(
          estabelecimento.complemento
            ? estabelecimento.complemento.replace(/  +/g, ' ')
            : '',
        );
        setNumber(estabelecimento.numero);
        setFantasy(
          estabelecimento.nome_fantasia === null
            ? empresa.razao_social
            : estabelecimento.nome_fantasia,
        );
        setRG(
          estabelecimento.inscricoes_estaduais.length > 0
            ? estabelecimento.inscricoes_estaduais[0].inscricao_estadual
            : '',
        );
        setLoadingButtonCNPJ(false);
      } catch (e) {
        setLoadingButtonCNPJ(false);
        ShowMessages(e);
      }
    } else ShowMessages({ message: 'CNPJ incompleto', status: 500 });
  };

  const validarPlaca = (placa) => {
    const regexPlaca = /^[a-zA-Z]{3}\d{4}$|^[a-zA-Z]{3}[0-9][a-zA-Z]\d{2}$/;
    return regexPlaca.test(placa);
  };

  const handleChangePlaca = (e) => {
    const novaPlaca = e.target.value;
    setPlaca(novaPlaca.toUpperCase());
  };

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-client');
  }, []);

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
  }, [data, editState, showModal]);

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogClassName="height550 width775"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
        onErrorSubmit={checkData}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-truck"></i> Propriedades do Veículo
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} addClass="heigth330px" />
          <div className={`${loading ? 'hidden' : ''}`}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={tabActive === 0 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(0);
                  }}
                >
                  <span id="personal-data">Dados Cadastrais</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId={0}>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <div style={{ width: '33%' }}>
                    <label className="required">Nome do proprietário</label>
                    <div className="input-group">
                      <TextInput
                        name="name"
                        id="name"
                        type="text"
                        required
                        maxLength={100}
                        errorMessage={{
                          required: 'Por favor, informe um nome!',
                        }}
                        value={name}
                        autoFocus={true}
                        className="form-control "
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div style={{ width: 33 + '%', marginLeft: '15px' }}>
                    <label>Tipo de Pessoa</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>

                  {kindofperson == 0 && (
                    <div style={{ width: 33 + '%', marginLeft: '15px' }}>
                      <label className="required">CPF</label>
                      <div className="input-group">
                        <MaskedInput
                          name="cpf"
                          id="cpf"
                          maxLength={25}
                          value={CPF}
                          required
                          errorMessage={{
                            required: 'Por favor, informe um CPF!',
                          }}
                          className="form-control"
                          onChange={(e) => setCPF(e.target.value)}
                          autoComplete="off"
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      </div>
                    </div>
                  )}

                  {kindofperson == 1 && (
                    <div style={{ width: 33 + '%', marginLeft: '15px' }}>
                      <label>CNPJ</label>
                      <div className="input-group">
                        <MaskedInput
                          name="cnpj"
                          id="cnpj"
                          maxLength={25}
                          value={CPF}
                          required
                          errorMessage={{
                            required: 'Por favor, informe um CNPJ!',
                          }}
                          className="form-control"
                          onChange={(e) => setCPF(e.target.value)}
                          autoComplete="off"
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '/',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      </div>
                    </div>
                  )}

                  {kindofperson === 1 ? (
                    <div
                      style={{
                        width: 33 + '%',
                        display: 'flex',
                        marginLeft: '15px',
                      }}
                    >
                      <div>
                        <button
                          disabled={loadingButtonCNPJ ? 'disabled' : ''}
                          type="button"
                          onClick={(e) => getCNPJ()}
                          className="btn btn-sm btn-success"
                          title="Pesquisa CNPJ"
                          style={{ marginLeft: '5px', marginTop: '19px' }}
                        >
                          {loadingButtonCNPJ ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <i className="fa fa-search" />
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <div style={{ width: 50 + '%' }}>
                    <label>Tipo</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                  <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                    <label className="required">Placa</label>
                    <div className="input-group">
                      <TextInput
                        name="name"
                        id="name"
                        type="text"
                        required
                        maxLength={8}
                        errorMessage={{
                          required: 'Por favor, informe uma placa!',
                        }}
                        value={placa}
                        autoFocus={true}
                        className="form-control "
                        onChange={handleChangePlaca}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <div style={{ width: 50 + '%' }}>
                    <label className="required">Renavam</label>
                    <div className="input-group">
                      <TextInput
                        name="name"
                        id="name"
                        type="text"
                        required
                        maxLength={100}
                        errorMessage={{
                          required: 'Por favor, informe uma placa!',
                        }}
                        value={renavam}
                        autoFocus={true}
                        className="form-control "
                        onChange={(e) => setRenavam(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                    <label>Tipo de propriedade</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <div style={{ width: 33 + '%' }}>
                    <label>Marca</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                  <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                    <label>Modelo</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                  <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                    <label>Cor</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                </div>
                <div>
                  <label>Observações</label>
                  <textarea
                    maxLength={2400}
                    className="form-control"
                    name="comments"
                    id="comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-client"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
