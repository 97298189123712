import React from 'react';

import { isAuthenticated } from './services/auth';

import Products from './pages/maintenance/products';
import CreateNFe from './pages/maintenance/create-nfe';
import CreateNFSe from './pages/maintenance/create-nfse';
import CreateCTe from './pages/maintenance/create-cte';
import CreateMDFe from './pages/maintenance/create-mdfe';
import FiscalDocuments from './pages/maintenance/fiscal-documents';
import Employees from './pages/maintenance/employees';
import Company from './pages/maintenance/company';
import Profile from './pages/maintenance/profile';
import User from './pages/maintenance/users';
import UserGroup from './pages/maintenance/user-group';
import Occupations from './pages/maintenance/occupation';
import PermissionsGroup from './pages/maintenance/permissions-group';
import PermissionsUser from './pages/maintenance/permissions-user';
import ReportBill from './pages/reports/report-bill';
import Error404 from './pages/errors/404';
import Bill from './pages/maintenance/bill';
import Alert from './pages/maintenance/alert';
import AlertAll from './pages/maintenance/alerts-all';
import NewsletterSystem from './pages/maintenance/newsletter-system';
import Providers from './pages/maintenance/providers';
import BillsToPay from './pages/maintenance/billstopay';
import CostCenter from './pages/maintenance/cost-center';
import ChartOfAccounts from './pages/maintenance/chart-of-accounts';
import PaymentConditions from './pages/maintenance/payment-conditions';
import Holidays from './pages/maintenance/holidays';
import ProductLocation from './pages/maintenance/product-location';
import Budget from './pages/maintenance/budget';
import Clients from './pages/maintenance/clients';
import Miner from './pages/maintenance/miner';
import Vehicle from './pages/maintenance/vehicle';
import Departament from './pages/maintenance/departament';
import CRS from './pages/maintenance/crs';
import Settings from './pages/maintenance/settings';
import PDV from './pages/maintenance/pdv';
import EmailList from './pages/maintenance/email-list';
import Condominos from './pages/maintenance/condiminos';

const getObject = (uri, id, props, idmenu) => {
  switch (uri) {
    case '/products':
      return <Products />;
    case '/residents':
        return <Condominos />;
    case '/create-nfe':
      return <CreateNFe />;
    case '/create-nfse':
      return <CreateNFSe />;
    case '/create-cte':
      return <CreateCTe />;
    case '/create-mdfe':
      return <CreateMDFe />;
    case '/nfe':
      return <FiscalDocuments />;
    case '/employees':
      return <Employees />;
    case '/company':
      return <Company />;
    case '/profile':
      return <Profile />;
    case '/users':
      return <User />;
    case '/user-groups':
      return <UserGroup />;
    case '/bill':
      return <Bill />;
    case '/occupations':
      return <Occupations />;
    case '/trucks':
      return <Vehicle />;
    case '/alert-all':
      return <AlertAll />;
    case '/newsletter-system':
      return <NewsletterSystem />;
    case '/provider':
      return <Providers />;
    case '/billstopay':
      return <BillsToPay />;
    case '/cost-centers':
      return <CostCenter />;
    case '/chart-of-accounts':
      return <ChartOfAccounts />;
    case '/payment-conditions':
      return <PaymentConditions />;
    case '/holidays':
      return <Holidays />;
    case '/location':
      return <ProductLocation />;
    case '/budget':
      return <Budget />;
    case '/clients':
      return <Clients />;
    case '/miner':
      return <Miner />;
    case '/crs':
      return <CRS />;
    case '/departament':
      return <Departament />;
    case '/settings':
      return <Settings id={id} />;
    case '/pdv':
      return <PDV />;
    case '/alert':
      return <Alert id={id} props={props} />;
    case '/usergroup/permissions':
      return <PermissionsGroup id={id} props={props} />;
    case '/user/permissions':
      return <PermissionsUser id={id} props={props} />;
    case '/receipt':
      return <ReportBill id={id} props={props} />;
    case '/email-list':
      return <EmailList />;
    default:
      return <Error404 />;
  }
};

export default function getComponent(uri, id, props, idmenu) {
  return isAuthenticated ? getObject(uri, id, props, idmenu) : <Error404 />;
}
