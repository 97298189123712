import { create } from 'apisauce'
import { COMPANY, getToken, IDCOMPANY, IP, ReadDataStorage, REGISTRATION, WriteDataStorage, CONTRACT } from './auth'
import ShowMessages from '../components/Exceptions'

import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';

const api = create({
    baseURL : process.env.REACT_APP_IP_BACKEND + ':' + process.env.REACT_APP_PORT_BACKEND,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
})


api.addResponseTransform(response => {
    if (!response.ok) {
        if (response.data) {
            if (response.data.error) {
                let _registration = ReadDataStorage(REGISTRATION)            
                if (response.data.status === 401) {  
                    ShowMessages(response.data)
                    localStorage.clear()         
                } 

                if (response.data.error.message.indexOf('token') > 0) {
                    ShowMessages(response.data.error)
                    localStorage.clear()
                }
      
                if (response.data.error.status === 401){
                   ShowMessages(response.data.error)
                   localStorage.clear()
                } 
                WriteDataStorage(REGISTRATION,_registration)
                throw response
            } else {
                if (response.data.status === 401) {
                    ShowMessages(response.data)
                    localStorage.clear()         
                } else {
                    ShowMessages(response.data)
                    console.log(response)
                    throw response
                }
            }
                

        } else {
            if (response.problem === "NETWORK_ERROR") {
                NotificationManager.error('Erro ao conectar com o servidor, tente novamente em alguns instantes!', 'Erro');
            } else 
                throw response
        }
            
    }
})

api.addAsyncRequestTransform(request => async () => {
   let token = await getToken()

    if (token) {
        token = JSON.parse(token);
        request.headers["Authorization"] = `bearer ${token.token}`
        request.headers["idcompany"]     = ReadDataStorage(IDCOMPANY)
        request.headers["company"]       = ReadDataStorage(COMPANY)
        request.headers["contract"]      = ReadDataStorage(CONTRACT)
        request.headers["version"]       = ''
        request.headers["ip"]            = localStorage.getItem(IP)
        request.headers["software"]      = ''
    }
        
})

export default api