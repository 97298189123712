import React, { useState, useEffect } from 'react';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import * as yup from 'yup';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

const validateSchema = yup.object().shape({
    port: yup.number().integer().min(1).max(65535).required(),
    imap: yup.string(),
    user: yup.string().email().required(),
    password: yup.string().required(),
    tls: yup.string(),
});

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

const initialValue = {
    user: '',
    imap: '',
    port: '',
    password: '',
    tls: '',
    checkjunk: false,
    interval: 1,
    titles: [],
    title: '',
    boxes: [],
    selectedboxes: {},
};

export default function ModalIntegrationEmail({
    showModal,
    handleCloseModal,
    setUserData,
    userData,
    editState,

    handleReload,
}) {
    const [loading, setLoading] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);
    const [loadingBoxes, setLoadingBoxes] = useState(false);

    const addTitle = async () => {
        if (userData.title === '') {
            ShowMessages({
                status: 500,
                message: 'Informe um título aa ser inserido!',
            });
            return;
        }

        setUserData((prev) => ({
            ...prev,
            titles: prev.titles.concat({ title: prev.title }),
        }));
        setUserData((prev) => ({ ...prev, title: '' }));
    };

    const removeTitle = (title) => {
        const _remove = userData.titles.filter((t, i) => {
            if (t.title !== title) return true;

            return false;
        });
        setUserData((prev) => ({
            ...prev,
            titles: _remove,
        }));
    };

    const editTitle = (title) => {
        let _edit = userData.titles.filter((t, i) => {
            if (t.title === title) return true;

            return false;
        })[0];

        setUserData((prev) => ({
            ...prev,
            title: _edit.title,
        }));
        removeTitle(_edit.title);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        loadingButton(
            true,
            document.querySelector('#submit-integration-email'),
            document.querySelector('#submit-integration-email').children[0].classList,
            document.querySelector('#submit-integration-email').lastChild.nodeValue,
        );

        let formattedData = {
            ...userData,
            interval: userData.interval,
        };

        try {
            if (editState) {
   
                return await api
                    .put(
                        '/integration-email/' + userData.idintegrationemail,
                        formattedData,
                    )
                    .then((response) => {
                        if (response.ok) {
                            if (response.data.status === 200) {
                                setUserData(initialValue);
                                handleCloseModal();
                                handleReload(true);
                            } else
                                loadingButton(
                                    false,
                                    document.querySelector('#submit-integration-email'),
                                );
                        } else
                            loadingButton(
                                false,
                                document.querySelector('#submit-integration-email'),
                            );
                    })
                    .catch((error) => {
                        console.log(error, 'error submit');
                        loadingButton(
                            false,
                            document.querySelector('#submit-integration-email'),
                        );
                        ShowMessages(error);
                    });
            }
            await api
                .post('/integration-email', formattedData)
                .then((response) => {
                    if (response.ok) {
                        if (response.data.status === 200) {
                            setUserData(initialValue);
                            handleCloseModal();
                            handleReload(true);
                        } else
                            loadingButton(
                                false,
                                document.querySelector('#submit-integration-email'),
                            );
                    } else
                        loadingButton(
                            false,
                            document.querySelector('#submit-integration-email'),
                        );
                })
                .catch((error) => {
                    console.log(error, 'error submit');
                    loadingButton(
                        false,
                        document.querySelector('#submit-integration-email'),
                    );
                    ShowMessages(error);
                });
        } catch (response) {
            loadingButton(false, document.querySelector('#submit-integration-email'));
            ShowMessages(response);
        }
    };

    const checkConnection = async () => {
        try {
            setLoadingCheck(true);
            await api
                .post('/checkEmailConnection', userData)
                .then((response) => {
                    setLoadingCheck(false);
                    ShowMessages({
                        status: response.data.status,
                        message: response.data.message,
                    });
                })
                .catch((error) => {
                    setLoadingCheck(false);
                    ShowMessages(error);
                });
        } catch (response) {
            setLoadingCheck(false);
            ShowMessages(response);
        }
    };

    useEffect(() => {
        setStatistic('modals\\modal-integration-email');
    }, []);

    return (
        <Modal
            size="lg"
            show={showModal}
            onHide={handleCloseModal}
            animation={true}
            backdrop="static"
            dialogClassName="width700"
            dialogAs={DraggableModalDialog}
        >
            <ValidationForm
                onSubmit={handleSubmit}
                setFocusOnError={true}
                className="modal-content"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ marginBottom: '0px' }}>
                        <i className="fa fa-envelopes-bulk"></i> Propriedades de Integração
                        por Email
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Loading loading={loading} addClass="heigth390px" />
                    <div
                        className={`box-shadow ${loading ? 'hidden' : ''}`}
                        style={{ display: 'flex' }}
                    >
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <div style={{ width: '150px' }}>
                                    <label>Endereço IMAP</label>
                                    <label className="text-required">*</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={userData?.imap}
                                        onChange={(e) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                imap: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                                <div style={{ width: '70px' }}>
                                    <label>Porta</label>
                                    <label className="text-required">*</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={userData?.port}
                                        onChange={(e) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                port: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                                <div style={{ width: '260px' }}>
                                    <label>Usuário</label>
                                    <label className="text-required">*</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={userData?.user}
                                        onChange={(e) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                user: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                                <div style={{ width: '100px' }}>
                                    <label>Senha</label>
                                    <label className="text-required">*</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={userData?.password}
                                        onChange={(e) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                password: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <div style={{ width: '100px' }}>
                                    <label>Monitorar a cada</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={userData?.interval}
                                        onChange={(e) => {
                                            setUserData((prev) => ({
                                                ...prev,
                                                interval: e.target.value,
                                            }));
                                        }}
                                    />
                                    <small>minuto(s)</small>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label
                                        className="switch switch-sm"
                                        style={{ justifyContent: 'center' }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={userData?.tls}
                                            value={userData?.tls}
                                            onChange={(e) => {
                                                setUserData((prev) => ({
                                                    ...prev,
                                                    tls: !prev.tls,
                                                }));
                                            }}
                                        />
                                        <span style={{ marginTop: '5px' }}></span>
                                    </label>
                                    <span style={{ marginLeft: '5px', marginTop: '5px' }}>
                                        TLS?
                                    </span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        type="button"
                                        disabled={
                                            userData?.imap === '' ||
                                            userData?.port === '' ||
                                            userData?.user === '' ||
                                            userData?.password === '' ||
                                            loadingCheck
                                        }
                                        className={`btn-primary ${loading ? 'hidden' : ''}`}
                                        onClick={(e) => checkConnection()}
                                    >
                                        <em
                                            className={`${loadingCheck ? 'fa fa-spinner fa-spin' : 'fa fa-check'
                                                }`}
                                        ></em>{' '}
                                        Testar Conexão
                                    </Button>
                                </div>
                             
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <Button
                    style={{ color: '#fff' }}
                    type="button"
                    className="btn-success"
                    onClick={(e) => insertEmail()}
                  >
                    <em className="fa fa-plus" style={{ color: '#fff' }}></em>{' '}
                    Inserir Email
                  </Button> */}
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <div
                                    style={{
                                        border: '1px dashed #cacaca',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        width: '100%',
                                    }}
                                >
                                    <div>
                                        <div style={{ display: 'flex', gap: '5px', width: '100%' }}>
                                            <div style={{ width: '100%' }}>
                                                <label>Títulos a ser ignorado</label>
                                                <TextInput
                                                    name="title"
                                                    className="form-control "
                                                    value={userData?.title}
                                                    onChange={(e) => {
                                                        setUserData((prev) => ({
                                                            ...prev,
                                                            title: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    title="Insere um título a ser monitorado"
                                                    onClick={(e) => addTitle()}
                                                >
                                                    <i className="fa fa-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            overflow: 'auto',
                                            height: '180px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <table className="table-striped w-100">
                                            <thead className="thead-theme">
                                                <tr className="head-th">
                                                    <td
                                                        className="head-th"
                                                        style={{
                                                            width: '85px',
                                                            minWidth: '85px',
                                                            maxWidth: '85px',
                                                        }}
                                                    >
                                                        Título
                                                    </td>
                                                    <td style={{ textAlign: 'center', width: '60px' }}>
                                                        ...
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(userData?.titles || []).map((item, key) => {
                                                    return (
                                                        <tr
                                                            className="head-th"
                                                            key={key}
                                                            onDoubleClick={(e) => editTitle(item.title)}
                                                        >
                                                            <td style={{ color: '#000' }}>{item.title}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={(e) => removeTitle(item.title)}
                                                                >
                                                                    <em className="fa fa-trash"></em>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-warning  btn-sm"
                                                                    onClick={(e) => editTitle(item.title)}
                                                                    style={{ marginLeft: '5px' }}
                                                                >
                                                                    <em className="fa fa-edit"></em>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <div
                                            className={`box-sem-dados bg-warning ${(userData?.titles || []).length > 0 ? 'hidden' : ''
                                                }`}
                                            style={{ padding: '15px', marginTop: '10px' }}
                                        >
                                            Ainda não foi incluido nenhum título.
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div
                                className="bg bg-warning"
                                style={{
                                    marginTop: '15px',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <i
                                    style={{ color: '#000', fontSize: '26px' }}
                                    className="fa fa-triangle-exclamation"
                                ></i>
                                <span style={{ color: '#000', marginLeft: '10px' }}>
                                    Para email <span style={{ fontWeight: 'bold' }}>GMAIL</span>,
                                    ative a verificação em 2 etapas, posteriormente, utilize a{' '}
                                    <span style={{ fontWeight: 'bold' }}>SENHAS DE APP </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        style={{ display: 'flex', width: '100%', justifyContent: 'end' }}
                    >
                        <Button
                            type="submit"
                            id="submit-integration-email"
                            className={`btn-theme ${loading ? 'hidden' : ''}`}
                        >
                            <em className="fa fa-save"></em> Salvar
                        </Button>
                        <Button
                            className="btn-ligth-gray"
                            onClick={handleCloseModal}
                            style={{ marginLeft: '15px' }}
                        >
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </div>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
