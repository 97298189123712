import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const dataUnit = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
    { value: 'd', label: 'd' },
];

const NFEST = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            {/* LINHA 1 */}
            <Row className="mt-2">
                <Col md={12} lg={12}>
                    <label>Modalidade de determinação da BC do ICMS-ST</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormSelect {...register('fiscalTributary.nfeParameters.st.determinationModality')}>
                                <option value="">Selecione</option>

                                {dataUnit.map((item) => {
                                    return (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={4} lg={4} xs={12}>
                    <label>MVA do ICMS-ST(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.st.icmsMva')}
                            name="fiscalTributary.nfeParameters.st.icmsMva"
                            id="fiscalTributary.nfeParameters.st.icmsMva"
                            type="text"
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
                <Col md={4} lg={4} xs={12}>
                    <label>Aliquota do ICMS-ST(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.st.icmsAliquot')}
                            name="fiscalTributary.nfeParameters.st.icmsAliquot"
                            id="fiscalTributary.nfeParameters.st.icmsAliquot"
                            type="text"
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
                <Col md={4} lg={4} xs={12}>
                    <label>Redução de BC do ICMS-ST</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfeParameters.st.bcReduction')}
                            name="fiscalTributary.nfeParameters.st.bcReduction"
                            id="fiscalTributary.nfeParameters.st.bcReduction"
                            type="text"
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mt-4 pt-3 border-top">
                <Col lg={6} md={6}>
                    <div className="d-flex flex-column">
                        <span className="fw-bold">Valor aproximado dos tributos</span>
                        <span>percentual de cálculo de tributos conforme Lei n° 12.741/12</span>
                    </div>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <label>Federal(%)</label>
                            <div className="input-group">
                                <FormControl
                                    {...register('fiscalTributary.nfeParameters.st.federal')}
                                    name="fiscalTributary.nfeParameters.st.federal"
                                    id="fiscalTributary.nfeParameters.st.federal"
                                    type="number"
                                    className="form-control "
                                    autoComplete="no"
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={12}>
                            <label>Estadual(%)</label>
                            <div className="input-group">
                                <FormControl
                                    {...register('fiscalTributary.nfeParameters.st.state')}
                                    name="fiscalTributary.nfeParameters.st.state"
                                    id="fiscalTributary.nfeParameters.st.state"
                                    type="number"
                                    className="form-control "
                                    autoComplete="no"
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} md={6}>
                    <div className="d-flex flex-column">
                        <span className="fw-bold">Informações adicionais do produto na NF-e</span>
                        <span>As informações abaixo aparecerão abaixo da desccrição do produto da Danfe</span>
                    </div>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <div className="input-group">
                                <FormControl
                                    {...register('fiscalTributary.nfeParameters.st.information')}
                                    name="fiscalTributary.nfeParameters.st.information"
                                    id="fiscalTributary.nfeParameters.st.information"
                                    as="textarea"
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NFEST);
