import { useState } from 'react';
import { Button } from 'react-bootstrap';

import ModalClient from '../modals/modal-client';

import { FormProvider, useForm } from 'react-hook-form';
import NfseData from '../../components/Invoices/nfse/data';
import NfseDetails from '../../components/Invoices/nfse/details';
import NfseObservations from '../../components/Invoices/nfse/observations';
import NfsePayment from '../../components/Invoices/nfse/payment';
import NfseServices from '../../components/Invoices/nfse/services';
import NfseTotalValues from '../../components/Invoices/nfse/total-values';
import TitleToggle from '../../components/TitleToggle';
import { defaultValuesNfse } from '../../utils/defaultValues';

export default function CreateNFse() {
    const methods = useForm({
        mode: 'all',
        defaultValues: defaultValuesNfse,
    });
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = (data) => {
        console.log('🚀 ~ file: create-nfe.js ~ line 101 ~ handleSubmit ~ e', data);
    };

    return (
        <div style={{ padding: '16px', overflowY: 'auto', maxHeight: '80vh' }}>
            <ModalClient showModal={showModal} handleCloseModal={handleCloseModal} />

            <FormProvider {...methods}>
                <form id="form-nfse" onSubmit={methods.handleSubmit((data) => handleSubmit(data))}>
                    {/* DADOS NOTA FISCAL */}
                    <TitleToggle title="Dados da nota fiscal">
                        <NfseData />
                    </TitleToggle>

                    {/* DETALHES DA NOTA FISCAL */}
                    <TitleToggle title="Detalhes da nota fiscal" marginTop={'32px'}>
                        <NfseDetails />
                    </TitleToggle>

                    {/* SERVIÇOS */}
                    <TitleToggle title="Serviços" marginTop={'32px'}>
                        <NfseServices />
                    </TitleToggle>

                    {/* TOTAIS DA NOTA FISCAL */}
                    <TitleToggle title="Totais da nota fiscal" marginTop={'32px'}>
                        <NfseTotalValues />
                    </TitleToggle>

                    {/* PAGAMENTO */}
                    <TitleToggle title="Pagamento" marginTop={'32px'}>
                        <NfsePayment />
                    </TitleToggle>

                    {/* OBSEVAÇÕES */}
                    <TitleToggle title="Observações" marginTop={'32px'}>
                        <NfseObservations />
                    </TitleToggle>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '16px',
                            marginTop: '32px',
                        }}
                    >
                        <Button type="submit" id="submit-client" className={`btn-theme`}>
                            <em className="fa fa-save" /> Salvar
                        </Button>
                        <Button id="submit-client" className="btn-warning">
                            <em className="fa-solid fa-paper-plane" /> Emitir
                        </Button>
                        <Button className="btn-ligth-gray">
                            <em className="fa fa-door-closed" /> Fechar
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
