import React, { useState, useEffect } from 'react';
import ContentWrapper from '../layouts/contentWrapper';
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic';
import { checkPermission } from '../../components/security';
import ModalMiner from '../../pages/modals/modal-miner';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { format } from 'date-fns';

import Swal from 'sweetalert2';

export default function Miner() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalMiner, setShowModalMiner] = useState(false);
  const [loadingMiner, setLoadingMiner] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [print, setPrint] = useState(false);
  const [excel, setExcel] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [editState, setEditState] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [data, setData] = useState({});
  const [reset, setReset] = useState(false);
  const [dataTerms, setDataTerms] = useState([]);
  const [filters, setFilters] = useState([
    {
      field: 'update_at',
      key: 'between',
      value: format(new Date(), 'yyyy-MM-dd'),
      value2: format(new Date(), 'yyyy-MM-dd'),
      ignoretime: true,
    },
  ]);
  const [editPermission, setEditPermisson] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const [principalIsentEmpty, setPrincipalIsentEmpty] = useState(false);
  const [repetedData, setRepetedData] = useState(false);

  const [statusCandidateData, setStatusCandidateData] = useState([]);
  const [statusCandidate, setStatusCandidate] = useState('');

  const [utillization, setUtillization] = useState(0);
  const [crs, setCRS] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1200);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseModalMiner = () => {
    setShowModalMiner(false);
  };

  const changeFilter = () => {
    let _filter = [
      {
        field: 'update_at',
        key: 'between',
        value: startDate,
        value2: endDate,
        ignoretime: true,
        external: true,
      },
    ];

    if (principalIsentEmpty)
      _filter.push(
        {
          field: 'title',
          key: '<>',
          value: '',
          value2: null,
          ignoretime: true,
          external: true,
        },
        {
          field: 'name',
          key: '<>',
          value: '',
          value2: null,
          external: true,
        },
        {
          field: 'email',
          key: '<>',
          value: '',
          value2: null,
          external: true,
        },
        {
          field: 'phone',
          key: '<>',
          value: '',
          value2: null,
          external: true,
        },
      );

    if (statusCandidate !== '')
      _filter.push({
        field: 'idstatuscandidate',
        key: '=',
        value: statusCandidate,
        value2: null,
        external: true,
      });

    if (repetedData)
      _filter.push({
        field: '',
        key: 'distinct',
        value: null,
        value2: null,
        special: true,
        external: true,
      });

    if (crs)
      _filter.push({
        field: '',
        key: 'crs',
        value: null,
        value2: null,
        special: true,
        external: true,
      });
    setReset(true);
    setFilters(_filter);
  };

  const getCandidates = async () => {
    await api
      .get('/candidatestatus/0')
      .then((response) => {
        setStatusCandidateData(response.data);
        setGridParams({
          idcandidate: {
            text: '#ID',
            mask: { name: 'leftSlice', value: '00000' },
            primaryKey: true,
            visible: false,
          },
          update_at: { text: 'Data de Cadastro', dataType: 'date' },
          title: { text: 'Título' },
          name: { text: 'Nome', searchDefault: true },
          phone: { text: 'Telefone' },
          email: { text: 'Email' },
          folder: { text: 'Etiqueta' },
          origin: { text: 'Origem' },
          idstatuscandidate: { text: 'Status', visible: false },
          statuscandidate: {
            text: 'Status',
            item: {
              className: 'text-center',
              switch: {
                data: response.data,
                key: 'idstatuscandidate',
              },
            },
          },
          Options: {
            text: 'Opções',
            className: 'text-center',
            order: false,
            filter: false,
            item: [
              {
                type: 'Custom',
                props: {
                  tag: 'button',
                  title: 'Exclusão de cliente',
                  className: 'btn btn-sm btn-danger',
                  icon: 'fa fa-trash',
                  message: 'Deseja realmente excluir o cliente?',
                  indexcallback: 0,
                },
              },
              {
                type: 'Custom',
                props: {
                  tag: 'button',
                  title: 'Editar cliente',
                  className: 'btn btn-sm btn-warning',
                  icon: 'fa fa-edit',
                  indexcallback: 1,
                },
              },
              {
                type: 'Custom',
                props: {
                  tag: 'button',
                  title: 'Visualizar PDF',
                  className: 'btn btn-sm btn-primary',
                  visualConditional: { file: { operator: '!==', values: '' } },
                  icon: 'fa fa-file-pdf',
                  indexcallback: 2,
                },
              },
              {
                type: 'Custom',
                props: {
                  tag: 'button',
                  title: 'Processar email',
                  className: 'btn btn-sm btn-theme',
                  icon: 'fa fa-rotate',
                  visualConditional: {
                    idstatuscandidate: { operator: '!==', values: 1 },
                  },
                  indexcallback: 3,
                },
              },
              {
                type: 'Custom',
                props: {
                  tag: 'button',
                  title: 'Aprovar Curriculum',
                  className: 'btn btn-sm btn-success',
                  visualConditional: {
                    idstatuscandidate: { operator: '==', values: 0 },
                  },
                  icon: 'fa fa-check',
                  indexcallback: 4,
                },
              },
              {
                type: 'Custom',
                props: {
                  tag: 'button',
                  title: 'Informar Erro dos Dados',
                  className: 'btn btn-sm btn-secondary',
                  visualConditional: {
                    idstatuscandidate: { operator: '==', values: 0 },
                  },
                  icon: 'fa fa-bug',
                  indexcallback: 5,
                },
              },
            ],
          },
        });
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  useEffect(() => {
    getCandidates();
    handleUtilization();
    setStatistic('maintenance\\miner');
  }, []);

  useEffect(() => {
    handleUtilization();
  }, [reload]);

  const handleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const processLine = async (id) => {
    await api
      .get('/check-candidates/' + id)
      .then((response) => {
        setReload(true);
        ShowMessages(response);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const PDFDownload = async (id) => {
    await api
      .get('/candidate/' + id)
      .then((response) => {
        var link = document.createElement('a');
        link.download = response.data.name + response.data.extension;
        link.href = 'data:application/pdf;base64,' + response.data.file;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const RemoveCandidate = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente excluir o candidato?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .delete('/candidate/' + id)
          .then((response) => {
            ShowMessages(response.data);
            handleUtilization();
            setReload(true);
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  const EditCandidate = async (id) => {
    await api
      .get('/candidate/' + id)
      .then((response) => {
        setData(response.data);
        setShowModalMiner(true);
        setReload(true);
        setEditState(true);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const processMails = async () => {
    setLoadingMiner(true);
    await api
      .post('/process-candidates')
      .then((response) => {
        setReload(true);
        setLoadingMiner(false);
        handleUtilization();
        ShowMessages(response);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const handleMails = async () => {
    setLoadingMiner(true);
    await api
      .post('/check-candidates')
      .then((response) => {
        setReload(true);
        setLoadingMiner(false);
        handleUtilization();
        ShowMessages(response);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const processMail = async () => {
    setLoadingMiner(true);

    await api
      .post('/reprocess-candidates', { filters })
      .then((response) => {
        setReload(true);
        setLoadingMiner(false);
        ShowMessages(response);
        handleUtilization();
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const approved = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente aprovar o candidato?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .post('/candidate/approved/' + id)
          .then((response) => {
            ShowMessages(response.data);
            handleUtilization();
            setReload(true);
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  const error = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente recusar o candidato?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .post('/candidate/error/' + id)
          .then((response) => {
            ShowMessages(response.data);
            handleUtilization();
            setReload(true);
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  const handleMiner = async (id) => {
    await api
      .get('/integration-email/')
      .then((response) => {
        setData(response.data);
        handleUtilization();
        setShowModal(true);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const handleUtilization = async () => {
    await api
      .get('/candidate/utilization')
      .then((response) => {
        setUtillization(response.data.utillization);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/#"
      ref={ref}
      style={{marginTop:"4px"}}
      className="mnp-name"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </a>
  ));

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ContentWrapper>
      <Loading loading={loading} />
      <ModalMiner
        showModal={showModalMiner}
        handleCloseModal={handleCloseModalMiner}
        data={data}
      />
      <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
        <div className="content-heading">
          <div className="box-maintance-buttons">
            <div className="d-flex justify-content-center align-items-center flex-wrap">
              <DropdownButton
                variant={'success'}

                disabled={loadingMiner || !checkPermission(32, 23)}
                as={CustomToggle}
                title={
                  <i
                    className={`fa ${
                      loadingMiner
                        ? 'fa-spin fa-spinner'
                        : 'fa-envelope-open-text'
                    }`}
                  ></i>
                }
              >
                <Dropdown.Item href="#" onClick={(e) => processMails()}>
                  <i className="fa fa-envelope-open" /> Processar Emails
                </Dropdown.Item>
                {/* <Dropdown.Item href="#" onClick={e => handleMails()}><i className='fa fa-envelopes-bulk'/> Reprocessar Todos os Emails</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={e => processMail()}><i className='fa fa-envelope-circle-check'/>   Reprocessar os Listados</Dropdown.Item> */}
              </DropdownButton>

              <button
                className="btn btn-primary"
                title="Atualizar Dados"
                style={{ marginLeft: '3px' }}
                onClick={(e) => setReload(true)}
              >
                <i className="fa fa-redo"></i>
              </button>
              {showButtons && (
                <>
                  <button
                    className="btn btn-dark"
                    title="Impressão"
                    onClick={(e) => setPrint(true)}
                    style={{ marginLeft: '3px' }}
                  >
                    <i className="fa fa-print"></i>
                  </button>
                  <button
                    className="btn btn-info"
                    disabled={!checkPermission(16, 23)}
                    title="Exportar para excel"
                    onClick={(e) => setExcel(true)}
                    style={{ marginLeft: '3px' }}
                  >
                    <i className="fa fa-file-excel"> </i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    title="Filtros Avançados"
                    style={{ marginLeft: '3px' }}
                    onClick={(e) => handleFilter()}
                  >
                    <i className="fa fa-search-plus"></i>
                  </button>
                </>
              )}

              {/* <button
                className="btn btn-theme"
                title="Mineração de Curriculuns"
                onClick={(e) => handleMiner()}
                style={{ marginLeft: '3px' }}
              >
                <i className="fa fa-envelope-open"></i>
              </button> */}
              <button
                className="btn btn-theme"
                title="Mineração de Curriculuns"
                onClick={(e) => {
                  window.addTab(
                    e,
                    'Emails',
                    'fa fa-envelope-open',
                    '/email-list',
                    {},
                    1,
                  );
                }}
                style={{ marginLeft: '3px' }}
              >
                <i className="fa fa-envelope-open"></i>
              </button>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              borderLeft: '1px #cacaca dashed',
              paddingLeft: '15px',
              gap: '15px',
            }}
          >
            <div
              className="box-shadow container"
              style={{
                width: '110px',
                marginTop: isSmallScreen ? '45px' : '0px',
              }}
            >
              <div className="row justify-content-center p-2">
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <span>(%) Aproveitamento</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '5px 0px',
                    }}
                  >
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {utillization}%
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-4 mb-3">
                  <label>Exibir somente</label>
                  <select
                    className="form-select"
                    value={statusCandidate}
                    onChange={(e) => setStatusCandidate(e.target.value)}
                    style={{ width: 'calc(100% + 5px)' }}
                  >
                    <option value="">Todos</option>
                    {statusCandidateData.map((_item) => (
                      <option
                        key={_item.idstatuscandidate}
                        value={_item.idstatuscandidate}
                      >
                        {_item.statuscandidate}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-xl-4 mb-3">
                  <label>Data Inicial</label>
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    style={{ width: 'calc(100% + 5px)' }}
                  />
                </div>
                <div className="col-12 col-xl-4 mb-3">
                  <label>Data Final</label>
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    style={{ width: 'calc(100% + 5px)' }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                justifyContent: 'center',
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <label
                  className="switch switch-sm"
                  style={{ justifyContent: 'center' }}
                >
                  <input
                    type="checkbox"
                    checked={principalIsentEmpty}
                    value={principalIsentEmpty}
                    onClick={(e) =>
                      setPrincipalIsentEmpty(!principalIsentEmpty)
                    }
                  />
                  <span></span>
                </label>
                <span>Exibir somente com dados principais preenchidos</span>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <label
                  className="switch switch-sm"
                  style={{ justifyContent: 'center' }}
                >
                  <input
                    type="checkbox"
                    checked={repetedData}
                    value={repetedData}
                    onClick={(e) => setRepetedData(!repetedData)}
                  />
                  <span></span>
                </label>
                <span>Não apresentar dados repetidos</span>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <label
                  className="switch switch-sm"
                  style={{ justifyContent: 'center' }}
                >
                  <input
                    type="checkbox"
                    checked={crs}
                    value={crs}
                    onClick={(e) => setCRS(!crs)}
                  />
                  <span></span>
                </label>
                <span>Retirar reicidentes do CRS</span>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                className="btn btn-success"
                type="button"
                onClick={(e) => changeFilter()}
              >
                <i className="fa fa-magnifying-glass" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`content-box-cart ${loading ? 'hidden' : ''}`}>
        <Filter
          isOpenFilter={isOpenFilter}
          filters={gridParams}
          callback={setFilters}
          callBackOpenFilter={handleFilter}
          externalFilters={filters}
          reset={reset}
          setReset={setReset}
        />
        <Grid
          url="/candidate"
          headers={gridParams}
          loading={setLoading}
          reload={reload}
          print={print}
          excel={excel}
          filters={filters}
          callbackShowButtons={setShowButtons}
          callbackPrint={setPrint}
          callbackReload={setReload}
          callbackExcel={setExcel}
          callbackCellClick={editPermission ? undefined : EditCandidate}
          filename="Mineração de Curriculums"
          callbackButtons={[
            RemoveCandidate,
            EditCandidate,
            PDFDownload,
            processLine,
            approved,
            error,
          ]}
        />
      </div>
    </ContentWrapper>
  );
}
