import React, {useEffect} from 'react'

import { ValidationForm,  } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';
import { format } from 'date-fns'

import api from '../../services/api'

import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalClientTerms({showModal, handleCloseModal, data}) {
    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#printer-terms'),document.querySelector('#printer-terms').children[0].classList,document.querySelector('#printer-terms').lastChild.nodeValue)
        try {
            await api.post('/client/print-terms/' + data[0].idpeople)
                     .then(response => {
                        const downloadLink = document.createElement("a");
                        const fileName = data[0].name + " - TERMO.pdf";
                        downloadLink.href = "data:application/pdf;base64," + response.data[0].signature;
                        downloadLink.download = fileName;
                        downloadLink.click();
                     })
                     .catch( error => {
                        ShowMessages(error)
                     })
            loadingButton(false,document.querySelector('#printer-terms'),'fa fa-print','Imprimir Termo')
            
        } catch (response) {
            loadingButton(false,document.querySelector('#printer-terms'),'fa fa-print','Imprimir Termo')
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        setStatistic('modals\\modal-clients-terms')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} className="modal-content" setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-signature"></i> Histórico de Assinaturas
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div style={{display:"flex",gap:"5px", flexDirection:"column", maxHeight:"300px", overflowX:"auto", padding:"10px"}}>   
                        {data.map(_item => {
                            return (
                                <div className='box-shadow' style={{display:"flex", alignItems:"center", justifyContent:"center", maxHeight:"100px", minHeight:"100px"}}>
                                    <img style={{width:"250px", maxHeight:"90px"}} src={_item.signature} alt=""/>
                                    <div style={{position:"relative", bottom:"-40px", right:"0"}}>
                                        <span>{format(new Date(_item.create_at),'dd/MM/yyyy HH:mm:ss')}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="printer-terms" className={`btn-theme`}>
                        <em className="fa fa-print"></em> Imprimir Termo
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}