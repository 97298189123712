import { useState } from 'react';
import { Button } from 'react-bootstrap';
import TitleToggle from '../../components/TitleToggle';
import ModalClient from '../modals/modal-search-client';

import CteAdditionalInformations from '../../components/Invoices/cte/adittional-informations';
import CteCargoInformation from '../../components/Invoices/cte/cargo-information';
import CteCharges from '../../components/Invoices/cte/charges';
import CteDocumentInformation from '../../components/Invoices/cte/document-information';
import CteGeneralInformation from '../../components/Invoices/cte/general-information';
import CteIcms from '../../components/Invoices/cte/icms';
import CteIcmsSharing from '../../components/Invoices/cte/icms-sharing';
import CteInstallments from '../../components/Invoices/cte/installments';
import CteInvoices from '../../components/Invoices/cte/invoices';
import CtePayment from '../../components/Invoices/cte/payments';
import CteRoad from '../../components/Invoices/cte/road';
import CteTransportDocuments from '../../components/Invoices/cte/transport-documents';

import { FormProvider, useForm } from 'react-hook-form';
import { defaultValuesCte } from '../../utils/defaultValues';

export default function CreateCTe() {
    const methods = useForm({
        mode: 'all',
        defaultValues: defaultValuesCte,
    });
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = (data) => {
        console.log('🚀 ~ file: create-nfe.js ~ line 101 ~ handleSubmit ~ e', data);
    };

    return (
        <div style={{ padding: '16px', overflowY: 'scroll', maxHeight: '80vh' }}>
            <ModalClient showModal={showModal} handleCloseModal={handleCloseModal} />
            {/* DADOS DO CT-e */}
            <h2>Dados do CT-e</h2>

            {/* INFORMAÇÔES GERAIS */}
            <FormProvider {...methods}>
                <form id="form-nfe" onSubmit={methods.handleSubmit((data) => handleSubmit(data))}>
                    <TitleToggle title={'Informações gerais'} marginTop="32px">
                        <CteGeneralInformation />
                    </TitleToggle>

                    {/* INFORMAÇÕES DE CARGA */}
                    <TitleToggle title={'Informações de carga'} marginTop="32px">
                        <CteCargoInformation />
                    </TitleToggle>

                    {/* RODOVIÁRIO */}
                    <TitleToggle title="Rodoviário" marginTop="32px">
                        <CteRoad />
                    </TitleToggle>

                    {/* INFORMAÇÔES DE DOCUMENTOS */}
                    <TitleToggle title={'Informações de documentos'} marginTop="32px">
                        <CteDocumentInformation />
                    </TitleToggle>

                    {/* DOCUMENTOS DE TRANSPORTE ANTERIOR */}
                    <TitleToggle title="Documentos de transporte anterior" marginTop="32px">
                        <CteTransportDocuments />
                    </TitleToggle>

                    {/* COBRANÇAS */}
                    <TitleToggle title="Cobranças" marginTop="32px">
                        <CteCharges />
                    </TitleToggle>

                    {/* ICMS */}
                    <TitleToggle title="ICMS" marginTop="32px">
                        <CteIcms />
                    </TitleToggle>

                    {/* Partilha do ICMS */}
                    <TitleToggle title="Partilha do ICMS" marginTop="32px">
                        <CteIcmsSharing />
                    </TitleToggle>

                    {/* INFORMAÇÕES ADICIONAIS */}
                    <TitleToggle title="Informações adicionais" marginTop="32px">
                        <CteAdditionalInformations />
                    </TitleToggle>

                    {/* FATURAS */}
                    <TitleToggle title="Faturas" marginTop="32px">
                        <CteInvoices />
                    </TitleToggle>

                    {/* PRESTAÇÕES */}
                    <TitleToggle title="Prestações" marginTop="32px">
                        <CteInstallments />
                    </TitleToggle>

                    {/* PAGAMENTOS */}
                    <TitleToggle title="Pagamentos" marginTop="32px">
                        <CtePayment />
                    </TitleToggle>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '16px',
                            marginTop: '32px',
                        }}
                    >
                        <Button type="submit" id="submit-client" className={`btn-theme`}>
                            <em className="fa fa-save" /> Salvar
                        </Button>
                        <Button id="submit-client" className="btn-warning">
                            <em className="fa-solid fa-paper-plane" /> Emitir
                        </Button>
                        <Button className="btn-ligth-gray">
                            <em className="fa fa-door-closed" /> Fechar
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
