import socketIOClient from "socket.io-client";
import { getUserData } from './auth'

const ENDPOINT = process.env.REACT_APP_IP_CHAT + ':' + process.env.REACT_APP_PORT_CHAT;

export const socket = getUserData() ? ((process.env.REACT_APP_ENABLED_CHAT === true) ? socketIOClient(ENDPOINT, {query : {room      : 110,
                                                                                                                          userid    : getUserData()['iduser'],
                                                                                                                          name      : getUserData()['name'],
                                                                                                                          pathphoto : getUserData()['pathphoto'], }}) : null) : null;

if (socket && getUserData())
    socket.on('connect', data => {
        console.log('conectado...')
        socket.emit('getInfo')
    })
