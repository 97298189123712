import { memo, useState } from 'react';
import { Col, Container, Row, FormSelect } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import api from '../../services/api';
import ShowMessages from '../Exceptions';

const colourOptions = [
  { label: 'Azul', value: 'blue' },
  { label: 'Vermelho', value: 'red' },
  { label: 'Preto', value: 'black' },
  { label: 'Rosa', value: 'pink' },
  { label: 'Verde', value: 'green' },
  { label: 'Amarelo', value: 'yellow' },
];

const Providers = () => {
  const { control, register } = useFormContext();
  const [fornecedores, setFornecedores] = useState([
    {
      label: 'Alguma coisa',
      value: 10,
    },
    {
      label: 'Alguma coisa 2',
      value: 11,
    },
    {
      label: 'Alguma coisa 3',
      value: 12,
    },
  ]);

  const { fields, append, remove } = useFieldArray({
    name: 'providers.providers', // unique name for your Field Array
  });

  const filterColors = (inputValue) => {
    return colourOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };
  const loadOptions = async (inputValue, callback) => {
    try {
      await api
        .get('/getProvider')
        .then((response) => {
          if (response.ok) {
            console.log(response);
          }
        })
        .catch((error) => {
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  return (
    <Container fluid>
      {fields.map((field, index) => (
        <Row key={field.id} className="d-flex align-items-center">
          <Col
            sm={1}
            md={1}
            lg={1}
            className="badge text-bg-primary align-self-end"
            style={{ width: '30px' }}
          >
            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
          </Col>
          <Col md={6} lg={6} className="d-flex align-items-end mr-2">
            <div className="input-group">
              <label>Produto/Código</label>
              <FormSelect
                {...register(`providers.providers.${index}.provider`)}
                className={'w-100'}
              >
                <option value="">Selecione</option>
                {fornecedores?.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.value} - {item.label}
                    </option>
                  );
                })}
              </FormSelect>
            </div>
          </Col>
          <Col sm={1} md={1} lg={1} className="align-self-end">
            <button className="btn btn-danger" onClick={() => remove(index)}>
              <i className="fa-solid fa-trash" />
            </button>
          </Col>
        </Row>
      ))}
      <div className="my-2">
        <button
          type="button"
          title="adicionar outro fornecedor"
          className="btn btn-success font-weight-bold"
          onClick={() => append({ provider: '' })}
        >
          <i className="fa-solid fa-plus" />
        </button>
      </div>
    </Container>
  );
};

export default memo(Providers);
