import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalBillsToPay from '../modals/modal-billstopay';
import ModalBillsToPayEdit from '../modals/modal-billstopay-edit';
import ModalBillsToPayDown from '../modals/modal-billstopay-down';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import { format,endOfWeek, startOfWeek  }        from 'date-fns'
import Swal from 'sweetalert2'

export default function BillsToPay() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalEdit, setShowModalEdit]                = useState(false)
    const [showModalDown, setShowModalDown]                = useState(false)

    const [fieldSearch, setFieldSearch]                    = useState('duedate')
    const [startDate, setStartDate]                        = useState(format(startOfWeek (new Date()),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(endOfWeek (new Date()),'yyyy-MM-dd'))

    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])

    const [editPermission, setEditPermisson]               = useState(false)

    const getBillsToPay = async() => {
        await api.get('/invoice-status/0')
                 .then(response => {
                     let _delCheck    = !checkPermission(4,12)
                     let _editCheck   = !checkPermission(8,12)
                     let _cancelCheck = !checkPermission(64,12)

                     setEditPermisson(_editCheck)
                     setGridParams({idinstallmentpayable : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                         value : '00000'},
                                                            primaryKey  : true},          
                                    name                 : {text        : "Fornecedor" ,
                                                            searchDefault : true},
                                    duedate              : {text        : "Vencimento",
                                                            dataType    : "date",
                                                            className   : "text-right",
                                                            item         : {className   : "text-right"}},
                                    parcel               : {text        : "Parcela"},
                                    amount               : {text        : "Valor",
                                                            className   : "text-right",
                                                            item        : {className   : "text-right"}},
                                    kindofpayment        : {text        : "Espécie"},   
                                    lastpaymentdate      : {text        : "Pagamento",
                                                            dataType    : "date",},
                                    amountpaid           : {text        : "Valor Pago",
                                                            className   : "text-right",
                                                            item        : {className   : "text-right"}},        
                                    balancetopay         : {text        : "Saldo à Pagar",
                                                            className   : "text-right",
                                                            item        : {className   : "text-right"}},  
                                    invoicestatus        : {text        : "Status",
                                                            item        : {className : "text-center",
                                                                           switch      : {data  : response.data,
                                                                                          key   : 'idinvoicestatus'}}},
                                    Options              : {text        : "Opções",
                                                            className   : "text-center",
                                                            order       : false,
                                                            filter      : false,
                                                            item        : [{type  : "Custom",
                                                                            props : {tag            : "button",
                                                                                     title          : "Exclusão de Parcela",
                                                                                     className      : 'btn btn-sm btn-danger',
                                                                                     icon           : "fa fa-trash",
                                                                                     disabled       : _delCheck,
                                                                                     message        : "Deseja realmente excluir a parcela?",
                                                                                     visualConditional : {idinvoicestatus : {operator : 'not in',
                                                                                                                             values : [2,3,4]}},
                                                                                     indexcallback  : 0}},
                                                                           {type  : "Custom",
                                                                            props : {tag            : "button",
                                                                                     title          : "Editar Parcela",
                                                                                     className      : 'btn btn-sm btn-warning',
                                                                                     icon           : "fa fa-edit",
                                                                                     disabled       : _editCheck,
                                                                                     visualConditional : {idinvoicestatus : {operator : 'not in',
                                                                                                                             values : [2,3,4,5]}},
                                                                                     indexcallback  : 1}},
                                                                           {type  : "Custom",
                                                                            props : {tag            : "button",
                                                                                     title          : "Baixar Parcela",
                                                                                     className      : 'btn btn-sm btn-success padding0',
                                                                                     icon           : "fa fa-check-square",
                                                                                     visualConditional : {idinvoicestatus : {operator : 'not in',
                                                                                                                             values : [2,4,5]}},
                                                                                     indexcallback  : 2}},
                                                                           {type  : "Custom",
                                                                            props : {tag            : "button",
                                                                                     title          : "Imprimir Recibo",
                                                                                     className      : 'btn btn-sm btn-dark padding0',
                                                                                     icon           : "fa fa-receipt",
                                                                                     visualConditional : {idinvoicestatus : {operator : 'in',
                                                                                                                             values : [2,3,4]}},
                                                                                     indexcallback  : 3}},
                                                                           {type  : "Custom",
                                                                            props : {tag            : "button",
                                                                                     title          : "Cancelar Parcela",
                                                                                     className      : 'btn btn-sm btn-danger padding0',
                                                                                     disabled       : _cancelCheck,
                                                                                     message        : "Deseja realmente cancelar a parcela?",
                                                                                     icon           : "fa fa-ban margintop9",
                                                                                     visualConditional : {idinvoicestatus : {operator : 'not in',
                                                                                                                             values : [2,3,4,5]}},
                                                                                     indexcallback  : 4}}]}                        
                             })
                        }).catch(error => {
                            ShowMessages(error)
                        })
    }

    const handleSearch = async() => {
        let _filters = [{ field      : fieldSearch,
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true    
                        },
                        ]
        setFilters(_filters)
    }

    const handleCloseModal     = () => {setShowModal(false)}
    const handleCloseModalEdit = () => {setShowModalEdit(false)}
    const handleCloseModalDown = () => {setShowModalDown(false)}
    const handleFilter         = () => {setIsOpenFilter(!isOpenFilter)}

    const RemoveBillsToPay = async (id) => { 
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir a parcela?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/bills-to-pay/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        })     
    }

    const EditBillsToPay = async(id) => {
        await api.get('/bills-to-pay/' + id)
                 .then(response => {
                    setData(response.data[0])
                    setShowModalEdit(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const DownBillsToPay = async(id) => {
        await api.get('/bills-to-pay/' + id)
                 .then(response => {
                    setData(response.data[0])
                    setShowModalDown(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const PrintBillsToPay = async(id) => {
        await api.get('/bills-to-pay/' + id)
                 .then(response => {
                    setData(response.data)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const CancelBillsToPay = async (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente cancelar a parcela?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                let _data = {
                    id,
                    status : 5
                }

                await api.post('/bills-to-pay/status',_data)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        })  
    }

    useEffect(() => {
        getBillsToPay()
        handleSearch()
        setStatistic('maintenance\\billstopay')
    },[])

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalBillsToPay showModal={showModal} handleCloseModal = {handleCloseModal}  handleReload={setReload}/>
            <ModalBillsToPayEdit showModal={showModalEdit} handleCloseModal = {handleCloseModalEdit}  data={data} handleReload={setReload}/>
            <ModalBillsToPayDown showModal={showModalDown} handleCloseModal = {handleCloseModalDown}  data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={!checkPermission(2,6)} title="Insere um novo registro" onClick={e => { setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,6)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                    <div style={{display:"flex",marginLeft:"15px"}}>
                        <div>
                            <span>Campo de Pesquisa</span>
                            <select className="form-select" value={fieldSearch} onChange={e => setFieldSearch(e.target.value)}>
                                <option value='create_at'>Data Lançamento</option>
                                <option value='duedate'>Data Vencimento</option>
                                <option value='lastpaymentdate'>Data Pagamento</option>
                            </select>
                        </div>
                        <div style={{marginLeft:"15px", width:"135px"}}>
                            <span>Data Inicial</span>
                            <input type="date" className='form-control' value={startDate} onChange={e => setStartDate(e.target.value)}/>
                        </div>
                        <div style={{marginLeft:"15px", width:"135px"}}>
                            <span>Data Final</span>
                            <input type="date" className='form-control' value={endDate} onChange={e => setEndDate(e.target.value)}/>
                        </div>
                    </div>
                    <div style={{marginLeft:"15px", display:"flex",alignItems:"center"}}>
                        <button className="btn btn-success" title="Clique aqui para pesquisar" onClick={e => handleSearch(e)}><i className="fa fa-search"/></button>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/bills-to-pay' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      filename            = "Contas a Pagar"
                      callbackCellClick   = {editPermission ? undefined : EditBillsToPay}
                      callbackButtons     = {[RemoveBillsToPay, 
                                              EditBillsToPay,
                                              DownBillsToPay,
                                              PrintBillsToPay,
                                              CancelBillsToPay]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}