import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NFECFOP = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            {/* LINHA 1 */}
            <Row className="mt-2">
                <Col lg={7} md={7} xs={12}>
                    <label>Venda consumidor de NFC-e</label>
                    <div className="input-group">
                        <FormControl
                            {...register('fiscalTributary.nfceParameters.cfop.consumerSale')}
                            name="fiscalTributary.nfceParameters.cfop.consumerSale"
                            id="fiscalTributary.nfceParameters.cfop.consumerSale"
                            type="number"
                            minLength={0}
                            maxLength={4}
                            className="form-control "
                            autoComplete="no"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NFECFOP);
