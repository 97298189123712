import { Alert, Button } from 'react-bootstrap';
import MdfeDrivers from '../../components/Invoices/mdfe/drivers';
import MdfeGeneralInformation from '../../components/Invoices/mdfe/general-informations';
import MdfeHirers from '../../components/Invoices/mdfe/hirers';
import MdfeInsuranceInformation from '../../components/Invoices/mdfe/insurance-informations';
import MdfeLoadingInformation from '../../components/Invoices/mdfe/loading-informations';
import MdfeProductInformation from '../../components/Invoices/mdfe/product-information';
import MdfeRouteInformation from '../../components/Invoices/mdfe/route-informations';
import MdfeTollVouchers from '../../components/Invoices/mdfe/toll-vouchers';
import MdfeTotalizers from '../../components/Invoices/mdfe/totalizers';
import MdfeTows from '../../components/Invoices/mdfe/tows';
import MdfeTransportOperationCodes from '../../components/Invoices/mdfe/transport-operation-codes';
import MdfeTransportationRoadInformation from '../../components/Invoices/mdfe/transportation-road-information';
import MdfeUnloadingInformation from '../../components/Invoices/mdfe/unloading-informations';

import { FormProvider, useForm } from 'react-hook-form';
import TitleToggle from '../../components/TitleToggle';
import { defaultValuesMdfe } from '../../utils/defaultValues';

export default function CreateMDFe() {
    const methods = useForm({
        mode: 'all',
        defaultValues: defaultValuesMdfe,
    });

    const handleSubmit = (data) => {
        console.log('🚀 ~ file: create-nfe.js ~ line 101 ~ handleSubmit ~ e', data);
    };

    return (
        <div style={{ padding: '16px', overflowY: 'scroll', maxHeight: '80vh' }}>
            <h2>Dados do MDF-e</h2>
            <FormProvider {...methods}>
                <form id="form-nfe" onSubmit={methods.handleSubmit((data) => handleSubmit(data))}>
                    {/* INFORMAÇÕES GERAIS */}
                    <TitleToggle title="Informações gerais" marginTop="32px">
                        <MdfeGeneralInformation />
                    </TitleToggle>

                    {/* INFORMAÇÕES DO PRODUTO PREDOMINANTE */}
                    <TitleToggle title="Informações do produto predominante" marginTop="32px">
                        <MdfeProductInformation />
                    </TitleToggle>

                    {/* INFORMAÇÕES DE CARREGAMENTO */}
                    <TitleToggle title="Informações de carregamento" marginTop="32px">
                        <MdfeLoadingInformation />
                    </TitleToggle>

                    {/* INFORMAÇÕES DE PERCURSO */}
                    <TitleToggle title="Informações de percurso" marginTop="32px">
                        {/* LINHA 1 */}
                        <div style={{ width: '100%' }}>
                            <Alert variant="warning">
                                <Alert.Heading>
                                    Atenção! Não é necessário repetir as UFs de carregamento e descarregamento!
                                </Alert.Heading>
                                <p>
                                    Se há mais de um estado neste trajeto, então deverá ser informado exatamente na
                                    ordem em que o veículo seguirá.
                                </p>
                                <p>
                                    Exemplo: Origem RS e Destino RJ, logo, as UFs de percurso deverão ser SC, PR, SP,
                                    Extamente nesta ordem.
                                </p>
                            </Alert>
                        </div>
                        <MdfeRouteInformation />
                    </TitleToggle>

                    {/* INFORMAÇÕES DE DESCARREGAMENTO */}
                    <TitleToggle title="Informações de descarregamento" marginTop="32px">
                        <MdfeUnloadingInformation />
                    </TitleToggle>

                    {/* TOTALIZADORES */}
                    <TitleToggle title="Totalizadores" marginTop="32px">
                        <MdfeTotalizers />
                    </TitleToggle>

                    {/* INFORMAÇÔES DO MODAL RODOVIÁRIO */}
                    <TitleToggle title="Informações do modal rodoviário" marginTop="32px">
                        <MdfeTransportationRoadInformation />
                    </TitleToggle>

                    {/* CONDUTORES */}
                    <TitleToggle title="Condutores" marginTop="32px">
                        <MdfeDrivers />
                    </TitleToggle>

                    {/* REBOQUE */}
                    <TitleToggle title="Reboque" marginTop="32px">
                        <MdfeTows />
                    </TitleToggle>

                    {/* CÓDIGO IDENTIFICADOR DA OPERAÇÃO DE TRANSPORTE */}
                    <TitleToggle title="Código identificador da operação de transporte" marginTop="32px">
                        <MdfeTransportOperationCodes />
                    </TitleToggle>

                    {/* CONTRATANTE */}
                    <TitleToggle title="Contratante" marginTop="32px">
                        <MdfeHirers />
                    </TitleToggle>

                    {/* VALE PEDÁGIO */}
                    <TitleToggle title="Vale pedágio" marginTop="32px">
                        <MdfeTollVouchers />
                    </TitleToggle>

                    {/* INFORMAÇÕES DE SEGURO */}
                    <TitleToggle title="Informações de seguro" marginTop="32px">
                        <MdfeInsuranceInformation />
                    </TitleToggle>

                    {/* INFORMAÇÕES DE PAGAMENTO DO FRETE */}
                    <TitleToggle title="Informações de pagamento do frete" subtitle={'(opcional)'} marginTop="32px">
                        {/* LINHA 1 */}
                    </TitleToggle>

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'left',
                            gap: '16px',
                            marginTop: '16px',
                        }}
                    >
                        <Button type="submit" id="submit-client" className={`btn-theme`}>
                            <em className="fa fa-save" /> Salvar
                        </Button>
                        <Button id="submit-client" className="btn-warning">
                            <em className="fa-solid fa-paper-plane" /> Emitir
                        </Button>
                        <Button className="btn-ligth-gray">
                            <em className="fa fa-door-closed" /> Fechar
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
