import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { checkPermission } from '../../components/security'
import { Dropdown, DropdownButton, FormControl } from 'react-bootstrap'
import Swal from 'sweetalert2'

export default function Budget() {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [reload, setReload] = useState(false)
    const [print, setPrint] = useState(false)
    const [excel, setExcel] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const [editState, setEditState] = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [data, setData] = useState({})
    const [filters, setFilters] = useState([])

    const [editPermission, setEditPermisson] = useState(false)
    const getBudget = async () => {
        let _delCheck  = !checkPermission(4, 18)
        let _editCheck = !checkPermission(8, 18)
        setEditPermisson(_editCheck)
        setGridParams({
            idbudget : {text: "#ID", mask: {
                            name: 'leftSlice',
                            value: '00000'
                             },
                        primaryKey: true},
            create_at: {
                        text: "Data de emissão",
                        dataType: "date"
                    },
            people: {
                        text: "Cliente"
                    },
            keytag: {
                        text: "Placa"
                    },
            total : {text : 'Valor'},
            status: {
                        text: "Status"
                    },
            Options: {
                        text: "Opções",
                        className: "text-center",
                        order: false,
                        filter: false,
                        item: [{
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Exclusão de Orçamento",
                                className: 'btn btn-sm btn-danger',
                                icon: "fa fa-trash",
                                disabled: _delCheck,
                                message: "Deseja realmente excluir o orçamento?",
                                indexcallback: 0
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Editar o Orçamento",
                                disabled: _editCheck,
                                className: 'btn btn-sm btn-warning',
                                visualConditional: {
                                    idpeoplestatus: {
                                        operator: 'not in',
                                        values: [3, 5]
                                    }
                                },
                                icon: "fa fa-edit",
                                indexcallback: 1
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Cancelar o Orçamento",
                                disabled: _editCheck,
                                className: 'btn btn-sm btn-danger',
                                visualConditional: {
                                    idpeoplestatus: {
                                        operator: 'not in',
                                        values: [3, 5]
                                    }
                                },
                                icon: "fa fa-ban",
                                indexcallback: 1
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Imprimir o Orçamento",
                                disabled: _editCheck,
                                className: 'btn btn-sm btn-dark',
                                visualConditional: {
                                    idpeoplestatus: {
                                        operator: 'not in',
                                        values: [3, 5]
                                    }
                                },
                                icon: "fa fa-print",
                                indexcallback: 1
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Aprovar o Orçamento",
                                disabled: _editCheck,
                                className: 'btn btn-sm btn-success',
                                visualConditional: {
                                    idpeoplestatus: {
                                        operator: 'not in',
                                        values: [3, 5]
                                    }
                                },
                                icon: "fa fa-file-circle-check",
                                indexcallback: 1
                            }
                        },
                        {
                            type: "Custom",
                            props: {
                                tag: "button",
                                title: "Faturar o Orçamento",
                                disabled: _editCheck,
                                className: 'btn btn-sm btn-info',
                                visualConditional: {
                                    idpeoplestatus: {
                                        operator: 'not in',
                                        values: [3, 5]
                                    }
                                },
                                icon: "fa fa-file-invoice",
                                indexcallback: 1
                            }
                        }]
                    }
                })
    }

    useEffect(() => {
        getBudget()
        setStatistic('maintenance\\budget')
    }, [])

    const handleCloseModal = () => { setShowModal(false) }
    const handleFilter = () => { setIsOpenFilter(!isOpenFilter) }

    const RemoveBudget = async (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o orçamento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/budget/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                        })
            }
            
        }) 
    }

    const EditBudget = async (id) => {
        await api.get('/budget/' + id)
            .then(response => {
                setData(response.data)
                setEditState(true)
                setShowModal(true)
            })
            .catch(error => {
                ShowMessages(error)
            })
    }

    return (
        <ContentWrapper>
            <Loading loading={loading} />
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={false} title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                               setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{ marginLeft: "3px" }} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ?
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{ marginLeft: "3px" }}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16, 4)} title="Exportar para excel" onClick={e => setExcel(true)} style={{ marginLeft: "3px" }}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{ marginLeft: "3px" }} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                                : ""}

                        </div>
                    </div>
                    <div className='px-2'>
                        <label className='mb-1'>Data Inicial</label>
                        <div className='input-group'>
                            <FormControl type={'date'} />
                        </div>
                    </div>
                    <div className='px-2'>
                        <label className='mb-1'>Data Final</label>
                        <div className='input-group'>
                            <FormControl type={'date'} />
                        </div>
                    </div>
                    <div className='px-2'>
                        <label className='mb-1'>Placa</label>
                        <div className='input-group'>
                            <FormControl type={'text'} />
                        </div>
                    </div>
                    <div className='px-2'>
                        <button className='btn btn-success'><i className="fa-solid fa-magnifying-glass"></i></button>
                    </div>

                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter} />
                <Grid url='/budget'
                    headers={gridParams}
                    loading={setLoading}
                    reload={reload}
                    print={print}
                    excel={excel}
                    filters={filters}
                    callbackShowButtons={setShowButtons}
                    callbackPrint={setPrint}
                    callbackReload={setReload}
                    callbackExcel={setExcel}
                    filename="Orçamentos"
                    callbackCellClick={editPermission ? undefined : EditBudget}
                    callbackButtons={[RemoveBudget,
                                      EditBudget]}
                />

            </div>
        </ContentWrapper>
    )
}