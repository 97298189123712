import React, { useState, useEffect } from 'react';

import {
  ValidationForm,
  TextInput,
  SelectGroup,
} from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal, Alert, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import MaskWithValidation from '../../components/maskInput';
import MaskedInput from 'react-text-mask';
import Loading from '../../components/Loading';
import TooltipItem from '../../components/tooltip';
import CEP from 'cep-promise';
import setStatistic from '../../services/apiStatistic';

import api from '../../services/api';

import { IDCOMPANY, ReadDataStorage } from '../../services/auth';
import loadingButton from '../layouts/Buttons';

import consultarCNPJ from 'consultar-cnpj';
import '../../styles/app/common/label.scss';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalProvider({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingButtonCNPJ, setLoadingButtonCNPJ] = useState(false);
  const [tabActive, setTabActive] = useState(0);

  const [messagePhone, setMessagePhone] = useState('');
  const [messageContact, setMessageContact] = useState('');

  const [dataPeopleStatus, setDataPeopleStatus] = useState([]);
  const [dataContactType, setDataContactType] = useState([]);
  const [dataPhoneType, setDataPhoneType] = useState([]);
  const [dataPhoneOperator, setDataPhoneOperator] = useState([]);
  const [dataKindOfPerson, setDataKindOfPerson] = useState([]);

  const [contactType, setContactType] = useState('');
  const [contactValue, setContactValue] = useState('');

  const [CPF, setCPF] = useState('');
  const [rg, setRG] = useState('');
  const [municipalRegistration, setMunicipalRegistration] = useState('');
  const [name, setName] = useState('');
  const [fantasy, setFantasy] = useState('');
  const [kindofperson, setKindofperson] = useState(1);
  const [idpeoplestatus, setIdpeoplestatus] = useState(-1);
  const [comments, setComments] = useState('');

  const [cep, setCEP] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUF] = useState('');

  const [phoneType, setPhoneType] = useState('');
  const [phoneOperator, setPhoneOperator] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneComments, setPhoneComments] = useState('');
  const [phones, setPhones] = useState([]);

  const [contacts, setContacts] = useState([]);

  const checkAdressess = () => {
    if (
      cep === '' ||
      city === '' ||
      neighborhood === '' ||
      number === '' ||
      street === '' ||
      uf === '' ||
      phones.length <= 0
    ) {
      if (phones.length <= 0)
        setMessagePhone('Por favor, informe um número de telefone!');
      else setMessagePhone('');
      document.querySelector('#address-phones').style.color = '#dc3545';
      document.querySelector('#address-phones').parentElement.click();
      return false;
    } else document.querySelector('#address-phones').style.color = '#656565';

    return true;
  };

  const checkContacts = () => {
    if (contacts.length <= 0) {
      setMessageContact('Por favor, informe um email válido.');
      document.querySelector('#other-contacts').style.color = '#dc3545';
      document.querySelector('#other-contacts').parentElement.click();
      return false;
    } else {
      if (!contacts.find((el) => el.idtype === 0)) {
        setMessageContact('Por favor, informe um email válido.');
        document.querySelector('#other-contacts').style.color = '#dc3545';
        document.querySelector('#other-contacts').parentElement.click();
        return false;
      } else {
        document.querySelector('#other-contacts').style.color = '#656565';
      }
    }
    return true;
  };

  const checkRegisters = () => {
    if (
      ((name === '' || idpeoplestatus === '' || rg === '' || CPF === '') &&
        parseInt(kindofperson) === 0) ||
      ((name === '' ||
        fantasy === '' ||
        rg === '' ||
        CPF === '' ||
        rg === '' ||
        idpeoplestatus === '') &&
        parseInt(kindofperson) === 1)
    ) {
      document.querySelector('#personal-data').style.color = '#dc3545';
      document.querySelector('#personal-data').parentElement.click();
      return false;
    } else {
      document.querySelector('#personal-data').style.color = '#656565';
    }
    return true;
  };

  const checkData = (event, formData, errorInputs) => {
    checkAdressess();
    checkContacts();
    checkRegisters();
  };

  const setData = (obj, clear) => {
    setTabActive(0);

    setMessagePhone('');
    setMessageContact('');

    setContactType('');
    setContactValue('');
    setCPF('');
    setRG('');
    setMunicipalRegistration('');

    if (obj.documents !== undefined)
      if (obj.documents.length > 0)
        obj.documents.map((item) => {
          if (item.idtype === 0 || item.idtype === 1)
            setCPF(clear ? '' : item.value);

          if (item.idtype === 2) setRG(clear ? '' : item.value);

          if (item.idtype === 13)
            setMunicipalRegistration(clear ? '' : item.value);
        });

    setKindofperson(clear ? 1 : obj.kindofperson);
    setName(clear ? '' : obj.name);
    setFantasy(clear ? '' : obj.fantasy);
    setIdpeoplestatus(clear ? -1 : obj.idpeoplestatus);
    setComments(clear ? '' : obj.comments ? obj.comments : '');

    setCEP(clear ? '' : obj.addresses.cep);
    setStreet(clear ? '' : obj.addresses.street);
    setNumber(clear ? '' : obj.addresses.number);
    setComplement(clear ? '' : obj.addresses.complement);
    setNeighborhood(clear ? '' : obj.addresses.neighborhood);
    setCity(clear ? '' : obj.addresses.city);
    setUF(clear ? '' : obj.addresses.uf);

    setPhoneType('');
    setPhoneOperator('');
    setPhone('');
    setPhoneComments('');
    setPhones(clear ? [] : obj.phones);

    setContacts(clear ? [] : obj.contacts);
  };

  const getAddress = (value) => {
    let _cep = value.replace(/\D/g, '');
    if (_cep.length === 8) {
      CEP(_cep)
        .then((data) => {
          setStreet(data.street);
          setCEP(value);
          if (data.street === '') {
            document.querySelector('#street').removeAttribute('disabled');
          } else {
            document.querySelector('#street').setAttribute('disabled', true);
          }

          setNeighborhood(data.neighborhood);
          if (data.neighborhood === '') {
            document.querySelector('#neighborhood').removeAttribute('disabled');
          } else {
            document
              .querySelector('#neighborhood')
              .setAttribute('disabled', true);
          }
          setUF(data.state);
          setCity(data.city);
        })
        .catch();
    } else {
      setStreet('');
      setNeighborhood('');
      setUF('');
      setCity('');
      document.querySelector('#street').setAttribute('disabled', true);
      document.querySelector('#neighborhood').setAttribute('disabled', true);
    }
  };

  const addPhone = () => {
    setMessagePhone('');
    if (phoneType !== '' && phoneOperator !== '' && phone !== '') {
      if (!phones.find((el) => el.phone === phone)) {
        setPhones((phones) =>
          phones.concat({
            idphonetype: phoneType,
            phonetype: dataPhoneType.find((el) => el.value === phoneType)[
              'label'
            ],
            idphoneoperator: phoneOperator,
            phoneoperator: dataPhoneOperator.find(
              (el) => el.value === phoneOperator,
            )['label'],
            phone: phone,
            comments: phoneComments,
          }),
        );
        setPhoneComments('');
        setPhoneOperator('');
        setPhoneType('');
        setPhone('');
      } else setMessagePhone('Telefone informado já está cadastrado!');
    } else {
      if (phoneType === '') {
        setMessagePhone('Por favor, informe o tipo do telefone!');
        return;
      }
      if (phoneOperator === '') {
        setMessagePhone('Por favor, informe a operadora do telefone!');
        return;
      }
      if (phone === '') {
        setMessagePhone('Por favor, informe um número de telefone!');
        return;
      }
    }
  };

  const editPhone = (phone) => {
    let _data = phones.find((el) => el.phone === phone);

    setPhoneComments(_data.comments);
    setPhoneOperator(_data.idphoneoperator);
    setPhoneType(_data.idphonetype);
    setPhone(_data.phone);

    removePhone(phone);
  };

  const removePhone = (phone) => {
    const _remove = phones.filter((t, i) => {
      if (t.phone !== phone) return true;

      return false;
    });
    setPhones(_remove);
  };

  const addContact = () => {
    setMessageContact('');

    if (contactType !== '' && contactValue !== '') {
      if (
        !contacts.find(
          (el) => el.contactType === contactType && el.value === contactValue,
        )
      ) {
        setContacts((prevContact) =>
          prevContact.concat({
            id:
              'contact' +
              new Date().getUTCMilliseconds() +
              (Math.floor(Math.random() * 10) + 1),
            idtype: contactType,
            type: dataContactType.find((el) => el.value === contactType)[
              'label'
            ],
            value: contactValue,
          }),
        );
        setContactType('');
        setContactValue('');
      } else setMessageContact('O contato informado já está cadastrado!');
    } else {
      if (contactType === '') {
        setMessageContact('Por favor, informe um tipo de contato.');
        return;
      }
      if (contactValue === '') {
        setMessageContact('Por favor, informe um contato.');
        return;
      }
    }
  };

  const removeContact = (id, value) => {
    const _remove = contacts.filter((t, i) => {
      if (t.id !== id && t.value !== value) return true;

      return false;
    });
    setContacts(_remove);
  };

  const editContact = (id, value) => {
    let _data = contacts.find((el) => el.id === id && el.value === value);
    setContactType(_data.idtype);
    setContactValue(_data.value);

    removeContact(id, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!checkRegisters()) return;

    if (!checkAdressess()) return;

    if (!checkContacts()) return;

    loadingButton(
      true,
      document.querySelector('#submit-provider'),
      document.querySelector('#submit-provider').children[0].classList,
      document.querySelector('#submit-provider').lastChild.nodeValue,
    );

    try {
      let idcompany = ReadDataStorage(IDCOMPANY);

      let _data = {
        name,
        CPF,
        rg,
        fantasy,
        kindofperson,
        municipalRegistration,
        idpeoplestatus,
        comments,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        uf,
        phones,
        contacts,
        idcompany,
      };

      if (editState)
        await api
          .put('/provider/' + data.idpeople, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-provider'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-provider'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-provider'));
            ShowMessages(error);
          });
      else
        await api
          .post('/provider', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-provider'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-provider'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-provider'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-provider'));
      ShowMessages(response);
    }
  };

  const handleKindOfPerson = async (value) => {
    setKindofperson(value);
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api
        .get('/getProviderData')
        .then((response) => {
          if (response.ok) {
            setDataPeopleStatus(response.data.peopleStatus);
            setDataContactType(response.data.contactType);
            setDataPhoneType(response.data.phonetype);
            setDataPhoneOperator(response.data.phoneoperator);
            setDataKindOfPerson(response.data.kindofperson);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  const getCNPJ = async () => {
    if (CPF.replace(/\D/g, '').length === 14) {
      setLoadingButtonCNPJ(true);
      try {
        const empresa = await consultarCNPJ(CPF, '');
        const estabelecimento = empresa.estabelecimento;

        console.log(empresa);
        setName(empresa.razao_social);
        setCEP(estabelecimento.cep);
        getAddress(estabelecimento.cep);
        setComplement(
          estabelecimento.complemento
            ? estabelecimento.complemento.replace(/  +/g, ' ')
            : '',
        );
        setNumber(estabelecimento.numero);
        setFantasy(
          estabelecimento.nome_fantasia === null
            ? empresa.razao_social
            : estabelecimento.nome_fantasia,
        );
        setRG(
          estabelecimento.inscricoes_estaduais.length > 0
            ? estabelecimento.inscricoes_estaduais[0].inscricao_estadual
            : '',
        );
        setLoadingButtonCNPJ(false);
      } catch (e) {
        setLoadingButtonCNPJ(false);
        ShowMessages(e);
      }
    } else ShowMessages({ message: 'CNPJ incompleto', status: 500 });
  };

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-provider');
  }, []);

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
  }, [data, editState, showModal]);

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogClassName="height550 width775"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
        onErrorSubmit={checkData}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-truck"></i> Propriedades do Fornecedor
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} addClass="heigth330px" />
          <div className={`${loading ? 'hidden' : ''}`}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={tabActive === 0 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(0);
                  }}
                >
                  <span id="personal-data">Dados Cadastrais</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 1 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(1);
                  }}
                >
                  <span id="address-phones">Endereços e Telefones</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 2 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(2);
                  }}
                >
                  <span id="other-contacts">Outros Contatos</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId={0}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 25 + '%' }}>
                    <label>Tipo de Pessoa</label>
                    <div>
                      <SelectGroup
                        name="idkindofperson"
                        id="idkindofperson"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um tipo de pessoa!',
                        }}
                        placeholder="Selecione"
                        className="form-select"
                        value={kindofperson}
                        onChange={(e) =>
                          handleKindOfPerson(parseInt(e.target.value))
                        }
                      >
                        {dataKindOfPerson.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                  {kindofperson === 1 ? (
                    <div
                      style={{
                        width: 33 + '%',
                        display: 'flex',
                        marginLeft: '15px',
                      }}
                    >
                      <div style={{ width: 100 + '%' }}>
                        <label className="required">CNPJ</label>
                        <div className="input-group">
                          <MaskedInput
                            name="cnpj"
                            id="cnpj"
                            maxLength={25}
                            value={CPF}
                            required
                            errorMessage={{
                              required: 'Por favor, informe um CNPJ!',
                            }}
                            className="form-control"
                            onChange={(e) => setCPF(e.target.value)}
                            autoComplete="off"
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              '.',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '.',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '/',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '-',
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          disabled={loadingButtonCNPJ ? 'disabled' : ''}
                          type="button"
                          onClick={(e) => getCNPJ()}
                          className="btn btn-sm btn-success"
                          title="Pesquisa CNPJ"
                          style={{ marginLeft: '5px', marginTop: '19px' }}
                        >
                          {loadingButtonCNPJ ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <i className="fa fa-search" />
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div style={{ width: 25 + '%', marginLeft: '15px' }}>
                    <label className="required">Status</label>
                    <div>
                      <SelectGroup
                        name="idpeoplestatus"
                        id="idpeoplestatus"
                        required
                        errorMessage={{
                          required: 'Por favor, informe um status!',
                        }}
                        placeholder="Selecione"
                        value={idpeoplestatus}
                        className="form-select"
                        onChange={(e) =>
                          setIdpeoplestatus(parseInt(e.target.value))
                        }
                      >
                        <option value="">Selecione</option>
                        {dataPeopleStatus.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                </div>
                {kindofperson === 0 ? (
                  <div>
                    <div>
                      <label className="required">Nome</label>
                      <div className="input-group">
                        <TextInput
                          name="name"
                          id="name"
                          type="text"
                          required
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe um nome!',
                          }}
                          value={name}
                          autoFocus={true}
                          className="form-control "
                          onChange={(e) => setName(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 25 + '%' }}>
                        <label className="required">CPF</label>
                        <div className="input-group">
                          <MaskedInput
                            name="cpf"
                            id="cpf"
                            maxLength={25}
                            value={CPF}
                            required
                            errorMessage={{
                              required: 'Por favor, informe um CPF!',
                            }}
                            className="form-control"
                            onChange={(e) => setCPF(e.target.value)}
                            autoComplete="off"
                            mask={[
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '.',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '.',
                              /[0-9]/,
                              /[0-9]/,
                              /[0-9]/,
                              '-',
                              /[0-9]/,
                              /[0-9]/,
                            ]}
                          />
                        </div>
                      </div>
                      <div style={{ width: 25 + '%', marginLeft: '15px' }}>
                        <label className="required">RG</label>
                        <div className="input-group">
                          <TextInput
                            name="rg"
                            id="rg"
                            type="text"
                            required
                            maxLength={100}
                            errorMessage={{
                              required: 'Por favor, informe um rg!',
                            }}
                            value={rg}
                            className="form-control "
                            onChange={(e) => setRG(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <label className="required">Razão Social</label>
                      <div className="input-group">
                        <TextInput
                          name="corporatename"
                          id="corporatename"
                          type="text"
                          required
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma razão social!',
                          }}
                          value={name}
                          autoFocus={true}
                          className="form-control "
                          onChange={(e) => setName(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="required">Nome Fantasia</label>
                      <div className="input-group">
                        <TextInput
                          name="fantasy"
                          id="fantasy"
                          type="text"
                          required
                          maxLength={100}
                          value={fantasy}
                          errorMessage={{
                            required: 'Por favor, informe um nome fantasia!',
                          }}
                          className="form-control "
                          onChange={(e) => setFantasy(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 33 + '%' }}>
                        <label className="required">Inscrição Estadual</label>
                        <div className="input-group">
                          <TextInput
                            name="rg"
                            id="rg"
                            type="text"
                            required
                            maxLength={100}
                            errorMessage={{
                              required:
                                'Por favor, informe uma inscrição estadual!',
                            }}
                            value={rg}
                            className="form-control "
                            onChange={(e) => setRG(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div style={{ width: 33 + '%', marginLeft: '15px' }}>
                        <label>Inscrição Municipal</label>
                        <div className="input-group">
                          <TextInput
                            name="municipalRegistration"
                            id="municipalRegistration"
                            type="text"
                            maxLength={100}
                            value={municipalRegistration}
                            className="form-control "
                            onChange={(e) =>
                              setMunicipalRegistration(e.target.value)
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <label>Observações</label>
                  <textarea
                    maxLength={2400}
                    className="form-control"
                    name="comments"
                    id="comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </TabPane>
              <TabPane tabId={1}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 17 + '%' }}>
                      <label className="required">CEP</label>
                      <div className="input-group">
                        <MaskWithValidation
                          name="cep"
                          id="cep"
                          type="text"
                          required
                          errorMessage={{
                            required: 'Por favor, informe um cep!',
                          }}
                          className="form-control "
                          value={cep}
                          onChange={(e) => getAddress(e.target.value)}
                          autoComplete="off"
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px', width: 64 + '%' }}>
                      <label>Endereço</label>
                      <div className="input-group">
                        <TextInput
                          name="street"
                          id="street"
                          type="text"
                          required
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma rua!',
                          }}
                          value={street}
                          className="form-control "
                          onChange={(e) => setStreet(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px', width: 19 + '%' }}>
                      <label className="required">Número</label>
                      <div className="input-group">
                        <TextInput
                          name="number"
                          id="number"
                          type="text"
                          required
                          maxLength={15}
                          errorMessage={{
                            required: 'Por favor, informe um número!',
                          }}
                          value={number}
                          className="form-control "
                          onChange={(e) => setNumber(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 29 + '%' }}>
                      <label>Complemento</label>
                      <div className="input-group">
                        <TextInput
                          name="complement"
                          id="complement"
                          type="text"
                          value={complement}
                          maxLength={100}
                          style={{ height: '2.1875rem' }}
                          className="form-control "
                          onChange={(e) => setComplement(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 29 + '%', marginLeft: '15px' }}>
                      <label>Bairro</label>
                      <div className="input-group">
                        <TextInput
                          name="neighborhood"
                          id="neighborhood"
                          type="text"
                          required
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe um bairro!',
                          }}
                          value={neighborhood}
                          style={{ height: '2.1875rem' }}
                          className="form-control "
                          onChange={(e) => setNeighborhood(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 29 + '%', marginLeft: '15px' }}>
                      <label>Cidade</label>
                      <div className="input-group">
                        <TextInput
                          name="city"
                          id="city"
                          type="text"
                          required
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma cidade!',
                          }}
                          value={city}
                          className="form-control "
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 13 + '%', marginLeft: '15px' }}>
                      <label>UF</label>
                      <div className="input-group">
                        <TextInput
                          name="uf"
                          disabled
                          id="uf"
                          type="text"
                          required
                          maxLength={2}
                          errorMessage={{
                            required: 'Por favor, informe uma estado!',
                          }}
                          value={uf}
                          className="form-control "
                          onChange={(e) => setUF(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="divider"></div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 50 + '%' }}>
                        <label className="required">Tipo de Telefone</label>
                        <div className=" dv-typecontact">
                          <select
                            name="phonetype"
                            id="phonetype"
                            placeholder="Selecione"
                            value={phoneType}
                            className="form-select"
                            onChange={(e) =>
                              setPhoneType(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataPhoneType.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                        <label className="required">Tipo de Operadora</label>
                        <div className=" dv-typecontact">
                          <select
                            name="phoneoperator"
                            id="phoneoperator"
                            placeholder="Selecione"
                            value={phoneOperator}
                            className="form-select"
                            onChange={(e) =>
                              setPhoneOperator(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataPhoneOperator.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '120px' }}>
                        <label className="required">Telefone</label>
                        <div className="input-group">
                          <MaskedInput
                            name="phone"
                            id="phone"
                            type="text"
                            value={phone}
                            className="form-control "
                            onChange={(e) => setPhone(e.target.value)}
                            mask={
                              ![1, 2, 99].includes(parseInt(phoneType))
                                ? [
                                    '(',
                                    /[0-9]/,
                                    /[0-9]/,
                                    ')',
                                    ' ',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                  ]
                                : [
                                    '(',
                                    /[0-9]/,
                                    /[0-9]/,
                                    ')',
                                    ' ',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                  ]
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div style={{ width: '130px', marginLeft: '15px' }}>
                        <label>Observação</label>
                        <div className="input-group">
                          <input
                            name="phonecomments"
                            id="phonecomments"
                            type="text"
                            value={phoneComments}
                            maxLength={15}
                            className="form-control "
                            onChange={(e) => setPhoneComments(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TooltipItem
                          content="Click aqui para inserir um novo telefone"
                          className="background-info"
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-success"
                            style={{ marginLeft: '15px', marginTop: '19px' }}
                            onClick={(e) => addPhone()}
                          >
                            <em className="fa fa-arrow-right"></em>
                          </button>
                        </TooltipItem>
                      </div>
                    </div>
                    <Alert
                      variant="danger"
                      style={{ marginTop: '5px', marginBottom: '0px' }}
                      className={`${messagePhone === '' ? 'hidden' : ''}`}
                    >
                      {messagePhone}
                    </Alert>
                  </div>
                  <div className="width75" style={{ marginLeft: '15px' }}>
                    <div style={{ overflow: 'auto', height: '150px' }}>
                      <table className="table table-striped w-100">
                        <thead className="thead-theme">
                          <tr className="head-th">
                            <td
                              className="head-th"
                              style={{
                                width: '85px',
                                minWidth: '85px',
                                maxWidth: '85px',
                              }}
                            >
                              Tipo
                            </td>
                            <td
                              className="head-th"
                              style={{
                                width: '70px',
                                minWidth: '70px',
                                maxWidth: '70px',
                              }}
                            >
                              Operadora
                            </td>
                            <td
                              className="head-th"
                              style={{
                                width: '80px',
                                minWidth: '80px',
                                maxWidth: '75px',
                              }}
                            >
                              Telefone
                            </td>
                            <td style={{ textAlign: 'center', width: '60px' }}>
                              ...
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {phones.map((item, key) => {
                            return (
                              <tr
                                className="head-th"
                                key={key}
                                onDoubleClick={(e) => editPhone(item.phone)}
                              >
                                <td title={item.comments}>{item.phonetype}</td>
                                <td title={item.comments}>
                                  {item.phoneoperator}
                                </td>
                                <td title={item.comments}>{item.phone}</td>
                                <td style={{ textAlign: 'center' }}>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={(e) => removePhone(item.phone)}
                                  >
                                    <em className="fa fa-trash"></em>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-warning  btn-sm"
                                    onClick={(e) => editPhone(item.phone)}
                                    style={{ marginLeft: '5px' }}
                                  >
                                    <em className="fa fa-edit"></em>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        className={`box-sem-dados bg-warning ${
                          phones.length > 0 ? 'hidden' : ''
                        }`}
                        style={{ padding: '15px' }}
                      >
                        Ainda não foi incluido nenhum telefone.
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '175px',
                      minWidth: '175px',
                      maxWidth: '175px',
                      marginBottom: '10px',
                    }}
                  >
                    <label className="required">Tipo de Contato</label>
                    <div className=" dv-typecontact">
                      <select
                        name="contacttype"
                        id="contacttype"
                        placeholder="Selecione"
                        className="form-select"
                        value={contactType}
                        onChange={(e) =>
                          setContactType(parseInt(e.target.value))
                        }
                      >
                        <option value="">Selecione</option>
                        {dataContactType.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div style={{ marginLeft: '15px', width: '275px' }}>
                    <label className="required">Contato</label>
                    <div className="input-group">
                      <input
                        name="contact"
                        id="contact"
                        type="text"
                        value={contactValue}
                        maxLength={100}
                        className="form-control "
                        onChange={(e) => setContactValue(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TooltipItem
                      content="Click aqui para inserir um novo contato"
                      className="background-info"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{ marginLeft: '15px', marginTop: '8px' }}
                        onClick={addContact}
                      >
                        <em className="fa fa-arrow-down"></em>
                      </button>
                    </TooltipItem>
                  </div>
                </div>

                <div style={{ overflow: 'auto', height: '187px' }}>
                  <Alert
                    variant="danger"
                    className={`${messageContact === '' ? 'hidden' : ''}`}
                  >
                    {messageContact}
                  </Alert>
                  <table className="table table-striped w-100">
                    <thead className="thead-theme">
                      <tr className="head-th">
                        <td
                          className="head-th"
                          style={{
                            width: '175px',
                            minWidth: '175px',
                            maxWidth: '175px',
                          }}
                        >
                          Tipo de Contato
                        </td>
                        <td className="head-th">Contato</td>
                        <td style={{ textAlign: 'center', width: '100px' }}>
                          ...
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((item) => {
                        return (
                          <tr
                            className="head-th text-dark"
                            key={item.id}
                            onDoubleClick={() =>
                              editContact(item.id, item.value)
                            }
                          >
                            <td>{item.type} </td>
                            <td>{item.value} </td>

                            <td style={{ textAlign: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() =>
                                  removeContact(item.id, item.value)
                                }
                              >
                                <em className="fa fa-trash"></em>
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning  btn-sm"
                                onClick={() => editContact(item.id, item.value)}
                                style={{ marginLeft: '5px' }}
                              >
                                <em className="fa fa-edit"></em>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className={`box-sem-dados bg-warning ${
                      contacts.length > 0 ? 'hidden' : ''
                    }`}
                  >
                    Ainda não foi incluido nenhum contato.
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-provider"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
