import { memo, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import ModalProduct from '../../../pages/modals/modal-product';

const colourOptions = [
  { label: 'Azul', value: 'blue' },
  { label: 'Vermelho', value: 'red' },
  { label: 'Preto', value: 'black' },
  { label: 'Rosa', value: 'pink' },
  { label: 'Verde', value: 'green' },
  { label: 'Amarelo', value: 'yellow' },
];

const NfeProducts = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  console.log('🚀 ~ file: products.js:18 ~ NfeProducts ~ errors', errors);
  const [showModalProduct, setShowModalProduct] = useState(false);

  const { fields, append, remove } = useFieldArray({
    name: 'products.products', // unique name for your Field Array
  });

  const handleCloseModalProduct = () => {
    setShowModalProduct(false);
  };

  const filterColors = (inputValue) => {
    return colourOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };
  const loadOptions = async (inputValue, callback) => {
    // try {
    //     await api
    //         .get('/getProvider')
    //         .then((response) => {
    //             if (response.ok) {
    //                 console.log(response);
    //             }
    //         })
    //         .catch((error) => {
    //             ShowMessages(error);
    //         });
    // } catch (response) {
    //     ShowMessages(response);
    // }

    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  return (
    <Container fluid>
      <ModalProduct
        showModal={showModalProduct}
        handleCloseModal={handleCloseModalProduct}
        editState={false}
      />
      {fields.map((field, index) => (
        <Row key={field.id} className="d-flex align-items-center">
          <Col
            sm={1}
            md={1}
            lg={1}
            className="badge text-bg-primary align-self-end"
            style={{ width: '30px' }}
          >
            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
          </Col>
          <Col md={3} lg={3} className="d-flex align-items-end mr-2">
            <div className="input-group">
              <label>Produto/Código</label>
              {/* <Controller
                                name={`products.products.${index}.product`}
                                control={control}
                                // rules={{ required: false }}
                                render={({ field }) => (
                                    <AsyncSelect
                                        {...field}
                                        name={`products.products.${index}.product`}
                                        isClearable
                                        defaultOptions
                                        placeholder={'Produto'}
                                        loadOptions={loadOptions}
                                    />
                                )}
                            /> */}
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>Quantidade</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.quantity`}
                id="quantity"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.quantity`)}
              />
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>IPI (%)</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.ipi`}
                id="ipi"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.ipi`)}
              />
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>ICMS (%)</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.icms`}
                id="icms"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.icms`)}
              />
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>Valor Unit.</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.unitaryValue`}
                id="unitaryValue"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.unitaryValue`)}
              />
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>CFOP</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.cfop`}
                id="cfop"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.cfop`)}
              />
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>NCM</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.ncm`}
                id="ncm"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.ncm`)}
              />
            </div>
          </Col>
          <Col lg={1} md={1}>
            <div className="input-group">
              <label>Valor Total</label>
              <Form.Control
                className="form-control"
                name={`products.products.${index}.totalValue`}
                id="totalValue"
                type="number"
                key={field.id}
                {...register(`products.products.${index}.totalValue`)}
              />
            </div>
          </Col>
          <Col sm={1} md={1} lg={1} className="align-self-end">
            <button className="btn btn-danger" onClick={() => remove(index)}>
              <i className="fa-solid fa-trash" />
            </button>
          </Col>
        </Row>
      ))}
      <div className="my-2">
        <button
          type="button"
          title="adicionar produto"
          className="btn btn-success font-weight-bold me-2"
          onClick={() =>
            append({
              product: '',
              quantity: 0,
              ipi: 0,
              icms: 0,
              unitaryValue: 0,
              cfop: 0,
              ncm: 0,
              totalValue: 0,
            })
          }
        >
          <i className="fa-solid fa-plus" />
        </button>
        <button
          title="Adicionar Novo Produto"
          className="btn btn-success font-weight-bold"
          onClick={() => setShowModalProduct(true)}
        >
          <i className="fa-solid fa-plus" />
          <i className="fa-solid fa-box" />
        </button>
      </div>
    </Container>
  );
};

export default memo(NfeProducts);
