import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const purpose = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const Data = () => {
    const { register } = useFormContext();
    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={4} md={4}>
                    <label htmlFor="data-transactionNature">Natureza da operação</label>
                    <div className="input-group">
                        <FormControl {...register('data.transactionNature')} id="data-transactionNature" type="text" />
                    </div>
                </Col>
                <Col lg={3} md={3}>
                    <label htmlFor="data-purpose">Finalidade</label>
                    <div className="input-group">
                        <FormSelect {...register('data.purpose')} id="data-purpose" className="w-100">
                            <option value="">Selecione</option>

                            {purpose.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3}>
                    <label htmlFor="data-type">Tipo</label>
                    <div className="input-group">
                        <FormSelect {...register('data.type')} id="data-type" className="w-100">
                            <option value="">Selecione</option>

                            {purpose.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={1} md={1}>
                    <label htmlFor="data-series">Série NF-e</label>
                    <div className="input-group">
                        <FormControl {...register('data.series')} id="data-series" type="text" />
                    </div>
                </Col>
                <Col lg={1} md={1}>
                    <label htmlFor="data-number">N° NF-e</label>
                    <div className="input-group">
                        <FormControl {...register('data.number')} id="data-number" type="text" />
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg={4}>
                    <label htmlFor="data-client">Cliente</label>
                    <div>
                        <FormSelect {...register('data.client')} id="data-client" className="w-100">
                            <option value="">Selecione</option>

                            {purpose.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                {/* {cliente !== null && (
                    <Col lg={1} className="d-flex align-items-end justify-content-center">
                        <button
                            className="btn btn-warning"
                            title="Insere um novo cliente"
                            onClick={showClient(cliente.id)}
                        >
                            <i className="fa fa-eye" />
                        </button>
                    </Col>
                )}
                <Col lg={cliente !== null ? 1 : 2} className="d-flex align-items-end justify-content-center">
                    <button className="btn btn-success" title="Insere um novo cliente" onClick={setShowModal}>
                        <i className="fa fa-plus" />
                    </button>
                </Col> */}
                <Col lg={4}>
                    <label htmlFor="data-seller">Vendedor</label>
                    <div>
                        <FormSelect {...register('data.seller')} id="data-seller" className="w-100">
                            <option value="">Selecione</option>

                            {purpose.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                {/* {cliente !== null && (
                    <Col lg={1} className="d-flex align-items-end justify-content-center">
                        <button className="btn btn-warning" title="Ver Vendedor" onClick={showClient(cliente.id)}>
                            <i className="fa fa-eye" />
                        </button>
                    </Col>
                )}
                <Col lg={cliente !== null ? 1 : 2} className="d-flex align-items-end justify-content-center">
                    <button className="btn btn-success" title="Insere um novo vendedor" onClick={setShowModal}>
                        <i className="fa fa-plus" />
                    </button>
                </Col> */}
            </Row>
        </Container>
    );
};

export default memo(Data);
