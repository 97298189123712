import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const Switch = ({ name, label }) => {
  const { control } = useFormContext();
  return (
    <div className="d-flex position-relative">
      <label className="switch me-3">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <input
                type="checkbox"
                onChange={onChange}
                value={value}
                checked={value}
                ref={ref}
              />
            </>
          )}
        />

        <span />
      </label>
      <span>{label}</span>
    </div>
  );
};

export default memo(Switch);
