import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const CteIcms = () => {
    const { control, register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="icms-icmsCst">CST ICMS</label>
                    <div className="input-group">
                        <FormSelect {...register(`icms.icmsCst`)} id="icms-icmsCst" className="w-100">
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="icms-icmsCalculationBasis">Base de cálculo ICMS</label>
                    <div className="input-group">
                        <FormControl
                            {...register(`icms.icmsCalculationBasis`)}
                            name={`icms.icmsCalculationBasis`}
                            id="cms-icmsCalculationBasis"
                            type="text"
                            className="form-control"
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="icms-icmsAliquot">Aliquota ICMS</label>
                    <div className="input-group">
                        <FormControl
                            {...register(`icms.icmsAliquot`)}
                            name={`icms.icmsAliquot`}
                            id="icms-icmsAliquot"
                            type="text"
                            className="form-control"
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label htmlFor="icms-icmsValue">Valor ICMS</label>
                    <div className="input-group">
                        <Controller
                            name="icms.icmsValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="icms.icmsValue"
                                    id="icms.icmsValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteIcms);
