import React, { useState, useEffect } from 'react'

import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions'
import { getUserData } from './../../services/auth';

import USER_NO_PHOTO from './../../assets/user-no-photo.jpg'
import setStatistic from './../../services/apiStatistic'

export default function Profile() {
    const [loading, setLoading]           = useState(false)
    const [photo, setPhoto]               = useState(null)
    const [occupation, setOccupation]     = useState('')
    const [registration, setRegistration] = useState('')
    const [email, setEmail]               = useState('')
    const [phone, setPhone]               = useState('')
    const [city, setCity]                 = useState('')
    const [uf, setUF]                     = useState('')
    const [lastAccess, setLastAccess]     = useState('')
    const [twitter, setTwitter]           = useState('')
    const [facebook, setFacebook]         = useState('')
    const [instagram, setInstagram]       = useState('')
    const [linkedin, setLinkedin]         = useState('')
    const [name, setName]                 = useState('')
    const [usergroup, setUsergroup]       = useState('')

    useEffect(() => {
        getUserInfo()
        setStatistic('maintenance\\profile')
    },[])

    const getUserInfo = async() => {
        setLoading(true)
  
        let url
        await api.get('/user/' + getUserData()['iduser'])
                 .then(response => {
                    let _data = response.data
                    if (_data.pathphoto !== "")
                        url = _data.pathphoto
                    setOccupation(_data.occupation)
                    setRegistration(_data.registration)
                    setEmail(_data.email)
                    setPhone(_data.phones)
                    setCity(_data.city)
                    setUF(_data.uf)
                    setLastAccess(_data.lastaccess)
                    setName(_data.name)
                    setTwitter(_data.twitter)
                    setFacebook(_data.facebook)
                    setInstagram(_data.instagram)
                    setLinkedin(_data.linkedin) 
                    setUsergroup(_data.usergroup) 
                 }).catch(error => {
                    setLoading(true)
                    ShowMessages(error)
                 }) 
        
        if (url !== undefined)       
            await api.get(url,{
                        responseType: 'arraybuffer'
                      })
                     .then(response => {  
                         setPhoto('data:image/png;base64,' + response.data)  
                         setLoading(false)  
                     }).catch(error => {
                        ShowMessages(error)
                     })  
        else
            setLoading(false)                     
    }             

    const onImageChange = (event ) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let file = event.target.files[0];
            reader.onloadend = () => {
                setPhoto(reader.result)
            };
            reader.readAsDataURL(file);
        }
    }

    const onsubmit = async() => {
        const data = new FormData();
        data.append('image',document.querySelector('#user-photo').files[0])

        await api.put('/user/uploadfoto/' + getUserData()['iduser'],data,{ 
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }})
                 .then((response) => {
                    ShowMessages(response)
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    return (
        
        <ContentWrapper>
           
            <Loading loading={loading} />
            <div className={`row ${(loading ? "hidden" : "")}`} style={{justifyContent:"center"}}>
                <div className="box-profile-center">
                    <div className="text-center">
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <input type="file" id="user-photo" style={{display:"none"}} accept="image/*" onChange={onImageChange}/>
                            <img alt="Imagem do usuário" src={photo === null ? USER_NO_PHOTO : photo} className="img-circle img-md" />
                            <b className="fa fa-camera edit-photo-profile" onClick={e => (document.querySelector('#user-photo').click())}/>
                        </div>
                        
                    </div>
                    <div className="text-center mt-2">
                        <h5 className="text-120 text-secondary-d3">
                            {name}
                        </h5>
                    </div>
                    <div>
                        <div className="mx-auto mt-25 text-center action-buttons">
                            <a href={twitter} className="mx-1" target="_blank" style={{cursor:"Pointer"}}>
                                <i className="fab fa-twitter-square text-twitter"></i>
                            </a>
                            <a href={facebook} className="mx-1" target="_blank" style={{cursor:"Pointer"}}>
                                <i className="fab fa-facebook text-facebook"></i>
                            </a>
                            <a href={instagram} className="mx-1" target="_blank" style={{cursor:"Pointer"}}>
                                <i className="fab fa-instagram text-instagram"></i>
                            </a> 
                            <a href={linkedin} className="mx-1" target="_blank"style={{cursor:"Pointer"}}>
                                <i className="fab fa-linkedin text-linkedin"></i>
                            </a>
                        </div>
                    </div>
                    <hr className="wd-100 border-dotted" />
                    <div className="col-12 px-4 mb-3">
                        <h4 className="text-dark-m3 text-140">
                            <i className="fa fa-info text-theme"></i> Informações Pessoais
                            
                        </h4>
                        <hr className="wd-100 border-dotted" />

                        <div className="bgc-white px-1 bo1rder-1 brc-secondary-l2 radius-1">
                            <table className="table table table-striped table-borderless">
                                <tbody>
                                    <tr>
                                        <td><i className="fa fa-user-cog text-primary"></i></td>
                                        <td className="text-95 text-default-d3">Função</td>
                                        <td className="text-secondary-d2">{occupation}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa fa-user text-success"></i></td>
                                        <td className="text-95 text-default-d3">Matrícula</td>
                                        <td className="text-secondary-d2">{registration}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa fa-users text-success"></i></td>
                                        <td className="text-95 text-default-d3">Grupo de usúario</td>
                                        <td className="text-secondary-d2">{usergroup}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa fa-envelope text-info"></i></td>
                                        <td className="text-95 text-default-d3">Email</td>
                                        <td className="text-secondary-d2 text-wrap">{email}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa fa-phone text-warning"></i></td>
                                        <td className="text-95 text-default-d3">Telefone</td>
                                        <td className="text-secondary-d2">{phone}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa fa-map-marker text-danger"></i></td>
                                        <td className="text-95 text-default-d3">Cidade</td>
                                        <td className="text-secondary-d2">{city}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa fa-map-marker text-danger"></i></td>
                                        <td className="text-95 text-default-d3">UF</td>
                                        <td className="text-secondary-d2">{uf}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="far fa-clock text-secondary"></i></td>
                                        <td className="text-95 text-default-d3">Último Acesso</td>
                                        <td className="text-secondary-d2">{lastAccess}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button className="btn btn-theme" onClick={e => onsubmit()}><i className="fa fa-save"></i> Salvar Foto</button>
                        </div>
                    </div>
                </div>
            </div>                 
        </ContentWrapper>)
}