import React, { useEffect, useState} from 'react'

import { Modal, ModalDialog } from 'react-bootstrap';

import api from '../../services/api'
import ShowMessages from './../../components/Exceptions' 
import Loading from '../../components/Loading' 
import setStatistic from './../../services/apiStatistic'
import Draggable from 'react-draggable';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-body"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalPDFViewer({showModal, handleCloseModal, url}) {
    const [loading, setLoading] = useState(false)
    const [data, setData]       = useState(null)

    const getData = async() => {
        setLoading(true)
        await api.get(url)
                 .then(response => { 
                    setData("data:application/pdf;base64," + response.data)     
                    setLoading(false)
                 })
                 .catch( error => { 
                     setLoading(false)
                     ShowMessages(error)
                 })
    }

    useEffect(() => {
        if (showModal === true) {
            getData()
            setStatistic('modals\\modal-pdf-viewer')
        }
    },[showModal])
    
    useEffect(() => {
        
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <Modal.Body>              
                <button type="button" className='close color-button' onClick={e => handleCloseModal(false)}>
                    <span aria-hidden="true">×</span><span className="sr-only">Fechar</span>
                </button>
                <Loading loading={loading} addClass="heigth70vh"/>
                <embed   id="frm-pdf" src={data}  className={`container-pdf-viewer ${loading ? "hidden" : ""}`}/> 
            </Modal.Body>
        </Modal>
    )
}