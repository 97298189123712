import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';
import Switch from '../../Forms/Inputs/switch';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const NfseServices = () => {
    const { register, control } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={12} md={12} sm={12}>
                    <label>Descrição</label>
                    <div className="input-group">
                        <FormControl
                            {...register('services.description')}
                            name="services.description"
                            id="services-description"
                            as="textarea"
                            rows={6}
                            placeholder="Detalhe o serviço que foi ou será prestado"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="services-serviceValue">Valor do serviço</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <Controller
                                name="services.serviceValue"
                                control={control}
                                render={({ field }) => (
                                    <MaskInput
                                        name="services.serviceValue"
                                        id="services-serviceValue"
                                        mask="currency"
                                        field={field}
                                        className={`text-right`}
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="services.calculationBasis">Base de cáculo</label>
                    <div className="input-group">
                        <FormControl
                            {...register('services.calculationBasis')}
                            id="services-calculationBasis"
                            type="number"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="services-aliquot">Aliquota(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('services.aliquot')}
                            id="services-aliquot"
                            type="number"
                            step={0.01}
                            defaultValue={0.0}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="services-taxAmount">Valor imposto</label>
                    <div className="input-group">
                        <Controller
                            name="services.taxAmount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="services.taxAmount"
                                    id="services-taxAmount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3} className="d-flex align-items-center">
                    <Switch name="services.isToRetainIss" label="Reter ISS" />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="services-deductionAmount">Valor deduções</label>
                    <div className="input-group">
                        <Controller
                            name="services.deductionAmount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="services.deductionAmount"
                                    id="services-deductionAmount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="services-unconditionalAmount">Valor incondicionado</label>
                    <div className="input-group">
                        <Controller
                            name="services.unconditionalAmount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="services.unconditionalAmount"
                                    id="services-unconditionalAmount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={3}>
                    <label htmlFor="services-conditionalAmount">Valor condicionado</label>
                    <div className="input-group">
                        <Controller
                            name="services.conditionalAmount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="services.conditionalAmount"
                                    id="services-conditionalAmount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="services-federalTaxes">Tributos federais</label>
                    <div className="input-group">
                        <Controller
                            name="services.federalTaxes"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="services.federalTaxes"
                                    id="services-federalTaxes"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={3} md={3} sm={3}>
                    <label htmlFor="services-municipalTaxes">Tributos municipais</label>
                    <div className="input-group">
                        <Controller
                            name="services.municipalTaxes"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="services.municipalTaxes"
                                    id="services-municipalTaxes"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={4}>
                    <label htmlFor="services-activity">Atividade</label>
                    <div className="input-group">
                        <FormSelect {...register('services.activity')} id="services-activity" className="w-100">
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfseServices);
