import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';

const Settings = (props) => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = async (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await api.post('/settings', {
        productSummary: isChecked,
      });
    } catch (response) {
      ShowMessages(response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchSettings() {
      try {
        const { data } = await api.get('setttigs');
        setIsChecked(data.settings);
      } catch (error) {}
    }

    fetchSettings();
  }, []);

  return (
    <>
      <div
        className="d-flex justify-content-left box-shadow"
        style={{ padding: '50px' }}
      >
        <div className="form-check form-switch ms-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="productSummaryCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label
            className="form-check-label fs-6"
            htmlFor="productSummaryCheckbox"
          >
            Utilizar Cadastro de Produtos Resumido
          </label>
        </div>
      </div>
      <div className="modal-footer footer-fixed modal-footer-bottom-10">
        <Button
          type="submit"
          className={`btn-theme`}
          disabled={loading}
          onClick={handleSubmit}
        >
          <em className="fa fa-save"></em> Salvar
        </Button>
        <Button
          className="btn-ligth-gray"
          onClick={(e) => window.removeTab(`tab_${props.id}`, e)}
        >
          <em className="fa fa-door-closed"></em> Fechar
        </Button>
      </div>
    </>
  );
};

export default Settings;
