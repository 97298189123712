import React, {useState, useEffect} from 'react'

import { ValidationForm, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Draggable from 'react-draggable';

import Loading from '../../components/Loading';

import api from '../../services/api'
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCopyPermissionGroup({showModal, handleCloseModal}) {
    const [loading, setLoading]     = useState(false)
    const [fromGroup, setFromGroup] = useState(0)
    const [toGroup, setToGroup]     = useState(0)
    const [dataGroup, setDataGroup] = useState([])

    const handleSubmit = async(e) => {
        loadingButton(true,document.querySelector('#submit-copy-permission'),document.querySelector('#submit-copy-permission').children[0].classList,document.querySelector('#submit-copy-permission').lastChild.nodeValue)
        e.preventDefault()
        try {
            await api.post('/permission-copy', {fromGroup,
                                                toGroup 
                           })
                     .then(response => {
                         if (response.ok) {
                            ShowMessages(response);
                            if (response.data.status === 200)
                                handleCloseModal() 
                            else 
                                loadingButton(false,document.querySelector('#submit-copy-permission'),'fa fa-copy','Copiar')  
                         }
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#submit-copy-permission'),'fa fa-copy','Copiar') 
                        ShowMessages(response);
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-copy-permission'),'fa fa-copy','Copiar')
            ShowMessages(response)        
        }
    }

    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getUserGroupData')
                     .then(response => {
                        if (response.ok) {
                            setDataGroup(response.data)
                        }
                    })
            setLoading(false)         
        } catch (response) {
            ShowMessages(response)    
        }
    }

    useEffect(() => {
        getData()
        if (showModal)
            setStatistic('modals\\modal-copy-permission-group')
    } ,[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-copy"></i> Copiar Permissões
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div style={{ display: "Flex", flexDirection:"column" }} className={`${loading ? "hidden" : ""}`}>
                        <div style={{width:"300px"}}>
                            <label>Copiar permissões do grupo...</label>
                            <SelectGroup 
                                name="fromGroup" 
                                id="fromGroup"
                                value={fromGroup} 
                                required 
                                className="form-select"
                                errorMessage="Por favor, informe um grupo para copiar as permissões."
                                onChange={e => setFromGroup(parseInt(e.target.value))}>
                                <option value="">Selecione</option>
                                {dataGroup.map((data, id) => {
                                    return (<option key={id} value={data.value}>{data.label}</option>)
                                })} 
                            </SelectGroup>
                        </div>
                    </div>    
                    <div style={{ display: "Flex", flexDirection:"column" }} className={`${loading ? "hidden" : ""}`}>
                        <div style={{width:"300px"}}>
                            <label>... para o grupo...</label>
                            <SelectGroup 
                                name="togroup" 
                                id="togroup"
                                value={toGroup} 
                                required 
                                className="form-select"
                                errorMessage="Por favor, informe um grupo para receber as permissões."
                                onChange={e => setToGroup(parseInt(e.target.value))}>
                                <option value="">Selecione</option>
                                {dataGroup.map((data, id) => {
                                    return (<option key={id} value={data.value}>{data.label}</option>)
                                })} 
                            </SelectGroup>
                        </div>
                    </div>   
            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id="submit-copy-permission" className="btn-theme">
                    <em className="fa fa-copy"></em> Copiar
                </Button>          
                <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}