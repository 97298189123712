import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';

import ShowMessages from './../../components/Exceptions'
import zxcvbn from 'zxcvbn';

import api from '../../services/api'
import setStatistic from './../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'
import PasswordStrengthBar from 'react-password-strength-bar';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ChangePassword({showModal, handleCloseModal, iduser = 0}) {
    const [password, setPassword ]               = useState('')
    const [newPassword, setNewPassword ]         = useState('')
    const [confirmPassword, setConfirmPassword ] = useState('')
    const [updatepassword, setUpdatePassword]    = useState(false)

    const matchPassword = (value) => {
        return value && value === newPassword;   
    }

    const clearData = () => {
        setPassword('')  
        setNewPassword('')  
        setConfirmPassword('')  
        setUpdatePassword(false)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        const score = zxcvbn(confirmPassword)

        if (score.score < 3) {
            ShowMessages({status : 500, message : "A senha não atende os requisitos mínimos de segurança!"})   
            return
        }


        loadingButton(true,document.querySelector('#change-password'),document.querySelector('#change-password').children[0].classList,document.querySelector('#change-password').lastChild.nodeValue)
        try {
            await api.post('/changepassword', { password,
                                                confirmPassword,
                                                updatepassword,
                                                iduser
                           })
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                            if (response.data.status === 200)
                                handleCloseModal()  
                            else 
                                loadingButton(false,document.querySelector('#change-password'),'fa fa-key','Alterar') 
                         } else 
                            loadingButton(false,document.querySelector('#change-password'),'fa fa-key','Alterar') 
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#change-password'),'fa fa-key','Alterar')
                        ShowMessages(response)              
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#change-password'),'fa fa-key','Alterar')
            ShowMessages(response)          
        }
    }

    useEffect(() => {
        clearData()
        if (showModal)
            setStatistic('modals\\modal-changePassword')
    } ,[showModal])

    return (
        <Modal size='sm' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-lock"></i> Alteração de Senha
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "Flex", flexDirection:"column" }}>
                        {iduser === 0 ?
                            <div>
                                <label>Senha Atual</label>
                                <div className="input-group">
                                    <TextInput
                                        name="password"
                                        id="password"
                                        type="password"
                                        required
                                        maxLength={20}
                                        errorMessage={{ required: "Por favor, informe um senha válida!" }}
                                        value={password}
                                        className="form-control "
                                        onChange={e => setPassword(e.target.value)}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        : ''}

                        <div>
                            <label>Nova Senha</label>
                            <div className="input-group">
                                <TextInput
                                    name="newpassword"
                                    id="newpassword"
                                    type="password"
                                    required
                                    maxLength={20}
                                    errorMessage={{ required: "Por favor, informe um senha válida!", pattern:"A senha devem conter pelo menos 6 dígitos." }}
                                    value={newPassword}
                                    className="form-control "
                                    pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                    onChange={e => setNewPassword(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div>
                            <label>Confirme a Senha</label>
                            <div className="input-group">
                                <TextInput
                                    name="confirmpassword"
                                    id="confirmpassword"
                                    type="password"
                                    required
                                    maxLength={20}
                                    pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                    errorMessage={{ required: "Por favor, informe um senha válida!", pattern:"A senha devem conter pelo menos 6 dígitos.", validator : "As senhas informadas não conferem."}}
                                    value={confirmPassword}
                                    validator={matchPassword}
                                    className="form-control "
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <PasswordStrengthBar password={newPassword} shortScoreWord= "Muito curta" scoreWords={['Muito Fraca', 'Fraca', 'OK', 'Boa', 'Muito Boa']}/>
                        <div style={{display:"flex",alignItems:"start", marginTop:"15px"}}>
                            
                            <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                <input type="checkbox" checked={updatepassword} value={updatepassword} onClick={e => setUpdatePassword(!updatepassword)}  />
                                <span style={{ marginTop: "5px" }}></span>
                            </label>
                            <label style={{marginLeft:"5px"}}>Alterar a senha no próximo login?</label>
                        </div>  
                </div>    
            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id="change-password" className="btn-theme">
                    <em className="fa fa-key"></em> Alterar
                </Button>          
                <Button className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}