import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const CteTransportDocuments = () => {
    const { register, control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'transportDocuments.informations', // unique name for your Field Array
    });

    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="informations-cnpj">CPF/CNPJ</label>
                                <Controller
                                    name={`transportDocuments.informations.${index}.cnpj`}
                                    control={control}
                                    render={({ field }) => (
                                        <MaskInput
                                            {...register(`transportDocuments.informations.${index}.cnpj`)}
                                            name={`transportDocuments.informations.${index}.cnpj`}
                                            id="informations-cnpj"
                                            mask="cnpj"
                                            field={field}
                                            className={`text-right`}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="informations-companyName">Razao social/Nome</label>
                                <FormControl
                                    {...register(`transportDocuments.informations.${index}.companyName`)}
                                    name={`transportDocuments.informations.${index}.companyName`}
                                    id="informations-companyName"
                                    type="text"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="informations-stateRegistration">Inscrição estadual</label>
                                <FormControl
                                    {...register(`transportDocuments.informations.${index}.stateRegistration`)}
                                    name={`transportDocuments.informations.${index}.stateRegistration`}
                                    id="informations-stateRegistration"
                                    type="text"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="informations-eletronicDocumentNumbers">N. doc. eletrônicos</label>
                                <FormControl
                                    {...register(`transportDocuments.informations.${index}.eletronicDocumentNumbers`)}
                                    name={`transportDocuments.informations.${index}.eletronicDocumentNumbers`}
                                    id="informations-eletronicDocumentNumbers"
                                    type="number"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col lg={2} md={2}>
                            <div className="input-group">
                                <label htmlFor="informations-paperDocumentNumbers">N. doc. em papel</label>
                                <FormControl
                                    {...register(`transportDocuments.informations.${index}.paperDocumentNumbers`)}
                                    name={`transportDocuments.informations.${index}.paperDocumentNumbers`}
                                    id="informations-paperDocumentNumbers"
                                    type="number"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="adicionar chave de acesso"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                cnpj: '',
                                companyName: '',
                                stateRegistration: '',
                                eletronicDocumentNumbers: '',
                                paperDocumentNumbers: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(CteTransportDocuments);
