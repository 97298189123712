import { memo, useState } from 'react';
import { Button, Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
//import AsyncSelect from 'react-select/async';
import ModalClient from '../../../pages/modals/modal-client';
import Switch from '../../Forms/Inputs/switch';

const colourOptions = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const NfseData = () => {
    const { register, control } = useFormContext();
    const [showModal, setShowModal] = useState(false);

    const filterColors = (inputValue) => {
        return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const loadOptions = async (inputValue, callback) => {
        // try {
        //     await api
        //         .get('/getProvider')
        //         .then((response) => {
        //             if (response.ok) {
        //                 console.log(response);
        //             }
        //         })
        //         .catch((error) => {
        //             ShowMessages(error);
        //         });
        // } catch (response) {
        //     ShowMessages(response);
        // }

        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 1000);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Container fluid>
            <ModalClient showModal={showModal} handleCloseModal={handleCloseModal} />
            <Row className="mb-2">
                <Col lg={5} md={5} className="d-flex align-items-end">
                    <div className="w-100">
                        <label htmlFor="data-client">Cliente</label>
                        <div className="input-group">
                            <div>
                                {/* <Controller
                                    name={`data.client`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`data.client`}
                                            id="data-client"
                                            isClearable
                                            defaultOptions
                                            placeholder={'Cliente'}
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <Button
                        title="Adicionar Cliente"
                        variant="success"
                        id="button-add-client"
                        className="ms-2 mb-1"
                        style={{ width: 'max-content' }}
                        onClick={() => setShowModal(true)}
                    >
                        <i className="fa-solid fa-plus" />
                    </Button>
                </Col>
                <Col lg={3} md={3} className="d-flex align-items-end">
                    <div className="w-100">
                        <label htmlFor="data-technician">Técnico</label>
                        <div className="input-group">
                            <div>
                                {/* <Controller
                                    name={`data.technician`}
                                    control={control}
                                    // rules={{ required: false }}
                                    render={({ field }) => (
                                        <AsyncSelect
                                            {...field}
                                            name={`data.technician`}
                                            id="data-technician"
                                            isClearable
                                            defaultOptions
                                            placeholder={'Técnico'}
                                            loadOptions={loadOptions}
                                        />
                                    )}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <Button
                        title="Adicionar Técnico"
                        variant="success"
                        id="button-add-technician"
                        className="ms-2 mb-1"
                        style={{ width: 'max-content' }}
                        onClick={() => setShowModal(true)}
                    >
                        <i className="fa-solid fa-plus" />
                    </Button>
                </Col>
                <Col lg={2} md={2}>
                    <label htmlFor="data-rpsSeries">Série RPS</label>
                    <div className="input-group">
                        <FormControl {...register('data.rpsSeries')} id="data-rpsSeries" type="text" />
                    </div>
                </Col>
                <Col lg={2} md={2}>
                    <label htmlFor="data-rpsNumber">Número RPS</label>
                    <div className="input-group">
                        <FormControl {...register('data.rpsNumber')} id="data-rpsNumber" type="number" />
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col lg={6} md={6}>
                    <Switch name="data.isClientNotIndentified" label="Tomador não indentificado" />
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfseData);
