import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const CteAdditionalInformation = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={12} md={12} sm={12}>
                    <label htmlFor="additionalInformation-information">Infomações</label>
                    <div className="input-group">
                        <FormControl
                            {...register('additionalInformation.information')}
                            name="additionalInformation.information"
                            id="additionalInformation-information"
                            as="textarea"
                            rows={6}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteAdditionalInformation);
