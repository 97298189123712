import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import logo from './../../assets/logo.png'
import api from '../../services/api'
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic'
import Loading from '../../components/Loading';
import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';

export default function UpdatePassword(props) {
    const [password, setPassword]              = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [loading, setLoading]                = useState(false)

    const matchPassword = (value) => {
        return value && value === password;   
    }

    async function handleSubmit(event) {
        const score = zxcvbn(password)

        if (score.score < 3) {
            ShowMessages({status : 500, message : "A senha não atende os requisitos mínimos de segurança!"})   
            return
        }
        
        event.preventDefault()
                    
        try {
            await api.put('/forgot', {
                password,
                confirmPassword,
                hash : window.location.pathname.split('/')[2]
            })
            .then(response => {
                if (response.ok) {
                    NotificationManager.success("Sua senha foi alterada com sucesso.", 'Sucesso!', 2000);
                    var _t = setInterval(function() {
                        clearInterval(_t)
                        window.location.href  = '/login'
                    },3000) 
                }
            })
        } catch (response) {
            ShowMessages(response)
        }
    }

    async function checkData() {
        try {        
            setLoading(true)
            let _hash = window.location.pathname.split('/')[2]

            if (_hash === "") {
                NotificationManager.error("Usuário não encontrado!", 'Erro!', 2000);
                var _t = setInterval(function() {
                    clearInterval(_t)
                    window.location.href  = '/login'
                },3000) 
            } else {
                await api.get('/forgot/' + _hash)  
                .then(response => {
                    if (response.ok) {                    
                        if ((response.data.status === 404) || (response.data.status === 500)) {
                            NotificationManager.error(response.data.message, 'Erro!', 2000);
                            var _t = setInterval(function() {
                                clearInterval(_t)
                                window.location.href  = '/login'
                            },3000) 
                        } else 
                            setLoading(false)
                    }
                })
            }
        } catch (response) {
            ShowMessages(response)
        }
    }

    useEffect(() => {
        checkData()
        setStatistic('update password')
    },[])

    return (
        <div className="background">
            <NotificationContainer />
            <Loading loading={loading}/>
            <div className={`content  ${(loading ? "hidden" : "")}`}>
                <div className="block-center wd-xl">
                    <div className="card card-flat">
                        <div className="card-header text-center bg-blue">
                            <Link to="login">

                                <img className="block-center rounded imgWidth" src={logo} alt={process.env.REACT_APP_NAME} />
                            </Link>
                        </div>
                        <div className="card-body">
                            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                                <div className="input-group mb-3" style={{flexDirection:"column"}}>
                                    <label>Informe sua nova senha.</label>
                                    <TextInput
                                        name="password"
                                        id="password"
                                        type="password"
                                        required
                                        errorMessage={{ required: "Por favor, informe sua senha!", pattern:"A senha devem conter pelo menos 6 dígitos." }}
                                        value={password}
                                        pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                        className="form-control "
                                        onChange={e => setPassword(e.target.value)}
                                        autoComplete="off"
                                        maxLength={20}
                                    />
                                </div>
                                <PasswordStrengthBar password={password} shortScoreWord= "Muito curta" scoreWords={['Muito Fraca', 'Fraca', 'OK', 'Boa', 'Muito Boa']}/>
                                <div className="input-group mb-3" style={{flexDirection:"column"}}>
                                    <label>Informe novamente sua nova senha.</label>
                                    <TextInput
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        type="password"
                                        required
                                        errorMessage={{ required: "Por favor, confirme sua senha!",validator : "As senhas informadas não conferem.",pattern:"A senha devem conter pelo menos 6 dígitos." }}
                                        value={confirmPassword}
                                        pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                                        validator={matchPassword}
                                        className="form-control "
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        autoComplete="off"
                                        maxLength={20}
                                    />
                                </div>
                                <button className="btn btn-block btn-theme mt-3" type="submit" style={{width:"100%"}}><em className="fa fa-key"></em> Alterar Senha</button>
                                <div className="float-right" style={{marginTop:"15px"}}>
                                    <Link to="login" className="text-muted">Voltar para o login?</Link>
                                </div>
                            </ValidationForm>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )

}


