import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const MdfeGeneralInformation = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={4} md={4}>
                    <label htmlFor="generalInformation-transporterType">Tipo do transportador</label>
                    <div className="input-group">
                        <FormSelect
                            {...register('generalInformation.transporterType')}
                            id="generalInformation-transporterType"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {genericArray.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-emissionDate">Data de emissão</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.emissionDate')}
                            id="generalInformation-emissionDate"
                            type="date"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-emissionHour">Hora de emissão</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.emissionHour')}
                            id="generalInformation-emissionHour"
                            type="time"
                            placeholder="hora"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-mdfeSeries">
                        Série MDF-e <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.mdfeSeries')}
                            name="generalInformation.mdfeSeries"
                            id="generalInformation-mdfeSeries"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label htmlFor="generalInformation-mdfeNumber">Número MDF-e</label>
                    <div className="input-group">
                        <FormControl
                            {...register('generalInformation.mdfeNumber')}
                            name="generalInformation.mdfeNumber"
                            id="generalInformation-mdfeNumber"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={6} md={6} className="d-flex align-items-end">
                    <div className="w-100">
                        <label htmlFor="generalInformation-loadingUf">
                            UF do carregamento<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                            <FormSelect
                                {...register('generalInformation.loadingUf')}
                                id="generalInformation-loadingUf"
                                className="w-100"
                            >
                                <option value="">Selecione</option>

                                {genericArray.map((item) => {
                                    return (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={6}>
                    <div className="w-100">
                        <label htmlFor="generalInformation-unloadingUf">
                            UF do descarregamento<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                            <FormSelect
                                {...register('generalInformation.unloadingUf')}
                                id="generalInformation-unloadingUf"
                                className="w-100"
                            >
                                <option value="">Selecione</option>

                                {genericArray.map((item) => {
                                    return (
                                        <option key={item.value} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={12} md={12}>
                    <label htmlFor="additionalInformation-observations">Observações</label>
                    <div className="input-group">
                        <FormControl
                            {...register('additionalInformation.observations')}
                            name="additionalInformation.observations"
                            id="additionalInformation-observations"
                            as="textarea"
                            rows={3}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(MdfeGeneralInformation);
