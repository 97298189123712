import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const CteRoad = () => {
    const { register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={5} md={5} sm={12}>
                    <label htmlFor="road-rntrc">RNTRC(Registro Nacional de Transportadores Rodoviários de Carga)</label>
                    <div className="input-group">
                        <FormControl {...register('road.rntrc')} name="road.rntrc" id="road-rntrc" />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteRoad);
