import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NfsePayment = () => {
    const { register } = useFormContext();
    return (
        <Container fluid>
            <Row>
                <Col md={3} lg={3}>
                    <label>Quantidade de parcelas</label>
                    <div className="d-flex">
                        <div className="w-100 d-flex">
                            <FormControl
                                {...register('payment.parcelsNumber')}
                                name="payment.parcelsNumber"
                                id="payment.parcelsNumber"
                                type="number"
                                maxLength={100}
                                min="0"
                                autoComplete="off"
                            />
                            <button className="btn btn-primary ms-2">
                                <i className="fa-solid fa-rotate-right" />
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(NfsePayment);
