import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const GeneralFT = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <Container fluid>
            <Row className="mt-2">
                <Col md={3} lg={3}>
                    <label>Aliquota de ICMS(%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.icmsAliquot')}
                                name="fiscalTributary.icmsAliquot"
                                id="fiscalTributary.icmsAliquot"
                                type="number"
                                maxLength={100}
                                className={`${errors.fiscalTributary?.icmsAliquot ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} lg={3}>
                    <label>Aliquota de IPI(%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.ipiAliquot')}
                                name="fiscalTributary.ipiAliquot"
                                id="fiscalTributary.ipiAliquot"
                                type="number"
                                maxLength={100}
                                className={`${errors.fiscalTributary?.ipiAliquot ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} lg={3}>
                    <label>Aliquota de PIS(%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.pisAliquot')}
                                name="fiscalTributary.pisAliquot"
                                id="fiscalTributary.pisAliquot"
                                type="number"
                                maxLength={100}
                                className={`${errors.fiscalTributary?.pisAliquot ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} lg={3}>
                    <label>Aliquota de COFINS(%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.cofinsAliquot')}
                                name="fiscalTributary.cofinsAliquot"
                                id="fiscalTributary.cofinsAliquot"
                                type="number"
                                maxLength={100}
                                className={`${errors?.fiscalTributary?.cofinsAliquot ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={2} lg={2}>
                    <label>Unid. Tributável</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.taxableUnit')}
                                name="fiscalTributary.taxableUnit"
                                id="fiscalTributary.taxableUnit"
                                type="text"
                                maxLength={100}
                                className={`${errors?.fiscalTributary?.taxableUnit ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} lg={3}>
                    <label>Código benefício Fiscal</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.taxBenefitCode')}
                                name="fiscalTributary.taxBenefitCode"
                                id="fiscalTributary.taxBenefitCode"
                                type="text"
                                maxLength={100}
                                className={`${errors?.fiscalTributary?.taxBenefitCode ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>

                <Col md={3} lg={3}>
                    <label>Código CEST</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.cestCode')}
                                name="fiscalTributary.cestCode"
                                id="fiscalTributary.cestCode"
                                type="text"
                                maxLength={100}
                                className={`${errors.cestCode ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Federal(%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.federal')}
                                name="fiscalTributary.federal"
                                id="fiscalTributary.federal"
                                type="number"
                                maxLength={100}
                                className={`${errors?.fiscalTributary?.federal ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2}>
                    <label>Estadual(%)</label>
                    <div className="d-flex">
                        <div className="w-100">
                            <FormControl
                                {...register('fiscalTributary.state')}
                                name="fiscalTributary.state"
                                id="fiscalTributary.state"
                                type="number"
                                maxLength={100}
                                className={`${errors?.fiscalTributary?.state ? 'is-invalid' : ''}`}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(GeneralFT);
