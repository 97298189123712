import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalUser from '../modals/modal-user';
import ModalChangeUserGroup from '../modals/modal-changeUserGroup';
import ModalChangeUser from '../modals/modal-changeUser';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions'
import setStatistic from './../../services/apiStatistic'
import {checkPermission} from './../../components/security'
import ChangePassword from '../modals/modal-changePassword'
import Swal from 'sweetalert2';

export default function Users() {
    const [loading, setLoading]                                   = useState(false)
    const [showModal, setShowModal]                               = useState(false)
    const [showModalChangeUserGroup, setShowModalChangeUserGroup] = useState(false)
    const [showModalChangeUser, setShowModalChangeUser]           = useState(false)
    const [showModalPassword, setShowModalPassword]               = useState(false)
    const [isOpenFilter, setIsOpenFilter]                         = useState(false)
    const [reload, setReload]                                     = useState(false)
    const [print, setPrint]                                       = useState(false)
    const [excel, setExcel]                                       = useState(false)
    const [showButtons, setShowButtons]                           = useState(false)
    const [gridParams, setGridParams]                             = useState({})
    const [filters, setFilters]                                   = useState([])

    const [dataUser,setDataUser]                                  = useState([])
    const [idUser, setIdUser]                                     = useState(0)
    const [editPermission, setEditPermisson]                      = useState(false)

    const getUserStatus = async() => {
        let _delCheck  = !checkPermission(4,7)
        let _editCheck = !checkPermission(8,7)
        let _inatCheck = !checkPermission(64,7)
        let _reseCheck = !checkPermission(16,7)
        let _permCheck = !checkPermission(32,7)
        let _altPass   = !checkPermission(512,7) 

        setEditPermisson(_editCheck)
        await api.get('/userstatus')
                 .then(response => {
                    setGridParams({
                                     iduser       : {text        : "#ID", mask : { name  : 'leftSlice',
                                                                                   value : '00000'},
                                                     primaryKey  : true,
                                                     dataType    : 'number'                  },
                                     name         : {text        : "Nome",
                                                     searchDefault : true},
                                     email        : {text        : "Email"},
                                     registration : {text        : "Matrícula"},
                                     usergroup    : {text        : "Grupo"},
                                     userstatus   : {text        : "Status",
                                                     item        : {className : "text-center",
                                                                    switch    : {data  : response.data,
                                                                                 key   : 'iduserstatus'}}},
                                     Options      : {text        : "Opções",
                                                     className   : "text-center",
                                                     order       : false,
                                                     filter      : false,
                                                     item        : [{type  : "Custom",
                                                                     props : {tag               : "button",
                                                                              title             : "Editar usuário",
                                                                              className         : 'btn btn-sm btn-primary',
                                                                              icon              : "fa fa-edit",
                                                                              disabled          : _editCheck,
                                                                              indexcallback     : 7}},
                                                                    {type  : "Custom",
                                                                    props : {tag               : "button",
                                                                             title             : "Exclusão de Usuário",
                                                                             className         : 'btn btn-sm btn-danger',
                                                                             icon              : "fa fa-trash",
                                                                             disabled          : _delCheck,
                                                                             message           : "Deseja realmente excluir o usuário?",
                                                                             indexcallback     : 1}},
                                                                    {type  : "Custom",
                                                                     props : {tag               : "button",
                                                                              title             : "Editar grupo do usuário",
                                                                              className         : 'btn btn-sm btn-warning padding0',
                                                                              icon              : "fa fa-users",
                                                                              disabled          : _editCheck,
                                                                              indexcallback     : 4}},
                                                                    {type  : "Custom", 
                                                                     props : {tag               : "button",
                                                                              title             : "Inativar usuário",
                                                                              className         : 'btn btn-sm btn-secondary',
                                                                              icon              : "fa fa-user-times marginLeft-1",
                                                                              disabled          : _inatCheck,
                                                                              message           : "Deseja realmente inativar o usuário?",
                                                                              visualConditional : {iduserstatus : {operator : 'not in',
                                                                                                                   values : [0]}},
                                                                              indexcallback     : 0}},
                                                                    {type  : "Custom", 
                                                                     props : {tag               : "button",
                                                                              title             : "Ativar usuário",
                                                                              className         : 'btn btn-sm btn-primary',
                                                                              icon              : "fa fa-user-check ",
                                                                              disabled          : _inatCheck,
                                                                              visualConditional : {iduserstatus : {operator : 'not in',
                                                                                                                   values : [1]}},
                                                                              hiddenForId       : 1,
                                                                              message           : "Deseja realmente ativar o usuário?",
                                                                              indexcallback     : 6}},    
                                                                    {type  : "Custom",
                                                                     props : {tag               : "dropdown",
                                                                              title             : "Opções de senha",
                                                                              className         : 'btn-dark button-dropdown',
                                                                              disabled          : _reseCheck,
                                                                              icon              : 'fa fa-key marginLeft-1',
                                                                              menus             : [{text : 'Resetar a senha',
                                                                                                    indexcallback : 3,
                                                                                                    icon : 'fa fa-key',
                                                                                                    disabled : _reseCheck},
                                                                                                    {text : 'Alterar a senha',
                                                                                                    icon : 'fa fa-lock',
                                                                                                    indexcallback : 8,
                                                                                                    disabled : _altPass}]}},
                                                                    {type  : "Custom",
                                                                     props : {tag               : "button",
                                                                             title              : "Permissões do usuário",
                                                                             disabled           : _permCheck,
                                                                             className          : 'btn btn-sm btn-success ',
                                                                             icon               : "fa fa-user-check marginLeft-1",                                                           
                                                                             indexcallback      : 5}}]}
                                      })              
                 })
                 .catch( error => { 
                    ShowMessages(error)

                })
    }

    useEffect(() => {
        getUserStatus()
        setStatistic('maintenance\\users')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    const InactiveUser     = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente inativar o usuário?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.put('/user/' + id,{inactive : 0})
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => { 
                            ShowMessages(error)
                        })
            }
            
        }) 

    }

    const ActiveUser     = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente ativar o usuário?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.put('/user/' + id,{inactive : 1})
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => { 
                            ShowMessages(error)
                        })
            }
            
        }) 

    }

    const Permissions = async(id, value) => {
        let props = {
            id 
        }
        window.addTab(null, value.toUpperCase() + ' | Permissões de Usuário', 'fa fa-users-cog', '/user/permissions',props,0)
    }

    const RemoveUser     = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o usuário?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/user/' + id)
                         .then(response => {
                            if (response.ok) {
                                ShowMessages(response)
                                if (response.data.status === 200)
                                    handleCloseModal()   
                            }
                            setReload(true)
                          }).catch(response => {
                             ShowMessages(response)
                       
                         })
            }
            
        }) 

    }

    const ResetPassword     = async (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente resetar a senha do usuário?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.put('/user/' + id,{reset : true})
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                          })
                         .catch( error => { 
                         })
                        .catch( error => { 
                            ShowMessages(error)
                })
                    
            }
          })
    }

    const UpdatePassword = (id) => {
        setIdUser(id)
        setShowModalPassword(true)
    }

    const EditGroup = async(id) => {
        await api.get('/user/' + id)
                 .then(response => {
                    setDataUser(response.data) 
                    setIdUser(id)
                    setShowModalChangeUserGroup(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const EditUser = async(id) => {
        await api.get('/user/' + id)
                 .then(response => {
                    setDataUser(response.data) 
                    setIdUser(id)
                    setShowModalChangeUser(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalUser showModal={showModal} handleCloseModal = {handleCloseModal}  handleReload={setReload} />
            <ModalChangeUserGroup showModal={showModalChangeUserGroup} handleCloseModal = {setShowModalChangeUserGroup}  handleReload={setReload} data={dataUser} idUser={idUser}/>
            <ModalChangeUser showModal={showModalChangeUser} handleCloseModal = {setShowModalChangeUser}  handleReload={setReload} data={dataUser} idUser={idUser}/>
            <ChangePassword showModal={showModalPassword} handleCloseModal={setShowModalPassword} iduser={idUser}/>

            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={!checkPermission(2,7)} title="Insere um novo registro" onClick={e => setShowModal(true)}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? (
                                <>
                                    <button className="btn btn-dark" title="Impressão" style={{marginLeft:"3px"}} onClick={e => setPrint(true)}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(128,7)} title="Exportar para excel" style={{marginLeft:"3px"}} onClick={e => setExcel(true)}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button></>)
                            : ""}    
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/user' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Usuários"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {editPermission ? undefined : EditUser}
                      callbackButtons     = {[InactiveUser, 
                                              RemoveUser, 
                                              setShowModal,
                                              ResetPassword,
                                              EditGroup,
                                              Permissions,
                                              ActiveUser,
                                              EditUser,
                                              UpdatePassword]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}