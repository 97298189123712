import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wrapper element for template content
 */
const ContentWrapper = props =>(
    <div className={`content-wrapper ${props.className}`} >
        {props.unwrap ?
            (<div className="unwrap">{props.children}</div>)
            :
            (props.children)
        }
    </div>
)

ContentWrapper.propTypes = {
    unwrap: PropTypes.bool,
    className:PropTypes.string
}
ContentWrapper.defaultProps = {
    unwrap: false,
    className:''
}

export default ContentWrapper;
