import { memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const CteCharges = () => {
    const { control } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="charges-suppliesTotalValues">Valor total prestação serviço (R$)</label>
                    <div className="input-group">
                        <Controller
                            name="charges.suppliesTotalValues"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="charges.suppliesTotalValues"
                                    id="charges.suppliesTotalValues"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="charges-taxesAproximateValues">Valor aproximado dos tributos (R$)</label>
                    <div className="input-group">
                        <Controller
                            name="charges.taxesAproximateValues"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="charges.taxesAproximateValues"
                                    id="charges.taxesAproximateValues"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="charges-accountsReceivable">Valor a receber (R$)</label>
                    <div className="input-group">
                        <Controller
                            name="charges.accountsReceivable"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="charges.accountsReceivable"
                                    id="charges.accountsReceivable"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteCharges);
