import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';

const CteInstallmets = () => {
    const { register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'installments.installments', // unique name for your Field Array
    });

    return (
        <Container fluid>
            <Row className="mb-2">
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={7} md={7}>
                            <div className="input-group">
                                <label htmlFor="installments-nome">Nome</label>
                                <FormControl
                                    {...register(`installments.installments.${index}.nome`)}
                                    name={`installments.installments.${index}.nome`}
                                    id="installments-nome"
                                    type="text"
                                    key={field.id}
                                    placeholder="Exemplos: FRETE PESO, FRETE VALOR, SEC/CAT, ADEME, AGENDAMENTO, etc"
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col lg={3} md={3}>
                            <div className="input-group">
                                <label htmlFor="installments-quantity">Quantidade</label>
                                <FormControl
                                    {...register(`installments.installments.${index}.quantity`)}
                                    name={`installments.installments.${index}.quantity`}
                                    id="installments-quantity"
                                    type="number"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>

                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="adicionar chave de acesso"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                nome: '',
                                quantity: 0,
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(CteInstallmets);
