import { Container } from 'react-bootstrap';
import GeneralFT from './FiscalTributary/generalFT';
import NFCEParameters from './FiscalTributary/NFCEParameters';
import NfeParameters from './FiscalTributary/NfeParameters';

const FiscalTributary = () => {
    return (
        <Container fluid>
            <GeneralFT />
            <NfeParameters />
            <NFCEParameters />
        </Container>
    );
};

export default FiscalTributary;
