import React, { useEffect, useState } from 'react';

import P from 'prop-types';
import { Accordion, Modal, ModalDialog } from 'react-bootstrap';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import { ACCEPT_TERMS, ReadDataStorage, REGISTRATION, WriteDataStorage } from '../../services/auth';
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalAcceptedTerms({ showModal, handleReload }) {
  const [loading, setLoading] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState({
    term1: false,
    term2: false,
    term3: false,
    term4: false,
  });
  const [disableButton, setDisableButton] = useState(true);

  const handleCheckedTerms = (e) => {
    let nameInput = e.target.name;
    setCheckedTerms({ ...checkedTerms, [nameInput]: !checkedTerms[nameInput] });
  };

  const handleDisableButton = () => {
    let isAllTermsCheckedTrue = Object.values(checkedTerms).every((term) => term === true);

    if (isAllTermsCheckedTrue) {
      return setDisableButton(false);
    }
    setDisableButton(true);
  };

  const logout = async () => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente sair do sistema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .post('/logout')
          .then(() => {
            let _registration = ReadDataStorage(REGISTRATION);
            localStorage.clear();
            WriteDataStorage(REGISTRATION, _registration);
            window.location.href = '/login';
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(false);
      await api
        .post('/acceptedterm')
        .then(() => {
          WriteDataStorage(ACCEPT_TERMS, true);
          handleReload();
        })
        .catch((error) => {
          ShowMessages(error);
        });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-accepted-terms'), null, 'Baixar');
      ShowMessages(response);
    }
  };

  useEffect(() => {
    handleDisableButton();
  }, [checkedTerms]);

  useEffect(() => {
    setStatistic('modals\\modal-acceptedterms');
  }, []);

  return (
    <Modal
      size="xl"
      show={showModal}
      onHide={logout}
      animation={true}
      backdrop="static"
      dialogClassName="w-50"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-user-shield ml-2"></i> Política de Privacidade
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} addClass="heigth330px" />
          <div className={`${loading ? 'hidden' : ''}`}>
            <Accordion className="my-4">
              <Accordion.Item eventKey="0" className="my-1">
                <Accordion.Header className="own-acordion">Politica de Privacidade</Accordion.Header>
                <Accordion.Body className="overflow-auto  maxHeight350">
                    <div class="col-md-12 mt-1">
                        <div class="mt-1">
                            <p>Nossa política de privacidade explica como as informações são coletadas, usadas e divulgadas pela <span style={{fontWeight:"bold"}}>55Zap</span> e são válidas para todos os usuários.</p>
                        </div>
  
                        <div class="mt-4">
                            <h5>Quais informações coletamos?</h5>
                            <p>Nós coletamos algumas informações que você nos fornece quando usa o<span style={{fontWeight:"bold"}}>55Zap</span>, por exemplo: Quando você cria o seu cadastro, nos envia um email, insere dados ou outro conteúdo, nós coletamos algumas informações pessoais como seu nome, endereço de email e número de telefone.</p>
                            <p>Nós também coletamos automaticamente determinadas informações quando você usa o <span style={{fontWeight:"bold"}}>55Zap</span>, tais como a sua localização/coordenadas GPS (se você habilitar este recurso), o identificador do dispositivo, endereço MAC, o endereço Internet Protocol (IP), sistema operacional, o tipo de navegador e sua atividade no <span style={{fontWeight:"bold"}}>55Zap</span>. Você pode ativar ou desativar serviços de localização quando você usa a <span style={{fontWeight:"bold"}}>55Zap</span> a qualquer momento, por meio de configurações do seu navegador. Esta informação não é tratada como informação pessoal a não ser que combine ou vincule isto com qualquer informação de identificação pessoal mencionado acima.</p>
                            <p>Também podemos coletar automaticamente determinadas informações através do uso de cookies. Estes são pequenos arquivos que o seu navegador coloca em seu computador. Nós podemos utilizar tanto session cookies como persistent cookies para entender melhor como você interage com os nossos serviços, monitorar o uso agregado por nossos usuários e roteamento de tráfego web nos nossos serviços, e melhorar os nossos serviços. A maioria dos navegadores aceitam cookies automaticamente. Você pode instruir seu navegador, editando estas opções para parar de aceitar os cookies ou consultar antes de aceitar um cookie dos sites que você visita.</p> 
                        </div>
  
                        <div class="mt-4">
                            <h5>Como nós utilizamos as informações coletadas?</h5>
                            <p>Nós usamos as informações coletadas através do <span style={{fontWeight:"bold"}}>55Zap</span> para os seguintes fins:</p>
                            <p>Para fornecer nossos serviços ou informações solicitadas, e para processar e completar todas as transações.</p>
                            <p>Responder seus email, apresentações, questões, comentários, pedidos, reclamações e prestar serviços ao cliente. </p>
                            <p>Monitorar e analisar maneiras de uso e tendências, personalizar e melhorar o <span style={{fontWeight:"bold"}}>55Zap</span> e suas experiências quando você usa o <span style={{fontWeight:"bold"}}>55Zap</span>, como fornecimento do conteúdo ou funcionalidades que correspondam ao seu perfil ou interesses (incluindo anúncios), e aumentar a funcionalidade e facilidade de uso dos nossos serviços. </p>
                            <p>Enviar confirmações, updates, alertas de segurança, suporte e mensagens administrativas, ajudando assim a facilitar a sua utilização, nossa administração e operação de nossos serviços.</p>
                            <p>Para qualquer outro propósito de cada informação que foi coletada.</p>
                        </div> 

                        <div class="mt-4">
                            <h5>Qual informação dividimos com terceiros?</h5>
                            <p>Nós não vamos dividir informações pessoais que nós coletamos de você, exceto nos casos descritos abaixo:</p>
                            <p>Para uso em anúncios e ferramentas de análise de dados para melhora nos serviços.</p>
                            <p>Com fornecedores de serviços que trabalham conosco em conexão como a operação do nosso site ou nossos serviços (esses fornecedores de serviços tem acesso as suas informações pessoais somente para executar serviços em nosso nome e são obrigados a não divulgá-las ou utilizá-las para outros fins).</p>
                            <p>Podemos compartilhar informações agregadas e informações sem-indentificação com terceiros para análises industriais, perfil demográfico e outros fins semelhantes.</p>
                            <p>Quando você nos dá consentimento para fazê-lo, incluindo se nós te notificamos de que as informações que você fornecer serão compartilhados de uma maneira particular e você fornecer tais informações.</p>
                            <p>Quando nós acreditamos de boa fé que estamos legalmente autorizados ou obrigados a fazer ou que isso seja razoavelmente necessário ou apropiado para cumprir com a lei, processos legais, responder a solicitações legais ou autoridades judiciais, incluindo responder a intimações, mandados legais ou ordens judiciais.</p>
                            <p>Para cumprir ou aplicar a nossa política de privacidade, nossos termos de uso, as nossas outras políticas ou acordos; e em conexão com, ou durante as negociações de, qualquer fusão, venda de ativos da empresa, financiamento ou aquisições, ou em qualquer outra situação onde as informações pessoais podem ser divulgadas ou transferidas como um dos nossos negócios.</p>
                            <p>Nós não somos responsáveis por essas ações do fornecedor de serviços ou de terceiros, nem somos responsáveis por qualquer informação adicional que você fornece diretamente para terceiros.</p>
                        </div>
  
                        <div class="mt-4">
                            <h5>Segurança</h5>
                            <p>Estamos muito preocupados em guardar a confidencialidade de suas informações pessoalmente identificáveis. Implementamos uma série de medidas de segurança administrativas, físicas e eletrônicas para a proteger suas informações contra acesso não autorizado.</p>
                            <p>Nós não vendemos, comerciamos, ou de outra forma, transferimos a terceiros as suas informações pessoalmente identificáveis.</p>
                        </div>
    
                        <div class="mt-4">
                            <h5>Links para outros sites</h5>
                            <p>O <span style={{fontWeight:"bold"}}>55Zap</span> contém links para outros sites. O fato de que um link para um site não é um endosso/aval, autorização ou representação da nossa filiação com esse terceiro. Nós não exercemos controle sobre sites de terceiros. Esses outros sites podem colocar seus próprios cookies ou outros arquivos no seu computador, coletar dados ou solicitar informações pessoais sobre você. Outros sites de seguir regras diferentes em relação à utilização ou divulgação das informações pessoais que você enviar para eles. Nós o incentivamos a ler as políticas de privacidade ou declarações de outros sites que você visita.</p>
                        </div>
  
                        <div class="mt-4">
                            <h5>Mudanças na Política</h5>
                            <p>Qualquer informação que é recolhida é sujeita a política de privacidade em vigor no momento em que essas informações são coletadas. Podemos, no entanto, modificar e rever a nossa política de privacidade de tempos a tempos. Ao continuar a acessar ou usar o <span style={{fontWeight:"bold"}}>55Zap</span> após essas alterações entrarem em vigor, você concorda em se comprometer com a política revisada.</p>
                        </div>
                    </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="my-1">
                <Accordion.Header className="own-acordion">Termo de Uso</Accordion.Header>
                <Accordion.Body className="overflow-auto my-1 maxHeight350">
                <div class="col-md-12 mt-1">
                    <div class=" mt-1">
                        <p>CONTRATO DE PRESTAÇÃO DE SERVIÇOS, TERMOS DE SERVIÇO E UTILIZAÇÃO DA 55ZAP</p>
                        <p>CASO O USUÁRIO NÃO CONCORDE COM ESTES TERMOS, ELE NÃO PODERÁ CONTRATAR OS SERVIÇOS COLOCADOS À DISPOSIÇÃO PELA PLATAFORMA 55ZAP DE AUTOMAÇÃO DE PERFIS NO WHATSAPP.</p>
                        <p>AO CONCORDAR COM OS TERMOS DAS PRESENTES CONDIÇÕES GERAIS, O USUÁRIO DECLARA TER LIDO INTEGRALMENTE ESTE CONTRATO, TORNANDO CERTO E PERFEITO O ATO DE CONTRATAÇÃO.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>1. CONDIÇÕES GERAIS DE USO</h5>
                        <p>Estas condições gerais (doravante denominadas “Condições Gerais”) regulamentam a utilização da plataforma de automação de perfis no WhatsApp oferecida pela 55Zap, registrada sob o endereço eletrônico https://www.55zap.com.br</p>
                        <p>Uma cópia atualizada destas Condições Gerais estará à disposição através do endereço https://www.55zap.com.br/termos-de-uso.</p>
                        <p>A expressa aceitação sem reservas quanto aos termos de uso atribui a condição de usuário do serviço(doravante, denominado “Usuário”) e declara a aceitação plena e sem reservas do usuário a cada uma das Condições Gerais na versão publicada pela 55Zap no exato momento em que utilizar o Serviço. Todos os demais avisos, regulamentos e instruções integram e complementam as presentes Condições Gerais.</p>
                    </div>
                    <div class=" mt-4">
                        <h5>2. OBJETO</h5>
                        <p>Este contrato regulamenta a utilização dos serviços da plataforma 55ZAP de automação de perfis no WhatsApp. A ferramenta colocará à disposição um painel administrativo para gerenciar a automação de ações no seu perfil do WhatsApp.</p>
                        <p>Os dados referentes aos conteúdos ficarão armazenados dentro do sítio da 55ZAP em servidores próprios ou contratados.</p>
                        <p>O Usuário é o único e exclusivo responsável por manter a confidencialidade de suas Senhas de Acesso e compromete-se a utilizá-las diligentemente, bem como a notificar aos administradores da plataforma, imediatamente, em caso de perda, roubo ou de qualquer uso não autorizado das mesmas.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>3. O SERVIÇO</h5>
                        <p> O Usuário, após concordar com as presentes Condições Gerais e Termos de Uso terá acesso à plataforma 55ZAP de gerenciamento.</p>
                        <p>Os dados referentes aos conteúdos gerenciados serão armazenados dentro do sítio e dos provedores da 55ZAP ou terceiros contratados.</p>
                    </div>
                    <div class=" mt-4">
                        <h5>4. CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SERVIÇO</h5>
                        <p>O Usuário reconhece que é essencial para utilização do serviço o preenchimento correto e obediência às regras impostas na ficha de cadastro (assinatura) na 55ZAP, obrigando-se a preenchê-lo com dados verdadeiros e isentando a 55ZAP de qualquer responsabilidade quanto a prejuízos gerados por dados falsos ou imprecisos que sejam fornecidos no referido Cadastro.</p>
                        <p>O Usuário compromete-se a utilizar o Serviço em conformidade com as presentes Condições Gerais e demais normas e regulamentos da 55ZAP aplicáveis ao serviço, bem como em conformidade com as Leis Brasileiras, a moral e os bons costumes e a ordem pública, utilizando o serviço de forma adequada se abstendo da prática de atos ilícitos, proibidos pela lei e pelas presentes Condições Gerais, lesivos aos direitos e interesses de terceiros, ou que, de qualquer forma, possa danificar, inutilizar, sobrecarregar ou deteriorar o serviço os servidores ou os equipamentos informáticos de outros Usuários ou de outros internautas (“hardware” e “software”) através da prática de “hacking”.</p>
                        <p>É responsabilidade do usuário a manutenção de cópias locais de seus arquivos, uma vez que o serviço prestado pela 55ZAP não contempla o serviço de backup dos materiais disponíveis em seu sítio, não sendo portanto a 55ZAP responsável por eventuais danos que o Usuário possa vir a sofrer decorrentes da perda, por qualquer motivo, do material inserido ou armazenado nos sítios da 55ZAP.</p>
                        <p>O serviço não poderá ser utilizado com a finalidade de armazenar, distribuir, transmitir, difundir ou Pôr à disposição de terceiros, qualquer tipo de conteúdo que, por si mesmo, ou cuja transmissão:</p>
                        <p>Contravenha, menospreze ou atente contra os direitos fundamentais e liberdades públicas e individuais reconhecidas no ordenamento jurídico brasileiro ou em tratados internacionais; induza, incite ou promova atuações delituosas, difamatórias, infamantes, violentas ou, contrárias à lei, à moral e aos bons costumes ou à ordem pública;</p>
                        <p>Induza, incite ou promova atuações, atitudes ou ideias discriminatórias em razão de sexo, raça, religião, crenças, idade ou condição social; incorpore, ponha à disposição ou permita acessar produtos, elementos, mensagens delituosas, violentas, pornográficas, degradantes ou, em geral, contrárias à lei, à moral e aos bons costumes ou à ordem pública; esteja protegido por quaisquer direitos de propriedade intelectual ou industrial pertencentes a terceiros, sem que o Usuário tenha obtido previamente dos seus titulares a autorização necessária para realizar o uso que efetua ou pretende efetuar; caso em que o Usuário será responsável por quaisquer danos, eximindo desde já a 55ZAP de qualquer responsabilidade ou corresponsabilidade; seja contrários ao direito, à honra, à intimidade pessoal e familiar ou à própria imagem das pessoas; constitua publicidade ilícita, enganosa ou desleal e, em geral, que constitua concorrência desleal; contribua, facilite ou incentive, de qualquer forma, a prática de quaisquer formas de infração aos direitos de propriedade intelectual de qualquer conteúdo disponibilizado na internet.</p>
                        <p>§ 1º Em qualquer das hipóteses acima, a 55ZAP poderá, a seu exclusivo critério, cancelar o registro do Usuário e eliminar o respectivo conteúdo, a qualquer tempo e sem qualquer aviso prévio, nem indenização.</p>
                        <p>§ 2º Qualquer decisão sobre a aplicação, ou não, das presentes Condições Gerais, será tomada segundo exclusivo critério da 55ZAP que poderá ainda, notificar as autoridades policiais e judiciais acerca da existência de conteúdo de Usuários que contenham qualquer classe de material que, a juízo da 55ZAP seja considerado ilegal, fornecendo, ainda, cópias desse conteúdo e demais documentos dos usuários, independentemente de prévio consentimento.</p>
                        <p>O usuário compromete-se a utilizar a plataforma de forma sã e bem intencionada, sendo assim, a 55ZAP reserva-se o direito de suspender quaisquer contas que façam abuso da plataforma através de quaisquer meios disponíveis (agendamento de publicações, automações etc) para quaisquer finalidades.</p>
                        <p>O Usuário declara desde já que é o único e exclusivo responsável pelo conteúdo por ele inserido, não tendo a 55ZAP qualquer responsabilidade ou controle sobre este. Em decorrência da presente declaração, o Usuário, desde já obriga-se, caso venha a ser chamado a responder judicialmente pela prática de um ato ilícito ou ilegal em decorrência dos referidos conteúdos, a isentar a 55ZAP de quaisquer responsabilidades, bem como requerer a exclusão da mesma do processo, se for o caso.</p>
                        <p>A 55ZAP reserva-se o direito de modificar, a qualquer momento, a apresentação e configuração do Serviço, assim como também as presentes Condições Gerais ou quaisquer outros regulamentos e normas que influenciem no Serviço.</p>
                        <p>Caso ocorram eventos de força maior que não permitam à 55ZAP comunicar com antecedência alterações ou eventuais falhas no serviço, o USUÁRIO neste ato permite que a 55ZAP suspenda a prestação do serviço e rescinda este contrato, ficando desde já acertado que esta informará o ocorrido e sua causa para ciência do USUÁRIO. A 55ZAP não reembolsará pagamentos efetuados pelo USUÁRIO caso haja interrupção temporária ou permanente do serviço, e neste ato o USUÁRIO expressamente concorda com tal política, sem quaisquer ressalvas.</p>
                        <p>O Usuário deverá manter sempre seu cadastro atualizado junto a 55ZAP, informando pelo menos um endereço de e-mail em funcionamento, para que possa receber, comunicados e avisos. Não somos responsáveis pelos comunicados não recebidos devidos ao configurações de email do usuário.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>5. USO E CUSTÓDIA</h5>
                        <p>O usuário ao ter o serviço ativado, informará um “login” e uma senha de acesso ao painel administrativo. Ele deverá alterar a senha periodicamente, guardando consigo a nova senha escolhida.</p>
                        <p>O Usuário compromete-se a fazer um uso diligente das Senhas de Acesso, assim como a mantê-las em sigilo, conforme demais avisos, regulamentos e instruções fornecidas pela 55ZAP.</p>
                        <p>O Usuário compromete-se a comunicar a 55ZAP, imediatamente, caso haja perda ou extravio das Senhas de Acesso assim como qualquer risco de acesso às mesmas por um terceiro.</p>
                        <p>A 55ZAP não se responsabiliza por senhas de acesso extraviadas, perdidas modificadas pelo Usuário, ou por senhas cuja combinação seja considerada muito “fraca”, apta a ser forçada ou adivinhada por qualquer forma.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>6. PROPRIEDADES</h5>
                        <p>Embora não tenha acesso direto ao banco de dados do programa da 55ZAP, pertence ao USUÁRIO o conteúdo (arquivos e conteúdo inseridos em razão deste contrato), podendo tal conteúdo ser acessado através do painel administrativo.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>7. DIREITOS DO USUÁRIO</h5>
                        <p>Ao contratar os serviços descritos pela 55ZAP, o Usuário possui os direitos:</p>
                        <p>Utilizar todos os serviços colocados à disposição pela plataforma objeto deste contrato;</p>
                        <p>O usuário declara neste ato estar ciente que ao cancelar a assinatura, o serviço será imediatamente interrompido e que não haverá ressarcimento de pagamento pelos dias não utilizados.</p>
                        <p>Hospedar os arquivos a ele referentes, enviar arquivos, utilizar bancos de dados, de acordo com as limitações impostas pelo serviço contratado;</p>
                        <p>O Usuário se responsabiliza por todo conteúdo colocado na plataforma, incluindo mas não limitado à direitos autorais de terceiros, de documentos inseridos, sejam fotos, vídeos ou textos.</p>
                        <p>O usuário declara neste ato estar ciente que em casos decorrentes de força maior, manutenção de emergência ou quedas inesperadas de serviço, não será possível notificá-lo com antecedência. A 55ZAP não oferece nenhum tipo de garantia ou reembolso advindo da instabilidade decorrentes de fatos descritos neste inciso.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>8. ALTERAÇÃO NO SERVIÇO</h5>
                        <p>O usuário tem conhecimento e concorda que o serviço pode mudar de tempos em tempos sem aviso prévio.</p>
                        <p>Mudanças incluem, sem limitação, alterações na taxa e condição de pagamento, patches de segurança, funcionalidades adicionadas ou removidas e outras melhorias ou restrições.</p>
                        <p>A plataforma não poderá ser responsabilizada pelo usuário ou terceira parte no caso de qualquer modificação, alteração de preço, suspenção ou descontinuação do serviço.</p>
                        <p>A 55ZAP pode alterar as suas taxas e condições de pagamento para o serviço, sem aviso prévio, respeitando todavia a vigência dos planos já contratados sem cobrança adicional.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>9. POLÍTICAS DE COBRANÇA E PAGAMENTO</h5>
                        <p>O serviço é prestado para o Usuário mediante pagamento de valores previamente estipulados de acordo com o tipo de serviço oferecido.</p>
                        <p>Sempre que possível a renovação da assinatura será realizada de forma automática, desde que o cartão de crédito tenha sido informado no momento da compra e o mesmo esteja apto para uso no dia da renovação.</p>
                        <p>O usuário concorda desde já manter-se em dia com os pagamentos acordados com a 55ZAP,estando ciente que o atraso no pagamento resultará na interrupção dos serviços.</p>
                        <p>Para realizar o cancelamento da assinatura, é necessário enviar um e-mail para contato@55zap.com.br com a solicitação do cancelamento até 48 horas antes do início do novo ciclo para evitar que a renovação automática seja processada.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>10. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</h5>
                        <p>A 55ZAP exime-se de quaisquer responsabilidades quanto a danos e prejuízos de qualquer natureza porventura advindos devidos à indisponibilidade ou falta de continuidade do serviço.</p>
                        <p>A 55ZAP eximi-se de quaisquer responsabilidades quanto ao uso de automações no perfil. Ao utilizar automações em seus perfis no WhatsApp, o usuário compreende que está colocando em potencial risco suas contas e que as políticas do WhatsApp podem mudar a qualquer momento, resultando em exclusão total, permanente e irrecuperável do perfil.</p>
                        <p>Os dados do Usuário serão armazenados com todo o sigilo e somente serão manipulados por funcionários da 55ZAP. No entanto, A 55ZAP não garante a privacidade e a segurança na utilização do Serviço por parte do Usuário, e portanto, não pode garantir que terceiros não autorizados não possam acessar e, eventualmente, interceptar, eliminar, alterar, modificar ou manipular de qualquer modo o conteúdo posto à disposição no Serviço ou interceptar, eliminar, alterar, modificar ou manipular de qualquer modo os Arquivos e comunicações de qualquer classe que o Usuário transmita, armazene ou ponha à disposição de terceiros através do Serviço.</p>
                        <p>A 55ZAP se exime de toda responsabilidade pelos danos e prejuízos de qualquer natureza decorrente do acesso, interceptação, eliminação, alteração, modificação ou manipulação dos arquivos e comunicações armazenados, transmitidos ou postos à disposição por terceiros não autorizados a utilizar o Serviço.</p>
                    </div>

                    <div class=" mt-4">
                        <h5>11. ATENDIMENTO E SUPORTE TÉCNICO</h5>
                        <p>A 55ZAP prestará suporte técnico para seus usuários, obedecendo às seguintes condições e limitações:</p>
                        <p>A 55ZAP esclarecerá dúvidas e prestará informações simples a usuários através do email contato@55zap.com.br</p>
                    </div>

                    <div class=" mt-4">
                        <h5>12. FINALIZAÇÃO</h5>
                        <p>Os serviços disponíveis na 55ZAP ficarão disponíveis para o usuário pelo prazo definido no plano contratado até impedimento imposto por viés tecnológico (atualizações nos serviços do WhatsApp que impeçam a realização do serviço) ou até o final da existência do serviço, se este for ilimitado.</p>
                    </div>
                </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="checkbox-group">
              <div className="d-flex my-1">
                <label className="switch switch-sm">
                  <input
                    name="term1"
                    type="checkbox"
                    checked={checkedTerms.term1}
                    value={checkedTerms.term1}
                    onChange={(e) => handleCheckedTerms(e)}
                  />
                  <span></span>
                </label>

                <span className="mx-3">Declaro que li os <span style={{fontWeight:"bold"}}>TERMOS DE USO</span> e a <span style={{fontWeight:"bold"}}>POLÍTICA DE PRIVACIDADE</span> da 55Zap</span>
              </div>
              <div className="d-flex my-1">
                <label className="switch switch-sm">
                  <input
                    name="term2"
                    // defaultChecked={checkedTerms.term2}
                    type="checkbox"
                    checked={checkedTerms.term2}
                    value={checkedTerms.term2}
                    onChange={(e) => handleCheckedTerms(e)}
                  />
                  <span></span>
                </label>

                <span className="mx-3">Me comprometo a não utilizar essa ferramenta para atos ilícitos que hajam contra o código penal brasileiro.</span>
              </div>
              <div className="d-flex my-1">
                <label className="switch switch-sm">
                  <input
                    name="term3"
                    type="checkbox"
                    checked={checkedTerms.term3}
                    value={checkedTerms.term3}
                    onChange={(e) => handleCheckedTerms(e)}
                  />
                  <span></span>
                </label>

                <span className="mx-3">O conteúdo enviando e de minha inteira responsabilidade</span>
              </div>
              <div className="d-flex my-1">
                <label className="switch switch-sm">
                  <input
                    name="term4"
                    type="checkbox"
                    checked={checkedTerms.term4}
                    value={checkedTerms.term4}
                    onChange={(e) => handleCheckedTerms(e)}
                  />
                  <span></span>
                </label>

                <span className="mx-3">Estou ciente que a 55Zap não e responsável por números banidos da plataforma do WhatsApp</span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-accepted-terms"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
            disabled={disableButton}
          >
            <em className="fa fa-check"></em> Aceitar e Continuar
          </Button>
          <Button className="btn-ligth-gray" onClick={logout}>
            <em className="fa fa-door-closed"></em> Sair
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}

ModalAcceptedTerms.propTypes = {
  handleReload: P.func.isRequired,
  showModal: P.bool.isRequired,
};
