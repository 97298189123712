import { memo } from 'react';
import { Container } from 'react-bootstrap';
import CarouselImg from './CaroulselImg/carousel-img';

// import CurrencyInput from '../../moneyInput'

const Atachments = () => {
    return (
        <Container fluid>
            <CarouselImg />
        </Container>
    );
};

export default memo(Atachments);
