import React, { useState, useEffect } from 'react';
import ContentWrapper from '../layouts/contentWrapper';
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalProduct from '../modals/modal-product';
import ModalSummarizedProducts from '../modals/modal-summarized-product';
import Grid from '../../components/Grid';
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic';
import { checkPermission } from '../../components/security';
import Swal from 'sweetalert2';

export default function FiscalDocument() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [print, setPrint] = useState(false);
  const [excel, setExcel] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [editState, setEditState] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [data, setData] = useState({});
  const [filters, setFilters] = useState([]);

  const [editPermission, setEditPermisson] = useState(false);

  const getProduct = async () => {
    let _delCheck = !checkPermission(4, 16);
    let _editCheck = !checkPermission(8, 16);

    setGridParams({
      idproduct: {
        text: '#ID',
        mask: {
          name: 'leftSlice',
          value: '00000',
        },
        primaryKey: true,
      },
      code: {
        text: 'Código',
      },
      description: {
        text: 'Produto',
      },
      brand: {
        text: 'Marca',
      },
      ncm: {
        text: 'NCM',
      },
      sale_price: {
        text: 'Preço de Venda',
        dataType: 'number',
        className: 'text-right',
        item: { className: 'text-right' },
      },
      Options: {
        text: 'Opções',
        className: 'text-center',
        order: false,
        filter: false,
        item: [
          {
            type: 'Custom',
            props: {
              tag: 'button',
              title: 'Exclusão de produto',
              className: 'btn btn-sm btn-danger',
              icon: 'fa fa-trash',
              disabled: _delCheck,
              indexcallback: 0,
            },
          },
          {
            type: 'Custom',
            props: {
              tag: 'button',
              title: 'Editar produto',
              disabled: _editCheck,
              className: 'btn btn-sm btn-warning',
              visualConditional: {
                idpeoplestatus: {
                  operator: 'not in',
                  values: [3, 5],
                },
              },
              icon: 'fa fa-edit',
              indexcallback: 1,
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    getProduct();
    setStatistic('maintenance\\products');
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const RemoveProduct = async (isConfirm, swal, id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente excluir o produto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .delete('/products/' + id)
          .then((response) => {
            ShowMessages(response.data);
            setReload(true);
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  const EditProduct = async (id) => {
    await api
      .get('/products/' + id)
      .then((response) => {
        setData(response.data);
        setEditState(true);
        setShowModal(true);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/#"
      ref={ref}
      className="mnp-name"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </a>
  ));

  return (
    <ContentWrapper>
      <Loading loading={loading} />
      <ModalProduct
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        editState={editState}
        data={data}
        handleReload={setReload}
      />

      {/* <ModalSummarizedProducts
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        editState={editState}
        data={data}
        handleReload={setReload}
      /> */}

      <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
        <div className="content-heading">
          <div className="box-maintance-buttons">
            <div>
              <button
                className="btn btn-success"
                title="Adicionar Dados"
                style={{ marginLeft: '3px' }}
                onClick={(e) => {
                  setEditState(false);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-plus"></i>
              </button>
              <button
                className="btn btn-primary"
                title="Atualizar Dados"
                style={{ marginLeft: '3px' }}
                onClick={(e) => setReload(true)}
              >
                <i className="fa fa-redo"></i>
              </button>
              {showButtons ? (
                <>
                  <button
                    className="btn btn-dark"
                    title="Impressão"
                    onClick={(e) => setPrint(true)}
                    style={{ marginLeft: '3px' }}
                  >
                    <i className="fa fa-print"></i>
                  </button>
                  <button
                    className="btn btn-info"
                    disabled={!checkPermission(16, 4)}
                    title="Exportar para excel"
                    onClick={(e) => setExcel(true)}
                    style={{ marginLeft: '3px' }}
                  >
                    <i className="fa fa-file-excel"> </i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    title="Filtros Avançados"
                    style={{ marginLeft: '3px' }}
                    onClick={(e) => handleFilter()}
                  >
                    <i className="fa fa-search-plus"></i>
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`content-box-cart ${loading ? 'hidden' : ''}`}>
        <Filter
          isOpenFilter={isOpenFilter}
          filters={gridParams}
          callback={setFilters}
          callBackOpenFilter={handleFilter}
        />
        <Grid
          url="/products"
          headers={gridParams}
          loading={setLoading}
          reload={reload}
          print={print}
          excel={excel}
          filters={filters}
          callbackShowButtons={setShowButtons}
          callbackPrint={setPrint}
          callbackReload={setReload}
          callbackExcel={setExcel}
          filename="Produtos"
          callbackCellClick={editPermission ? undefined : EditProduct}
          callbackButtons={[RemoveProduct, EditProduct]}
        />
      </div>
    </ContentWrapper>
  );
}
