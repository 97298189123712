import '../../styles/app/common/pdv.scss';
import ProductForm from './PDVForm';
import ProductList from './PDVList';

export const Product = () => {
  return (
    <div className="w-100">
      <ProductForm />
      <ProductList />
    </div>
  );
};
