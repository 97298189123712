import React, { useState } from 'react'
import { Button, Modal, ModalDialog } from 'react-bootstrap'
import Draggable from 'react-draggable';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function modalSearchClient({showModal, handleCloseModal,}) {
    
    return (
    <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="height550 width775" dialogAs={DraggableModalDialog}>
            <Modal.Header closeButton>
                <Modal.Title style={{marginBottom:"0px"}}>
                    <i className="fa fa-users"></i> Buscar cliente
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id='submit-client' className={`btn-theme `} >
                    <em className="fa fa-save"></em> Salvar
                </Button>          
                <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
    </Modal>
  )
}
