import React, { memo, useState } from 'react';
import { Container, FormControl } from 'react-bootstrap';

const Stock = () => {
  const [hasAuthorization, setHasAuthorization] = useState(true);
  const [branches, setBranches] = useState([
    {
      id: '1',
      description: 'Loja 1',
      cnpj: '123.123.123/0001-10',
      stockAmount: 10,
      newStock: '',
    },
    {
      id: '2',
      description: 'Loja 2',
      cnpj: '123.523.123/0001-22',
      stockAmount: 110,
      newStock: '',
    },
    {
      id: '3',
      description: 'Loja 3',
      cnpj: '123.523.222/0001-40',
      stockAmount: 4,
      newStock: '',
    },
  ]);

  return (
    <Container fluid>
      <table className="table table-striped w-100">
        <thead>
          <tr>
            <th scope="col">Descrição</th>
            <th scope="col">CNPJ</th>
            <th scope="col">Estoque Atual</th>
            {hasAuthorization && <th scope="col">Novo Estoque</th>}
          </tr>
        </thead>
        <tbody>
          {branches.map((branch) => (
            <tr key={branch.id} className="head-th text-dark">
              <td>{branch.description}</td>
              <td>{branch.cnpj}</td>
              <td>{branch.stockAmount}</td>
              {hasAuthorization && (
                <>
                  <td>
                    <div className="input-group">
                      <FormControl
                        name={`branch.newStock`}
                        id={`branch.newStock`}
                        type="number"
                        min="0"
                        autoComplete="off"
                        disabled={!hasAuthorization}
                      />
                    </div>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default memo(Stock);
