import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import ShowMessages from '../../components/Exceptions'
import api from '../../services/api'

export default function ReportBill(props) {
    const [loading, setLoading]                                 = useState(false)
    const [urlLogo, setURLLogo]                                 = useState('')
    const [company, setCompany]                                 = useState('')
    const [cnpj, setCNPJ]                                       = useState('')
    const [address, setAddress]                                 = useState('')
    const [city, setCity]                                       = useState('')
    const [cep, setCEP]                                         = useState('')
    const [phone, setPhone]                                     = useState('')
    const [email, setEmail]                                     = useState('')

    const [idInstallmentReceivable, setidInstallmentReceivable] = useState('')

    const[clientName, setClientName]                            = useState('')
    const[clientCPF, setClientCPF]                              = useState('')
    const[clientFone, setClientFone]                            = useState('')
    const[clientEmail, setClientEmail]                          = useState('')
    const[clientAddress, setClientAddress]                      = useState('')
    const[clientNumber, setClientNumber]                        = useState('')
    const[clientComplement, setClientComplement]                = useState('')
    const[clientNeighborhood, setClientNeighborhood]            = useState('')
    const[clientCity, setClientCity]                            = useState('')
    const[clientUF, setClientUF]                                = useState('')
    const[clientCEP, setClientCEP]                              = useState('')

    const [products, setProducts]                               = useState([])

    const [total, setTotal]                                     = useState(0)
    const [duedate, setDueDate]                                 = useState('')
    const [comments, setComments]                               = useState('')
    const [reference, setReference]                             = useState('')
    const [invoicestatus, setInvoiceStatus]                     = useState('')

    const getBill = async(id) => {
        setLoading(true)
        await api.post('/bills-sumary-report/' + id)
                 .then(response => { 
                     let _company  = response.data.data.company[0]
                     let _data     = response.data.data.data[0]

                     setURLLogo(_company.logobase64)
                     setCompany(_company.company)
                     setCNPJ(_company.cnpj)
                     setAddress(_company.addresses.street + ' ' + _company.addresses.complement + ' ' + _company.addresses.number + ' ' + _company.addresses.neighborhood)
                     setCity(_company.addresses.city + ' - ' + _company.addresses.uf)
                     setCEP(_company.addresses.cep)
                     setPhone(_company.phones)
                     setEmail(_company.email)
                     setLoading(false)  

                     setidInstallmentReceivable(_data.idinstallmentreceivable.toString().padStart(5,"0"))
                     setClientName(_data.name)
                     setClientCPF(_data.cpf)
                     setClientFone(_data.phones)
                     setClientEmail(_data.email)
                     setClientAddress(_data.addresses.street)
                     setClientNumber(_data.addresses.number)
                     setClientComplement(_data.addresses.complement)
                     setClientNeighborhood(_data.addresses.neighborhood)
                     setClientCity(_data.addresses.city)
                     setClientUF(_data.addresses.uf)
                     setClientCEP(_data.addresses.cep)

                     setProducts(_data.products)

                     setTotal(_data.total)
                     setDueDate(_data.duedate)
                     setComments(_data.comments)
                     setReference(_data.reference)
                     setInvoiceStatus(_data.invoicestatus)
                 }).catch(error => {
                    ShowMessages(error)
                 })   
        
    }

    const printer = () => {
        let _area   = document.querySelector('#bill-container')
        let _window = window.open('', 'PRINT', 'height=600,width=800');

        _window.document.write(_area.innerHTML);

        _window.document.close();
        _window.focus();

        _window.print();
        _window.close();

        return true;
    }

    useEffect(() => {
        getBill(props.id)
        setStatistic('report\\report-bill')
    },[])
    

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <div id="bill-container" style={{width: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div className="bill-printer-header" style={{width: "620px", border: "1px solid #cacaca", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", marginTop: "25px", display: "flex", borderBottom: "0px"}}>
                    <div style={{width: "150px", padding:"10px", display: "flex", justifyContent: "center", alignRtems: "center"}}>
                        <img src={urlLogo} alt="" style={{width: "90%"}}/>
                    </div>  
                    <div style={{display: "flex", flexDirection: "column", width: "450px", alignItems: "flex-end", padding: "10px"}}>
                        <div>
                            <span style={{fontWeight:"bold",textTransform:'uppercase'}}>{company}</span>
                        </div>
                        <div>
                            <span>{cnpj}</span>
                        </div>
                        <div>
                            <span>{address}</span>
                        </div>
                        <div>
                            <span>{city} | {cep}</span>
                        </div>
                        <div>
                            <span>{phone} | {email}</span>
                        </div>
                    </div> 
                </div> 
                <div className="bill-printer-header" style={{width: "606px", border: "1px solid #cacaca", display: "flex", justifyContent: 'center',padding: "7px"}}>
                    <span style={{fontWeight:"bold",fontSize:"20px"}}>Fatura de Serviços n° #{idInstallmentReceivable}</span>
                </div>                    
                <div style={{width: "600px", padding:"10px",borderLeft:"1px solid #cacaca", borderRight : "1px solid #cacaca"}}>
                    <span style={{fontWeight:"bold",marginBottom:"15px"}}>Dados do Cliente</span>
                    
                    <div style={{width:"300px",border:"1px solid #cacaca"}}></div>    
                    <div style={{marginTop:"10px"}}>
                        <div>
                            <span style={{fontWeight:"bold"}}>Cliente:</span><span> {clientName}</span>
                        </div>
                    </div>
                    <div style={{marginTop: "5px", display: "flex",justifyContent: "space-between"}}>
                        <div>
                            <span style={{fontWeight:"bold"}}>CPF/CNPJ:</span><span> {clientCPF}</span>
                        </div>
                    </div>
                    <div style={{marginTop: "5px", display: "flex",justifyContent: "space-between"}}>
                        <div>
                            <span style={{fontWeight:"bold"}}>Fone:</span><span> {clientFone}</span>
                        </div>
                        <div>
                            <span style={{fontWeight:"bold"}}>Email:</span><span> {clientEmail}</span>
                        </div>
                    </div>
                    <div style={{marginTop: "5px", display: "flex",justifyContent: "space-between"}}>
                        <div>
                            <span style={{fontWeight:"bold"}}>Endereço:</span><span> {clientAddress}</span>
                        </div>
                        <div>
                            <span style={{fontWeight:"bold"}}>N°:</span><span> {clientNumber}</span>
                        </div>
                    </div>
                    <div style={{marginTop: "5px", display: "flex",justifyContent: "space-between"}}>
                        <div>
                            <span style={{fontWeight:"bold"}}>Complemento:</span><span> {clientComplement}</span>
                        </div>
                        <div>
                            <span style={{fontWeight:"bold"}}>Bairro:</span><span> {clientNeighborhood}</span>
                        </div>
                    </div>
                    <div style={{marginTop: "5px", display: "flex",justifyContent: "space-between"}}>
                        <div>
                            <span style={{fontWeight:"bold"}}>Cidade:</span><span> {clientCity}</span>
                        </div>
                        <div>
                            <span style={{fontWeight:"bold"}}>UF:</span><span> {clientUF}</span>
                        </div>
                        <div>
                            <span style={{fontWeight:"bold"}}>CEP:</span><span> {clientCEP}</span>
                        </div>
                    </div>
                </div> 
                <div style={{width: "600px", padding:"10px",borderLeft:"1px solid #cacaca", borderRight : "1px solid #cacaca"}}> 
                    <div style={{marginTop:"10px", display:"flex"}}>
                        <div style={{width:"240px"}}>
                            <span style={{fontWeight:"bold"}}>Serviços</span>
                        </div>
                        <div style={{width:"240px"}}> 
                            <span style={{fontWeight:"bold"}}>Plano</span>
                        </div>
                        <div style={{width:"120px",textAlign:"right"}}>
                            <span style={{fontWeight:"bold"}}>Valor do Serviço</span>
                        </div>
                    </div>
                    <div style={{marginTop:"10px", display:"flex"}}>
                        {products.map((item) => {
                            return (
                                <>
                                    <div style={{width:"240px"}}>
                                        <span>{item.product}</span>
                                    </div>
                                    <div style={{width:"240px"}}>
                                        <span>{item.plan}</span>
                                    </div>
                                    <div style={{width:"120px",textAlign:"right"}}>
                                        <span>{item.value}</span>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div style={{width: "600px", borderBottom: "1px solid #cacaca", borderLeft:"1px solid #cacaca", borderRight:"1px solid #cacaca",padding:"10px"}}>
                    <span style={{fontWeight:"bold",marginBottom:"15px"}}>Dados de Pagamento</span>
                    
                    <div style={{width:"300px",border:"1px solid #cacaca"}}></div>  
                    <div style={{display:"flex", justifyContent:"space-between", marginTop:"10px"}}>  
                        <div>
                            <span style={{fontWeight:"bold"}}>Valor Total da Fatura:</span><span> R$ {total}</span>
                        </div>
                    
                        <div style={{marginTop: "5px", display: "flex",justifyContent: "space-between"}}>
                            <div>
                                <span style={{fontWeight:"bold"}}>Vencimento:</span><span> {duedate}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div style={{display: "flex",justifyContent: "space-between"}}>
                            <div>
                                <span style={{fontWeight:"bold"}}>Referência:</span><span> {reference}</span>
                            </div>
                        </div>
                        <div style={{display: "flex",justifyContent: "space-between"}}>
                            <div>
                                <span style={{fontWeight:"bold"}}>Situação:</span><span> {invoicestatus} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width: "600px", borderBottom: "1px solid #cacaca", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",borderLeft:"1px solid #cacaca", borderRight:"1px solid #cacaca",padding:"10px"}}>
                    <div>
                        <span style={{fontWeight:"bold"}}>Observação</span><span>{comments}</span>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "25px"}}>
                <button className="btn btn-dark" title="Imprimir Fatura" onClick={e => printer(e)}><i className="fa fa-print"/></button>
            </div>
        </ContentWrapper>
    )
}