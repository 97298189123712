import { memo } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const genericArray = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const CteCargoInformation = () => {
    const { control, register } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'cargoInformation.characteristics', // unique name for your Field Array
    });

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="cargoInformation-amount">Valor carga(R$)</label>
                    <div className="input-group">
                        <Controller
                            name="cargoInformation.amount"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="cargoInformation.amount"
                                    id="cargoInformation.amount"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="cargoInformation-principalProduct">Produto predominate</label>
                    <div className="input-group">
                        <FormControl
                            {...register('cargoInformation.principalProduct')}
                            name="cargoInformation.principalProduct"
                            id="cargoInformation-principalProduct"
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="cargoInformation-otherProductCharacteristic">
                        Outras características do produto
                    </label>
                    <div className="input-group">
                        <FormControl
                            {...register('cargoInformation.otherProductCharacteristic')}
                            name="cargoInformation.otherProductCharacteristic"
                            id="cargoInformation-otherProductCharacteristic"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                {fields.map((field, index) => (
                    <Row key={field.id} className="d-flex align-items-center mb-2">
                        <Col
                            sm={1}
                            md={1}
                            lg={1}
                            className="badge text-bg-primary align-self-end"
                            style={{ width: '30px' }}
                        >
                            <span style={{ fontSize: '1.3rem' }}>{index + 1 + '.'}</span>
                        </Col>
                        <Col lg={3} md={3}>
                            <div className="input-group">
                                <label htmlFor="characteristics-measurementUnit">Unidade de medida</label>
                                <FormSelect
                                    {...register(`cargoInformation.characteristics.${index}.measurementUnit`)}
                                    id="characteristics-measurementUnit"
                                    className="w-100"
                                >
                                    <option value="">Selecione</option>

                                    {genericArray.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </FormSelect>
                            </div>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className="input-group">
                                <label htmlFor="characteristics-measurementType">Tipo de medida</label>
                                <FormControl
                                    {...register(`cargoInformation.characteristics.${index}.measurementType`)}
                                    name={`cargoInformation.characteristics.${index}.measurementType`}
                                    id="characteristics-measurementType"
                                    type="text"
                                    key={field.id}
                                    className="form-control"
                                />
                            </div>
                        </Col>
                        <Col lg={3} md={3}>
                            <div className="input-group">
                                <label htmlFor="characteristics-cargoQuantity">Quantidade de carga</label>
                                <FormControl
                                    {...register(`cargoInformation.characteristics.${index}.cargoQuantity`)}
                                    name={`cargoInformation.characteristics.${index}.cargoQuantity`}
                                    id="characteristics-cargoQuantity"
                                    type="number"
                                    key={field.id}
                                />
                            </div>
                        </Col>
                        <Col sm={1} md={1} lg={1} className="align-self-end">
                            <button className="btn btn-danger" onClick={() => remove(index)}>
                                <i className="fa-solid fa-trash" />
                            </button>
                        </Col>
                    </Row>
                ))}
                <div className="my-2">
                    <button
                        type="button"
                        title="adicionar produto"
                        className="btn btn-success font-weight-bold me-2"
                        onClick={() =>
                            append({
                                measurementUnit: '',
                                measurementType: '',
                                cargoQuantity: '',
                            })
                        }
                    >
                        <i className="fa-solid fa-plus" />
                    </button>
                </div>
            </Row>
        </Container>
    );
};

export default memo(CteCargoInformation);
