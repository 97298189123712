import React, {useState, useEffect} from 'react'
import { ValidationForm, TextInput} from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalMiner({showModal, handleCloseModal, data, handleReload}) {
    const [loading, setLoading]                 = useState(false)
    const [loadingCheck, setLoadingCheck]       = useState(false)
    const [name, setName]                       = useState('')
    const [email, setEmail]                     = useState('')
    const [phone, setPhone]                     = useState('')
    const [address, setAdrress]                 = useState('')

    const setData = (obj,clear) => {
        setName(clear ? '' : obj?.name)
        setEmail(clear ? '' : obj?.email)
        setPhone(clear ? '' : obj?.phone)
        setAdrress(clear ? '' : obj?.address)

    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        loadingButton(true,document.querySelector('#submit-miner'),document.querySelector('#submit-miner').children[0].classList,document.querySelector('#submit-miner').lastChild.nodeValue)

        try {
            let _data = {name,
                         email,
                         phone,
                         address}

            await api.put('/candidate/' + data.idcandidate, _data)
                     .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-miner'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-miner'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-miner'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-miner'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setData(data,false)
    },[data,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-miner')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-address-book"></i> Propriedades do Candidato
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>  
                        <div style={{display:"flex", gap:"5px"}}>
                            <div style={{width:"250px"}}>
                                <label>Nome</label>
                                <TextInput
                                    name="name"
                                    id="name"
                                    type="text"
                                    required
                                    maxLength={60}
                                    errorMessage={{ required: "Por favor, informe um nome" }}
                                    value={name}
                                    className="form-control "
                                    onChange={e => setName(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div style={{display:"flex",gap:"5px"}}>
                            <div style={{width:"250px"}}>
                                <label>Email</label>
                                <TextInput
                                    name="email"
                                    id="email"
                                    type="text"
                                    required
                                    maxLength={150}
                                    errorMessage={{ required: "Por favor, informe um email" }}
                                    value={email}
                                    className="form-control "
                                    onChange={e => setEmail(e.target.value)}
                                    autoComplete="off"
                                />
                            </div> 
                        </div>     
                        <div style={{display:"flex",gap:"5px"}}>
                            <div style={{width:"250px"}}>
                                <label>Telefones</label>
                                <TextInput
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    required
                                    maxLength={150}
                                    errorMessage={{ required: "Por favor, informe um telefone" }}
                                    value={phone}
                                    className="form-control "
                                    onChange={e => setPhone(e.target.value)}
                                    autoComplete="off"
                                />
                            </div> 
                        </div>             
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-miner" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal} style={{marginLeft:"15px"}}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button> 
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}