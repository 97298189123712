import { memo } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import MaskInput from '../../Forms/Inputs/maskedInput';

const CteCargoInformation = () => {
    const { control, register } = useFormContext();

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="icmsSharing-icmsValueCalculationBasis">Valor de base de cáculo do ICMS</label>
                    <div className="input-group">
                        <Controller
                            name="icmsSharing.icmsValueCalculationBasis"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="icmsSharing.icmsValueCalculationBasis"
                                    id="icmsSharing.icmsValueCalculationBasis"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="icmsSharing-endUfInternalAliquot">Aliquota interna da UF de témino</label>
                    <div className="input-group">
                        <FormControl
                            {...register('icmsSharing.endUfInternalAliquot')}
                            name="icmsSharing.endUfInternalAliquot"
                            id="icmsSharing-endUfInternalAliquot"
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="icmsSharing-interstateAliquot">Aliquota interestadual</label>
                    <div className="input-group">
                        <FormControl
                            {...register('icmsSharing.interstateAliquot')}
                            name="icmsSharing.interstateAliquot"
                            id="icmsSharing-interstateAliquot"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="icmsSharing-endUfPercentageSharing">partilha para a UF de término(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('icmsSharing.endUfPercentageSharing')}
                            name="icmsSharing.endUfPercentageSharing"
                            id="icmsSharing-endUfPercentageSharing"
                        />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <label htmlFor="icmsSharing-startUfIcmsSharingValue">
                        Valor do ICMS de partilha para a UF de início
                    </label>
                    <div className="input-group">
                        <Controller
                            name="icmsSharing.startUfIcmsSharingValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="icmsSharing.startUfIcmsSharingValue"
                                    id="icmsSharing.startUfIcmsSharingValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="icmsSharing-endUfIcmsSharingValue">
                        Valor do ICMS de partilha para a UF de início
                    </label>
                    <div className="input-group">
                        <Controller
                            name="icmsSharing.endUfIcmsSharingValue"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="icmsSharing.endUfIcmsSharingValue"
                                    id="icmsSharing.endUfIcmsSharingValue"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="icmsSharing-endUfIcmsPercentageFCP"> ICMS ao F.C.P na UF de término(%)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('icmsSharing.endUfIcmsPercentageFCP')}
                            name="icmsSharing.endUfIcmsPercentageFCP"
                            id="icmsSharing-endUfIcmsPercentageFCP"
                        />
                    </div>
                </Col>
                <Col lg={4} md={4} sm={12}>
                    <label htmlFor="icmsSharing-endUfIcmsValueFCP">Valor do ICMS ao F.C.P na UF de término</label>
                    <div className="input-group">
                        <Controller
                            name="icmsSharing.endUfIcmsValueFCP"
                            control={control}
                            render={({ field }) => (
                                <MaskInput
                                    name="icmsSharing.endUfIcmsValueFCP"
                                    id="icmsSharing.endUfIcmsValueFCP"
                                    mask="currency"
                                    field={field}
                                    className={`text-right`}
                                    autoComplete="off"
                                />
                            )}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default memo(CteCargoInformation);
