import { yupResolver } from '@hookform/resolvers/yup';
import React, { memo, useEffect, useState } from 'react';
import { Button, Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import clsx from 'clsx';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';

import { FormProvider, useForm } from 'react-hook-form';
import Atachments from '../../components/Products/Atachments';
import FiscalTributary from '../../components/Products/FiscalTributary';
import General, {
  getGeneralInfoProductSchema,
} from '../../components/Products/General';
import Kit, { getKitSchema } from '../../components/Products/Kit';
import Providers from '../../components/Products/Providers';
import { defaultValuesProduct } from '../../utils/defaultValues';
import Stock from '../../components/Products/Stock';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

const ModalProducts = ({ showModal, handleCloseModal, editState, data }) => {
  const schema = getKitSchema.concat(getGeneralInfoProductSchema);
  const { reset, ...methods } = useForm({
    mode: 'all',
    defaultValues: defaultValuesProduct,
    resolver: yupResolver(schema),
  });

  const isKit = methods.watch('kitStructure.isKit');

  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [tabHasError, setTabHasError] = useState({
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false,
  });
  const [showInventory, setShowInventory] = useState(true);
  const [showLocation, setShowLocation] = useState(false);

  const [dataProductType, setDataProductType] = useState([]);
  const [dataUnit, setDataUnit] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const [dataProductStatus, setDataProductStatus] = useState([]);
  const [dataProductLocation, setDataProductLocation] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const handleErrorsOnTab = () => {
      let tabWithErrorsState = { ...tabHasError };

      methods.formState.errors?.product !== undefined
        ? (tabWithErrorsState.tab1 = true)
        : (tabWithErrorsState.tab1 = false);

      methods.formState.errors?.providers !== undefined
        ? (tabWithErrorsState.tab2 = true)
        : (tabWithErrorsState.tab2 = false);

      methods.formState.errors?.kitStructure !== undefined
        ? (tabWithErrorsState.tab3 = true)
        : (tabWithErrorsState.tab3 = false);

      methods.formState.errors?.fiscalTributary !== undefined
        ? (tabWithErrorsState.tab4 = true)
        : (tabWithErrorsState.tab4 = false);

      setTabHasError(tabWithErrorsState);
    };

    handleErrorsOnTab();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.errors]);

  const setData = (obj, clear) => {
    if (clear) {
      reset(defaultValuesProduct);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api
        .get('/getProductsData')
        .then((response) => {
          if (response.ok) {
            setShowInventory(
              response.data.companysettings.enabledInventory || false,
            );
            setDataProductType(response.data.productype || []);
            setDataUnit(response.data.unit || []);
            setDataOrigin(response.data.origin || []);
            setDataProductStatus(response.data.productstatus || []);
            setDataProductLocation(response.data.productlocation || []);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editState, showModal]);

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-product');
  }, []);

  const getLocation = async () => {
    try {
      await api
        .get('/getProductsData')
        .then((response) => {
          if (response.ok) {
            setDataProductLocation(response.data.productlocation || []);
          }
        })
        .catch((error) => {
          ShowMessages(error);
        });
    } catch (response) {
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (!showLocation) getLocation();
    setReload(false);
  }, [showLocation, reload]);

  // const handleShowModalLocation = (value) => {
  //   if (value) {
  //     if (!checkPermission(1, 17) || !checkPermission(2, 17)) {
  //       ShowMessages({
  //         message: "Você não tem permissão para executar essa operação",
  //         status: 500,
  //       });
  //       return;
  //     }
  //     document.querySelector("#modal-product").style.opacity = 0.1;
  //   } else document.querySelector("#modal-product").style.opacity = 1;

  //   setShowLocation(value);
  // };
  const handleSubmitForm = async (data, e) => {
    setShowLocation(false);
    e.stopPropagation();
    console.log(
      '🚀 ~ file: modal-product.js ~ line 232 ~ handleSubmitForm ~ data',
      data,
    );

    // const organizedDataToSend = {
    //   code: data.productCode,
    //   name: data.productName,
    //   productType: data.product_type,
    //   brand: data.brand,
    //   unit: data.unity,
    //   gtin_ean: data.gtin_ean,
    //   ncm: data.ncm,
    //   gross_weight: data.grossweight,
    //   net_weight: data.netweight,
    //   cost_price: data.cost_price,
    //   sale_price: data.sale_price,
    //   origin: data.productOrigin,
    //   product_status: data.idproductstatus,
    //   internal_bar_code: data.internalBarcode,
    //   location_stock: data.locationStock,
    //   start_stock: data.minimum_stock,
    //   minimum_tock: data.minimum_stock,
    //   maximum_stock: data.minimum_stock,
    //   description: data.comments,
    //   barcode: data.barcode,
    //   comments: data.comments,
    // }

    // event.preventDefault();
    // let buttonId = event.nativeEvent.submitter.id;

    // loadingButton(
    //   true,
    //   document.querySelector("#" + buttonId),
    //   document.querySelector("#" + buttonId).children[0].classList,
    //   document.querySelector("#" + buttonId).lastChild.nodeValue
    // );
    // try {
    //   let _data = organizedDataToSend;

    //   if (editState)
    //     await api
    //       .put("/products/" + data.idproduct, _data)
    //       .then((response) => {
    //         ShowMessages(response);
    //         if (response.ok) {
    //           if (response.data.status === 200) {
    //             if (buttonId === "submit-product-insert") {
    //               setTabActive(0);
    //               loadingButton(
    //                 false,
    //                 document.querySelector("#" + buttonId),
    //                 "fa fa-plus",
    //                 "Salvar e Inserir"
    //               );
    //               editState = false;
    //               setData({}, true);
    //               document.querySelector("#code").focus();
    //             } else {
    //               handleCloseModal();
    //               handleReload(true);
    //             }
    //           } else
    //             loadingButton(false, document.querySelector("#" + buttonId));
    //         } else loadingButton(false, document.querySelector("#" + buttonId));
    //       })
    //       .catch((error) => {
    //         console.log("🚀 ~ file: modal-product.js ~ line 305 ~ handleSubmitForm ~ error", error)
    //         loadingButton(false, document.querySelector("#" + buttonId));
    //         ShowMessages(error);
    //       });
    //   else
    //     await api
    //       .post("/products", _data)
    //       .then((response) => {
    //         ShowMessages(response);
    //         if (response.ok) {
    //           if (response.data.status === 200) {
    //             if (buttonId === "submit-product-insert") {
    //               setTabActive(0);
    //               loadingButton(
    //                 false,
    //                 document.querySelector("#" + buttonId),
    //                 "fa fa-plus",
    //                 "Salvar e Inserir"
    //               );
    //               editState = false;
    //               setData({}, true);
    //               document.querySelector("#code").focus();
    //             } else {
    //               handleCloseModal();
    //               handleReload(true);
    //             }
    //           } else
    //             loadingButton(false, document.querySelector("#" + buttonId));
    //         } else loadingButton(false, document.querySelector("#" + buttonId));
    //       })
    //       .catch((error) => {
    //         loadingButton(false, document.querySelector("#" + buttonId));
    //         ShowMessages(error);
    //       });
    // } catch (response) {
    //   loadingButton(false, document.querySelector("#" + buttonId));
    //   ShowMessages(response);
    // }
    // // const organizedDataToSend1 = {
    // //   // adjust_price_automatically: false,
    // //   average_cost: 0,
    // //   barcode: "1",
    // //   // batch: null,
    // //   brand: data.brand,
    // //   code: "11",
    // //   comments: null,
    // //   control_stock: false,
    // //   cost_price: data.cost_price,
    // //   description: "1",
    // //   fulldescription: null,
    // //   grossweight: data.gross_weight,
    // //   idcolor: null,
    // //   idgroup: null,
    // //   idlocation: null,
    // //   idmanufacturer: null,
    // //   idorigin: data.idorigin,
    // //   idproduct: 11,
    // //   idproductstatus: data.idproductstatus,
    // //   idtag: null,
    // //   idtaxgroup: null,
    // //   internalbarcode: data.internalbarcode,
    // //   minimum_stock: data.minimum_stock,
    // //   ncm: data.ncm,
    // //   netweight: data.netweight,
    // //   product_type: 6,
    // //   profit_margin: 0,
    // //   provider: null,
    // //   qrcode: null,
    // //   sale_price: 1,
    // //   unity: "5",
    // // }
  };

  return (
    <Modal
      id="modal-product"
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      {/* <ModalLocation
        showModal={showLocation}
        handleCloseModal={handleShowModalLocation}
        editState={false}
        setReturn={setLocation}
      /> */}

      <FormProvider {...methods}>
        <form
          id="form-product"
          onSubmit={methods.handleSubmit(handleSubmitForm)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="mb-0">
              <i className="fa fa-box" /> Propriedades do Produto
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Loading loading={loading} addClass="heigth330px" />
            <div
              className={`${loading ? 'hidden' : ''}`}
              style={{ maxHeight: '600px' }}
            >
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`${tabActive === 0 ? 'active' : ''} 
                                            ${
                                              tabHasError.tab1
                                                ? 'text-danger'
                                                : null
                                            }`}
                    onClick={() => {
                      setTabActive(0);
                    }}
                  >
                    <span id="general">Geral</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={clsx({
                      active: tabActive === 1,
                      'text-danger': tabHasError.tab2,
                      hidden: !showInventory,
                    })}
                    onClick={() => {
                      setTabActive(1);
                    }}
                  >
                    <span id="stock">Estoque</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${tabActive === 2 ? 'active' : ''} 
                                        ${
                                          tabHasError.tab2
                                            ? 'text-danger'
                                            : null
                                        }`}
                    onClick={() => {
                      setTabActive(2);
                    }}
                  >
                    <span id="providers">Fornecedores</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={clsx({
                      active: tabActive === 3,
                      'text-danger': tabHasError.tab3,
                      hidden: !isKit,
                    })}
                    onClick={() => {
                      setTabActive(3);
                    }}
                  >
                    <span id="product-structure">
                      {'Estrutura do produto (KIT)'}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${tabActive === 4 ? 'active' : ''} 
                                         ${
                                           tabHasError.tab4
                                             ? 'text-danger'
                                             : null
                                         }`}
                    onClick={() => {
                      setTabActive(4);
                    }}
                  >
                    <span id="tributary-fiscals">Fiscais e tributárias</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${tabActive === 5 ? 'active' : ''} 
                                         ${
                                           tabHasError.tab5
                                             ? 'text-danger'
                                             : null
                                         }`}
                    onClick={() => {
                      setTabActive(5);
                    }}
                  >
                    <span id="attachments">Anexos</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={tabActive}
                style={{ maxHeight: '400px', overflow: 'auto' }}
              >
                <TabPane tabId={0}>
                  <General
                    dataProductType={dataProductType}
                    dataUnit={dataUnit}
                    dataOrigin={dataOrigin}
                    dataProductStatus={dataProductStatus}
                    dataProductLocation={dataProductLocation}
                    setReload={setReload}
                  />
                </TabPane>

                <TabPane tabId={1}>
                  <Stock />
                </TabPane>

                <TabPane tabId={2}>
                  <Providers />
                </TabPane>

                <TabPane tabId={3}>
                  <Kit />
                </TabPane>

                <TabPane tabId={4}>
                  <FiscalTributary />
                </TabPane>

                <TabPane tabId={5}>
                  <Atachments />
                </TabPane>

                <TabPane tabId={6}>
                  {/* LINHA 1
                      <div style={{ width: "50%" }}>
                        <div className="input-group">
                          <FormControl
                            name="produtoFotos"
                            id="produtoFotos"
                            type={"file"}
                            multiple
                          />
                          <FormText>
                            Máximo 2MB: JPG, JPEG, GIF, PNG, BMP
                          </FormText>
                        </div>
                      </div>
                      LINHA 1
                      <div style={{ width: "50%" }}>
                        <div className="input-group">
                          <FormControl
                            name="produtoAnexos"
                            id="produtoAnexos"
                            type={"file"}
                            multiple
                          />
                          <FormText>
                            Máximo 2MB: JPG, JPEG, GIF, PNG, BMP, PDF, TXT
                          </FormText>
                        </div>
                      </div> */}
                </TabPane>
              </TabContent>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              id="submit-product-insert"
              className={`btn-theme ${loading ? 'hidden' : ''}`}
            >
              <em className="fa fa-save" /> Salvar
            </Button>
            <Button
              className="btn-ligth-gray"
              onClick={() => {
                handleCloseModal();
              }}
            >
              <em className="fa fa-door-closed" /> Fechar
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
      {/* </ValidationForm> */}
    </Modal>
  );
};

export default memo(ModalProducts);
