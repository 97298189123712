import React from 'react'
import { Tooltip} from 'reactstrap';

export default class TooltipItem extends React.Component {
    // static propTypes { content: PropTypes.string }
    state = {
        _id: 'id4tooltip_'+new Date().getUTCMilliseconds()+(Math.floor(Math.random()*10) + 1),
        tooltipOpen: false
    }
    toggle = e => this.setState({tooltipOpen: !this.state.tooltipOpen})
    render() {
        return [
            <Tooltip {...this.props} isOpen={this.state.tooltipOpen} toggle={this.toggle} target={this.state._id} key='1' >
                {this.props.content}
            </Tooltip>,
            React.cloneElement(React.Children.only(this.props.children), {
                id: this.state._id,
                key: '2'
            })
        ]
    }
}

