import { memo, useState } from 'react';
import { Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import ModalProduct from '../../../pages/modals/modal-product';

// const colourOptions = [
//     { label: 'Azul', value: 'blue' },
//     { label: 'Vermelho', value: 'red' },
//     { label: 'Preto', value: 'black' },
//     { label: 'Rosa', value: 'pink' },
//     { label: 'Verde', value: 'green' },
//     { label: 'Amarelo', value: 'yellow' },
// ];

const freight = [
    { label: 'Azul', value: 'blue' },
    { label: 'Vermelho', value: 'red' },
    { label: 'Preto', value: 'black' },
    { label: 'Rosa', value: 'pink' },
    { label: 'Verde', value: 'green' },
    { label: 'Amarelo', value: 'yellow' },
];

const uf = [
    { label: 'CE', value: 'CE' },
    { label: 'GO', value: 'GO' },
    { label: 'ES', value: 'ES' },
    { label: 'MG', value: 'MG' },
    { label: 'RJ', value: 'RJ' },
    { label: 'PI', value: 'PI' },
];

const NfeTransport = () => {
    const { register } = useFormContext();
    const [showModalProduct, setShowModalProduct] = useState(false);

    const handleCloseModalProduct = () => {
        setShowModalProduct(false);
    };

    // const filterColors = (inputValue) => {
    //     return colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    // };
    // const loadOptions = async (inputValue, callback) => {
    //     // try {
    //     //     await api
    //     //         .get('/getProvider')
    //     //         .then((response) => {
    //     //             if (response.ok) {
    //     //                 console.log(response);
    //     //             }
    //     //         })
    //     //         .catch((error) => {
    //     //             ShowMessages(error);
    //     //         });
    //     // } catch (response) {
    //     //     ShowMessages(response);
    //     // }

    //     setTimeout(() => {
    //         callback(filterColors(inputValue));
    //     }, 1000);
    // };

    return (
        <Container fluid>
            <ModalProduct showModal={showModalProduct} handleCloseModal={handleCloseModalProduct} editState={false} />
            <Row>
                <Col lg={2} md={2} sm={12}>
                    <label>Peso bruto(KG)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('transport.grossWeight')}
                            id="transport-grossWeight"
                            type="number"
                            minLength={0.0}
                            step={0.01}
                            // placeholder="Peso em KG"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>Peso líquido(KG)</label>
                    <div className="input-group">
                        <FormControl
                            {...register('transport.netWeight')}
                            id="transport-netWeight"
                            type="number"
                            minLength={0.0}
                            step={0.01}
                            // placeholder="Peso em KG"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>Volumes</label>
                    <div className="input-group">
                        <FormControl
                            {...register('transport.volume')}
                            id="transport-volume"
                            type="number"
                            minLength={1}
                            step={1}
                        />
                    </div>
                </Col>

                <Col lg={2} md={2} sm={12}>
                    <label>Espécie</label>
                    <div className="input-group">
                        <FormControl
                            {...register('transport.kinds')}
                            id="transport-kinds"
                            type="text"
                            placeholder="Ex: Caixa ou Fardo"
                        />
                    </div>
                </Col>

                <Col lg={2} md={2} sm={12}>
                    <label>Marca</label>
                    <div className="input-group">
                        <FormControl {...register('transport.brand')} id="transport-brand" type="text" />
                    </div>
                </Col>

                <Col lg={2} md={2} sm={12}>
                    <label>Numeração</label>
                    <div className="input-group">
                        <FormControl {...register('transport.numbering')} id="transport-numbering" type="text" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={3} md={3} sm={12}>
                    <label>Modalidade do frete</label>
                    <div className="input-group ">
                        <FormSelect
                            {...register('transport.freightModality')}
                            id="transport.freightModality"
                            className="w-100"
                        >
                            <option value="">Selecione</option>

                            {freight.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12}>
                    <label>Transportadora</label>
                    <div className="input-group">
                        <FormControl
                            {...register('transport.shippingCompany')}
                            id="transport-shippingCompany"
                            type="text"
                        />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>Placa do veiculo</label>
                    <div className="input-group">
                        <FormControl {...register('transport.licensePlate')} id="transport-licensePlate" type="text" />
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>UF do veículo</label>
                    <div className="input-group">
                        <FormSelect {...register('transport.vehicleUf')} id="transport.vehicleUf" className="w-100">
                            <option value="">Selecione</option>

                            {uf.map((item) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                    </div>
                </Col>
                <Col lg={2} md={2} sm={12}>
                    <label>{'RNTC(ANTT)'}</label>
                    <div className="input-group">
                        <FormControl {...register('transport.rntc')} id="transport-tntc" type="text" />
                    </div>
                </Col>
            </Row>
            <div className="my-2">
                <button title="Adicionar Novo Produto" className="btn btn-success font-weight-bold">
                    <i className="fa-solid fa-plus" />
                    <i className="fa-solid fa-truck-arrow-right ml-1" />
                </button>
            </div>
        </Container>
    );
};

export default memo(NfeTransport);
