const loadingButton = (isLoading, object, originalClass, OriginalText) => {
    if (object === null)
        return 
        
    if (isLoading) {
        object.children[0].classList = ''
        object.children[0].classList = 'spinner-border spinner-border-sm'
        object.setAttribute('disabled','disabled')
        object.lastChild.nodeValue = ' Aguarde...'
    } else {
        object.children[0].classList = ''
        if (originalClass)
            object.children[0].classList = originalClass
        else 
            object.children[0].classList = 'fa fa-save'
        object.removeAttribute('disabled')
        if (OriginalText)
            object.lastChild.nodeValue = ' ' + OriginalText
        else
            object.lastChild.nodeValue = ' Salvar'
            
    }
    object.classList.toggle('cursor-wait')
}
export default loadingButton